import { ExcelData } from '../file/interface';

export function truncateAfterEmptyRow(data: ExcelData): ExcelData {
  const emptyRowIndex = data.findIndex((row) =>
    row.every((value) => value === '')
  );
  if (emptyRowIndex === -1) {
    return data;
  }
  return data.slice(0, emptyRowIndex);
}
