import { NormalizedOrder } from '@sweep/contract';
import { isEmptyString } from 'src/utils/string';

export function isShippingProcessOrder(order: NormalizedOrder) {
  return (
    order.process === 'shipping' &&
    (isEmptyString(order.shippingCompany) ||
      isEmptyString(order.shippingNumber))
  );
}
