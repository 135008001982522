import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import backendApi from '../utils/backendApi';
import { isSameComposition } from '../utils/compositionUtil';
import { isValid } from '../utils/utils';

const EXCEL_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

function useSettlement() {
  const addSummarySheet = (workbook, data, settings) => {
    const { userType = 'retailer' } = settings;
    const summaryData = data.reduce((acc, { productName, unit, quantity }) => {
      // productName과 unit을 결합하여 유일한 키 생성

      const foundIndex = acc.findIndex(
        (item) => item.productName === productName && item.unit === unit
      );

      if (foundIndex !== -1) {
        acc[foundIndex].quantity += quantity;
      } else {
        acc.push({
          productName,
          unit,
          quantity,
        });
      }

      return acc;
    }, []);

    // sortData
    summaryData.sort((a, b) => {
      // placeShippingFeeToEnd
      if (a.productName === '택배비' && b.productName !== '택배비') {
        return 1; // a가 '택배비'이면, b보다 뒤에 오도록 함
      } else if (a.productName !== '택배비' && b.productName === '택배비') {
        return -1; // b가 '택배비'이면, a보다 뒤에 오도록 함
      }

      a.productName.localeCompare(b.productName);
    });

    const worksheet = workbook.addWorksheet('전체');

    const headerRows = ['상품명', '구성', '개수', '가격', '합계'];

    const headerRow = worksheet.addRow(headerRows);

    const columnInfo = [
      { header: '상품명', key: 'productName', width: 40 },
      { header: '구성', key: 'unit', width: 12 },
      { header: '개수', key: 'quantity', width: 5 },
      { header: '가격', key: 'price', width: 20 },
      { header: '합계', key: 'totalPrice', width: 30 },
    ];

    worksheet.columns = columnInfo;

    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });

    summaryData.forEach((data, index) => {
      const rowIndex = index + 2;

      worksheet.addRow({ ...data });

      const priceCell = `D${rowIndex}`;

      const productNameCell = `A${rowIndex}`;
      const unitCell = data.unit ? `B${rowIndex}` : '""';

      const priceFormula = `=SUMIFS(가격표!C2:C21,가격표!A2:A21,${productNameCell},가격표!B2:B21,${unitCell})`;
      worksheet.getCell(priceCell).value = {
        formula: priceFormula,
        result: undefined, // 실제 엑셀에서 계산 예정
      };

      // 가격과 개수가 모두 있는 경우에만 수식을 추가
      const priceCellRef = `D${rowIndex}`; // '가격' 열
      const quantityCellRef = `C${rowIndex}`; // '개수' 열
      const totalPriceFormula = `${priceCellRef}*${quantityCellRef}`;

      worksheet.getCell(`E${rowIndex}`).value = {
        formula: totalPriceFormula,
        result: undefined, // 실제 엑셀에서 계산 예정
      };
    });

    // '총 합계' 행을 추가하는 로직
    const lastRowNumber = worksheet.rowCount;
    worksheet.getCell(`A${lastRowNumber + 1}`).value = '총 합계';
    worksheet.getCell(`C${lastRowNumber + 1}`).value = {
      formula: `SUM(C2:C${lastRowNumber})`,
      result: 0,
    }; // '개수' 열

    worksheet.getCell(`E${lastRowNumber + 1}`).value = {
      formula: `SUM(E2:E${lastRowNumber})`,
      result: 0,
    }; // '합계' 열

    // beautifyExcel
    // 1행 고정
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];
  };

  const addPartnerSheet = (workbook, data) => {
    const worksheet = workbook.addWorksheet('판매처 별');

    const headerRows = ['상품명', '구성', '개수', '가격', '합계'];
    const headerRow = worksheet.addRow(headerRows);

    const columnInfo = [
      {
        header: '판매처',
        key: 'partnerName',
        width: 15,
      },
      { header: '상품명', key: 'productName', width: 40 },
      { header: '구성', key: 'unit', width: 12 },
      { header: '개수', key: 'quantity', width: 5 },
      { header: '가격', key: 'price', width: 10 },
      { header: '합계', key: 'totalPrice', width: 15 },
    ];

    data.sort((a, b) => {
      if (a.partnerName === '기타') {
        return 1;
      } // '기타'를 b보다 아래로
      if (b.partnerName === '기타') {
        return -1;
      } // '기타'를 a보다 아래로
      return a.partnerName.localeCompare(b.partnerName); // 일반 문자열 비교
    });

    worksheet.columns = columnInfo;

    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });

    data.forEach((item, index) => {
      const rowIndex = index + 2;

      worksheet.addRow({ ...item });

      const priceCell = `E${rowIndex}`;

      const productNameCell = `B${rowIndex}`;
      const unitCell = item.unit ? `C${rowIndex}` : '""';

      const priceFormula = `=SUMIFS(가격표!C2:C21,가격표!A2:A21,${productNameCell},가격표!B2:B21,${unitCell})`;
      worksheet.getCell(priceCell).value = {
        formula: priceFormula,
        result: undefined, // 실제 엑셀에서 계산 예정
      };

      // 가격과 개수가 모두 있는 경우에만 수식을 추가
      const priceCellRef = `E${rowIndex}`; // '가격' 열
      const quantityCellRef = `D${rowIndex}`; // '개수' 열
      const totalPriceFormula = `${priceCellRef}*${quantityCellRef}`;

      worksheet.getCell(`F${rowIndex}`).value = {
        formula: totalPriceFormula,
        result: undefined, // 실제 엑셀에서 계산 예정
      };
    });

    // 데이터를 '상품명' 기준으로 정렬
    data.sort((a, b) => a.productName.localeCompare(b.productName));

    // '판매처' 열 병합 로직
    let startRow = 2; // 첫 번째 데이터 행부터 시작 (헤더 행 제외)
    for (let i = 2; i <= worksheet.rowCount; i++) {
      // 마지막 행이거나 다음 행의 '판매처' 값이 다른 경우
      if (
        i === worksheet.rowCount ||
        worksheet.getRow(i).getCell(1).text !==
          worksheet.getRow(i + 1).getCell(1).text
      ) {
        if (i > startRow) {
          // 연속된 셀 병합
          worksheet.mergeCells(startRow, 1, i, 1);
          worksheet.getCell(`${startRow}`, 1).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        }
        startRow = i + 1; // 다음 그룹의 시작 행 업데이트
      }
    }

    const lastRowNumber = worksheet.rowCount;
    // '총 합계' 라벨을 추가하는 셀
    worksheet.getCell(`A${lastRowNumber + 1}`).value = '총 합계';
    worksheet.getCell(`D${lastRowNumber + 1}`).value = {
      formula: `SUM(D2:D${lastRowNumber})`,
      result: 0,
    };

    worksheet.getCell(`F${lastRowNumber + 1}`).value = {
      formula: `SUM(F2:F${lastRowNumber})`,
      result: 0,
    };

    // beautifyExcel
    // 1행 고정
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];
  };

  const addSupplierSheet = (workbook, data) => {
    const worksheet = workbook.addWorksheet('공급사 별');

    const headerRows = ['상품명', '구성', '개수', '가격', '합계'];
    const headerRow = worksheet.addRow(headerRows);

    const columnInfo = [
      {
        header: '공급사',
        key: 'supplierName',
        width: 15,
      },
      { header: '상품명', key: 'productName', width: 40 },
      { header: '구성', key: 'unit', width: 12 },
      { header: '개수', key: 'quantity', width: 5 },
      { header: '가격', key: 'price', width: 10 },
      { header: '합계', key: 'totalPrice', width: 15 },
    ];

    data.sort((a, b) => {
      if (a.supplierName === '기타') {
        return 1;
      } // '기타'를 b보다 아래로
      if (b.supplierName === '기타') {
        return -1;
      } // '기타'를 a보다 아래로
      return a.supplierName.localeCompare(b.supplierName); // 일반 문자열 비교
    });

    worksheet.columns = columnInfo;

    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });

    data.forEach((item, index) => {
      const rowIndex = index + 2;

      worksheet.addRow({ ...item });

      const priceCell = `E${rowIndex}`;

      const productNameCell = `B${rowIndex}`;
      const unitCell = item.unit ? `C${rowIndex}` : '""';

      const priceFormula = `=SUMIFS(가격표!C2:C21,가격표!A2:A21,${productNameCell},가격표!B2:B21,${unitCell})`;
      worksheet.getCell(priceCell).value = {
        formula: priceFormula,
        result: undefined, // 실제 엑셀에서 계산 예정
      };

      // 가격과 개수가 모두 있는 경우에만 수식을 추가
      const priceCellRef = `E${rowIndex}`; // '가격' 열
      const quantityCellRef = `D${rowIndex}`; // '개수' 열
      const totalPriceFormula = `${priceCellRef}*${quantityCellRef}`;

      worksheet.getCell(`F${rowIndex}`).value = {
        formula: totalPriceFormula,
        result: undefined, // 실제 엑셀에서 계산 예정
      };
    });

    // 데이터를 '상품명' 기준으로 정렬
    data.sort((a, b) => a.productName.localeCompare(b.productName));

    // '공급사' 열 병합 로직
    let startRow = 2; // 첫 번째 데이터 행부터 시작 (헤더 행 제외)
    for (let i = 2; i <= worksheet.rowCount; i++) {
      // 마지막 행이거나 다음 행의 '파트너사' 값이 다른 경우
      if (
        i === worksheet.rowCount ||
        worksheet.getRow(i).getCell(1).text !==
          worksheet.getRow(i + 1).getCell(1).text
      ) {
        if (i > startRow) {
          // 연속된 셀 병합
          worksheet.mergeCells(startRow, 1, i, 1);
          worksheet.getCell(`${startRow}`, 1).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        }
        startRow = i + 1; // 다음 그룹의 시작 행 업데이트
      }
    }

    const lastRowNumber = worksheet.rowCount;
    // '총 합계' 라벨을 추가하는 셀
    worksheet.getCell(`A${lastRowNumber + 1}`).value = '총 합계';
    worksheet.getCell(`D${lastRowNumber + 1}`).value = {
      formula: `SUM(D2:D${lastRowNumber})`,
      result: 0,
    };

    worksheet.getCell(`F${lastRowNumber + 1}`).value = {
      formula: `SUM(F2:F${lastRowNumber})`,
      result: 0,
    };

    // beautifyExcel
    // 1행 고정
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];
  };

  const addPeriodSheet = (workbook, data) => {
    const worksheet = workbook.addWorksheet('기간 별');

    const headers = ['날짜', '상품명', '구성', '개수', '가격', '합계'];
    const headerRow = worksheet.addRow(headers);

    const columnInfo = [
      { header: '날짜', key: 'productName', width: 20 },
      { header: '상품명', key: 'productName', width: 40 },
      { header: '구성', key: 'unit', width: 12 },
      { header: '개수', key: 'quantity', width: 5 },
      { header: '가격', key: 'price', width: 20 },
      { header: '합계', key: 'totalPrice', width: 30 },
    ];

    worksheet.columns = columnInfo;

    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });

    // 두 번째 행부터 시작
    let rowIndex = 2;
    Object.entries(data).forEach(([date, dayData]) => {
      let { settlementData } = dayData;
      // settlementData가 없으면 다음으로 넘어가기
      console.log('start', date);

      if (!isValid(settlementData)) {
        return;
      }
      console.log('통과!');

      settlementData.forEach((data, index) => {
        const { productName, unit, quantity } = data;
        const row = [index === 0 ? date : '', productName, unit, quantity];
        worksheet.addRow(row);

        console.log('rowIndex', rowIndex);

        const priceCell = `E${rowIndex}`;

        const productNameCell = `B${rowIndex}`;
        const unitCell = data.unit ? `C${rowIndex}` : '""';

        const priceFormula = `=SUMIFS(가격표!C2:C21,가격표!A2:A21,${productNameCell},가격표!B2:B21,${unitCell})`;
        worksheet.getCell(priceCell).value = {
          formula: priceFormula,
          result: undefined, // 실제 엑셀에서 계산 예정
        };

        // 가격과 개수가 모두 있는 경우에만 수식을 추가
        const priceCellRef = `E${rowIndex}`; // '가격' 열
        const quantityCellRef = `D${rowIndex}`; // '개수' 열
        const totalPriceFormula = `${priceCellRef}*${quantityCellRef}`;

        worksheet.getCell(`F${rowIndex}`).value = {
          formula: totalPriceFormula,
          result: undefined, // 실제 엑셀에서 계산 예정
        };
        rowIndex++;
      });
    });

    // '총 합계' 행을 추가하는 로직
    const lastRowNumber = worksheet.rowCount;
    worksheet.getCell(`A${lastRowNumber + 1}`).value = '총 합계';
    worksheet.getCell(`D${lastRowNumber + 1}`).value = {
      formula: `SUM(D2:D${lastRowNumber})`,
      result: 0,
    }; // '개수' 열

    worksheet.getCell(`F${lastRowNumber + 1}`).value = {
      formula: `SUM(F2:F${lastRowNumber})`,
      result: 0,
    }; // '합계' 열

    // beautifyExcel
    // 1행 고정
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    // // 1열 중앙 정렬
    // worksheet.getColumn(1).eachCell((cell) => {
    //   cell.alignment = { vertical: 'middle', horizontal: 'center' };
    // });
  };

  function calculatePriceForData(data, priceList) {
    if (!isValid(priceList)) {
      return 0;
    }

    if (['택배비', '도서산간비'].includes(data.productName)) {
      return parseInt(data.unit, 10);
    }

    const price = priceList.find((price) => {
      if (isValid(price?.data) && isValid(data?.data)) {
        return isSameComposition(price.data, data.data);
      } else {
        return (
          price.productName === data.productName && price.unit === data.unit
        );
      }
    });

    return isValid(price) ? price.regularPrice : 0;
  }

  const addPriceSheet = async (workbook, data, settings) => {
    const { priceList = [] } = settings;

    // data 있을 수도 있고 없을수도 있음
    const summaryData = data.reduce(
      (acc, { productName, unit, quantity, data }) => {
        // productName과 unit을 결합하여 유일한 키 생성

        const foundIndex = acc.findIndex(
          (item) => item.productName === productName && item.unit === unit
        );

        if (foundIndex !== -1) {
          acc[foundIndex].quantity += quantity;
        } else {
          acc.push({
            productName,
            unit,
            quantity,
            data: data || [],
          });
        }

        return acc;
      },
      []
    );

    // sortData
    summaryData.sort((a, b) => {
      // placeShippingFeeToEnd
      if (a.productName === '택배비' && b.productName !== '택배비') {
        return 1; // a가 '택배비'이면, b보다 뒤에 오도록 함
      } else if (a.productName !== '택배비' && b.productName === '택배비') {
        return -1; // b가 '택배비'이면, a보다 뒤에 오도록 함
      }

      a.productName.localeCompare(b.productName);
    });

    // productInfo를 통해 productName, unit 별 기본 가격들을 불러오는 로직

    const worksheet = workbook.addWorksheet('가격표');

    const headerRows = ['상품명', '구성', '가격'];
    const headerRow = worksheet.addRow(headerRows);

    const columnInfo = [
      { header: '상품명', key: 'productName', width: 40 },
      { header: '구성', key: 'unit', width: 12 },
      { header: '가격', key: 'price', width: 10 },
    ];

    worksheet.columns = columnInfo;

    // updateSummaryDataWithPrices;
    let newPriceList = [...priceList];

    if (!isValid(priceList)) {
      const res = await backendApi.getPriceInfo();

      if (res.success) {
        newPriceList = res.data;
      }
    }

    summaryData.forEach((data, index) => {
      // price 추가 로직
      if (isValid(newPriceList)) {
        data.price = calculatePriceForData(data, newPriceList);
      }

      // 그 이후 파트너사 별로 다른 상품 가격을 반영하는 로직도 추가해야함
      worksheet.addRow({
        ...data,
      });
    });

    //// beautifyExcel
    // freezeHeaderRow
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    // styleHeaderRow
    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });
  };

  const _createExcelFile = async (fileName, data, settings) => {
    const {
      hasSupplierData = false,
      hasPartnerData = false,
      isPeriodSettlementData = false,
    } = settings;

    let updatedData = data;
    if (isPeriodSettlementData) {
      updatedData = Object.keys(updatedData).map((date) => ({
        date,
        ...data[date],
      }));

      updatedData = updatedData.reduce((acc, dayData) => {
        const { settlementData = [] } = dayData;

        return [...acc, ...settlementData];
      }, []);
    }

    const workbook = new ExcelJS.Workbook();

    addSummarySheet(workbook, updatedData, settings);

    // 판매처별 정산시트 생성
    if (hasPartnerData) {
      addPartnerSheet(workbook, updatedData);
    }

    // 공급사별 정산시트 생성
    if (hasSupplierData) {
      addSupplierSheet(workbook, updatedData);
    }

    if (isPeriodSettlementData) {
      // prop으로 data가 들어가는게 맞음 updatedData로 바꾸지 말것
      addPeriodSheet(workbook, data);
    }

    // 각 시트의 '가격'열에서 참조할 가격 시트 생성
    await addPriceSheet(workbook, updatedData, settings);

    // Buffer로 엑셀 파일을 생성
    const buffer = await workbook.xlsx.writeBuffer();

    // Blob으로 변환
    const blob = new Blob([buffer], {
      type: EXCEL_MIME_TYPE,
    });

    // 다운로드
    saveAs(blob, `${fileName}.xlsx`);
  };

  const createSettlementFileForPeriod = async (fileName, data) => {
    const settings = {
      hasPartnerData: false,
      isPeriodSettlementData: true,
    };
    await _createExcelFile(fileName, data, settings);
  };

  const downloadDailySettlementFile = async (data, settings) => {
    const { targetDate = 'today' } = settings;

    const date = targetDate === 'today' ? new Date() : new Date(targetDate);
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}.${String(date.getDate()).padStart(2, '0')}`;

    const fileName = `${formattedDate} 정산파일`;

    await _createExcelFile(fileName, data, settings);
  };

  return {
    downloadDailySettlementFile,
    createSettlementFileForPeriod,
  };
}

export default useSettlement;
