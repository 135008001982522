import { HTMLAttributes, ReactNode } from 'react';
import { cva } from '@sweep/tailwind';

export interface ModalDescriptionProps
  extends HTMLAttributes<HTMLParagraphElement> {
  children: ReactNode;
}

export function ModalDescription({
  children,
  className,
  ...rest
}: ModalDescriptionProps) {
  return (
    <p className={text({ className })} {...rest}>
      {children}
    </p>
  );
}

const text = cva('text-medium-m text-gray-700');
