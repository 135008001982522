import { http, HttpHandler, HttpResponse } from 'msw';
import { isNotNil, LocalStorageValue, sleep } from '@sweep/utils';
import { BACKEND_URL } from 'src/env';
import {
  FulfilledOrder,
  FulfilledOrderResult,
  FulfillOrderResponse,
} from 'src/network/order/fullfillOrder';
import { LOCALSTORAGE_DISPATCH_PREFIX } from '../../../common/constants';

const FULFILL_ORDER_URL = `${BACKEND_URL}/fulfill-order`;

export function createFulfillOrderHandlers(): HttpHandler[] {
  return [createFulfillOrderHandler()].filter(isNotNil);
}

export type FulfillOrdersType = 'success' | 'partial-fail' | 'fail' | 'none';
export const fulfillOrderTypeStorage = new LocalStorageValue<FulfillOrdersType>(
  {
    key: `${LOCALSTORAGE_DISPATCH_PREFIX}/fullfill-order-type`,
    defaultValue: 'none',
  }
);

function createFulfillOrderHandler() {
  const fulfillOrderType = fulfillOrderTypeStorage.value;

  if (fulfillOrderType === 'none') {
    return null;
  }

  return http.post(
    `${FULFILL_ORDER_URL}/fulfill-orders`,
    async ({ request }) => {
      await sleep(1000);
      const { infoArray: orders } = (await request.json()) as {
        infoArray: FulfilledOrder[];
      };

      switch (fulfillOrderType) {
        case 'success':
          return HttpResponse.json<FulfillOrderResponse>({
            success: true,
            data: orders.map<FulfilledOrderResult>((order) => ({
              ...order,
              success: true,
            })),
          });

        case 'partial-fail':
          return HttpResponse.json<FulfillOrderResponse>({
            success: true,
            data: mapPartialFailOrders(orders),
          });

        case 'fail':
          return HttpResponse.json<FulfillOrderResponse>({
            success: false,
            error: 'msw-order-error',
          });
      }
    }
  );
}

function mapPartialFailOrders(orders: FulfilledOrder[]) {
  return orders.map<FulfilledOrderResult>((order, index) =>
    index % 2 === 0
      ? { ...order, success: true }
      : {
          ...order,
          success: false,
          failReason: 'msw-order-error',
        }
  );
}
