import { action, computed, makeObservable, observable } from 'mobx';
import { Partner } from 'models/Partner';
import { MultiSheetExcelFile } from 'services/file/interface';
import { OMSStore } from 'stores/OMSStore';
import { matchFileToPartners } from 'src/services/column-mapping/matchFileToPartners';
import {
  FileMatching,
  FileMatchingCandidate,
  PartnerMatchingStage,
} from './interface';

export class PartnerMatchingStore {
  stage: PartnerMatchingStage;

  fileMatchingCandidates: FileMatchingCandidate[];
  fileMatchings: FileMatching[];
  fileMatchingsToUpdateHeader: FileMatching[] = [];

  isExistDuplicatedMatching: boolean;
  isExistEmptyMatching: boolean;

  get duplicatedMathingCandidates() {
    return this.fileMatchingCandidates.filter(
      (matching) => matching.partners.length > 1
    );
  }

  get notMatchingFiles() {
    return this.fileMatchingCandidates
      .filter((matching) => matching.partners.length === 0)
      .map((matching) => matching.file);
  }

  constructor(
    files: MultiSheetExcelFile[],
    private onSubmit: (fileMatchings: FileMatching[]) => void,
    oms: OMSStore
  ) {
    makeObservable(this, {
      stage: observable,
      fileMatchings: observable,
      fileMatchingsToUpdateHeader: observable,

      duplicatedMathingCandidates: computed,
      notMatchingFiles: computed,

      submitDuplicatedMatchings: action.bound,
      submitNotMatchings: action.bound,
      submitUpdateHeader: action.bound,
      pushFileMatchings: action.bound,
    });

    this.fileMatchingCandidates = files.map((file) => {
      const { sheet, partners } = matchFileToPartners(
        file,
        oms.partner.partners
      );
      return { file: sheet, partners };
    });

    this.fileMatchings = this.fileMatchingCandidates
      .filter((match) => match.partners.length === 1)
      .map((matchingCandidate) => ({
        file: matchingCandidate.file,
        partner: matchingCandidate.partners.at(0)!,
      }));

    this.isExistDuplicatedMatching = this.fileMatchingCandidates.some(
      (matching) => matching.partners.length > 1
    );
    this.isExistEmptyMatching = this.fileMatchingCandidates.some(
      (matching) => matching.partners.length === 0
    );

    this.stage = this.isExistDuplicatedMatching
      ? 'DUPLICAT_MACHED'
      : this.isExistEmptyMatching
        ? 'NOT_MATCHED'
        : 'END';

    if (this.stage === 'END') {
      // NOTE(@이지원): 렌더링 동기 스택에서 바로 setState(onSubmit)를 실행하지 않도록 setTimeout 사용
      // TODO(@이지원): PartnerMatchingStore를 OrderCombineStore에서 생성하고, END이면 overlay.open을 호출하지 않도록 수정
      setTimeout(() => onSubmit(this.fileMatchings));
    }
  }

  submitDuplicatedMatchings(matchings: FileMatching[]) {
    this.pushFileMatchings(matchings);

    if (this.isExistEmptyMatching) {
      this.stage = 'NOT_MATCHED';
      return;
    }

    this.onSubmit(this.fileMatchings);
  }

  submitNotMatchings(
    matchings: FileMatching[],
    partnersToUpdateHeader: Partner[] = []
  ) {
    this.pushFileMatchings(matchings);

    if (partnersToUpdateHeader.length > 0) {
      const partnersToUpdateHeaderSet = new Set(
        partnersToUpdateHeader.map((partner) => partner._id)
      );
      this.fileMatchingsToUpdateHeader = this.fileMatchings.filter((matching) =>
        partnersToUpdateHeaderSet.has(matching.partner._id)
      );

      this.stage = 'MATCHING_HEADER';
      return;
    }

    this.onSubmit(this.fileMatchings);
  }

  submitUpdateHeader(partners: Partner[]) {
    this.stage = 'END';
    this.fileMatchings.forEach(({ partner: matchedPartner }) => {
      matchedPartner.matchings =
        partners.find((partner) => partner._id === matchedPartner._id)
          ?.matchings ?? matchedPartner.matchings;
    });
    this.onSubmit(this.fileMatchings);
  }

  pushFileMatchings(newFileMatchings: FileMatching[]) {
    const newMatchingExcelNameSet = new Set(
      newFileMatchings.map((matching) => matching.file.name)
    );
    const prevMatchings = this.fileMatchings.filter(
      (matching) => !newMatchingExcelNameSet.has(matching.file.name)
    );

    this.fileMatchings = [...prevMatchings, ...newFileMatchings];
  }
}
