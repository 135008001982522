import { overlay } from 'overlay-kit';
import { Dialog } from 'src/design-system/components/Dialog';

export function openConfirmOrderWithExclusionDialog(orderCount: number) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close }) => (
      <Dialog open={isOpen} onClose={close}>
        <Dialog.Title className="mb-16px">
          일부 주문을 제외한{' '}
          <span className="text-blue-500">{orderCount}건</span>의 주문을
          확인할까요?
        </Dialog.Title>
        <Dialog.Description className="mb-28px">
          카카오메이커스 주문 건은 확인할 수 없습니다.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
