import { NormalizedOrder, Order } from '../type/order/Order';

const ORDER_URL = '/order';

export const REPLACE_ORDERS_URL = `${ORDER_URL}/replace`;

export type ReplaceOrdersBody = {
  orders: Order[];
};

export const REPLACE_NORMALIZED_ORDERS_URL = `${ORDER_URL}/replace-normalized`;

export type ReplaceNormalizedOrderSBody = {
  orders: NormalizedOrder[];
};

export const REPLACE_ALL_ORDERS_URL = `${ORDER_URL}/replace-all`;

export type ReplaceAllOrdersBody = {
  orders: Order[];
  normalizedOrders: NormalizedOrder[];
};

export const UPSERT_NORMALIZED_ORDERS_URL = `${ORDER_URL}/upsert-normalized`;

export type UpsertNormalizedOrdersBody = {
  orders: NormalizedOrder[];
};
