import { isNotNil } from '@sweep/utils';
import { Token } from './interface';
import {
  isOperator,
  OPERATORS,
  VARIABLE_ARGUMENT_OPERATOR_TOKENS,
} from './operator';

// TODO(@이지원): 괄호가 제대로 닫히지 않는 경우 null 리턴 추가
export function parseExpression(input: string): Token[] | null {
  const operatorPattern = sortByContainment([
    ...OPERATORS,
    ...VARIABLE_ARGUMENT_OPERATOR_TOKENS,
  ])
    .map((op) => `\\${op}`)
    .join('|');
  const stringPattern = `"[^"]*"`;
  const numberPattern = '\\d+(\\.\\d+)?';
  const wordPattern = '\\S+';

  const regex = new RegExp(
    `${operatorPattern}|${stringPattern}|${numberPattern}|${wordPattern}`,
    'g'
  );
  const splitted = input.match(regex);

  if (splitted == null) {
    return null;
  }

  const parsed = splitted.map((token) => {
    if (isOperator(token)) {
      return token;
    }

    const isWrappedWithQuotes = /^".*"$/.test(token);
    if (isWrappedWithQuotes) {
      return token.replace(/^"|"$/g, '');
    }

    const lowerCasedToken = token.toLocaleLowerCase();
    if (lowerCasedToken === 'true') {
      return true;
    }

    if (lowerCasedToken === 'false') {
      return false;
    }

    const isNumber = !isNaN(parseFloat(token));
    if (isNumber) {
      return parseFloat(token);
    }

    return null;
  });

  if (parsed.every(isNotNil)) {
    return parsed;
  }

  return null;
}

// NOTE(@이지원): 정규식에서 ROUNDUP이 ROUND보다 먼저 매칭되도록 정렬
function sortByContainment(arr: string[]): string[] {
  return arr.sort((a, b) => {
    if (a.includes(b)) {
      return -1;
    }

    if (b.includes(a)) {
      return 1;
    }

    return 0;
  });
}
