import { useCallback, useState } from 'react';
import { Product } from '@sweep/contract';
import { uniq } from '@sweep/utils';

export function useProductCheck(products: Product[]) {
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);

  const clearSelectedProductIds = useCallback(() => {
    setSelectedProductIds([]);
  }, []);

  const handleTotalCheckedChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        const productIds = products.map((product) => product._id);
        setSelectedProductIds(uniq([...selectedProductIds, ...productIds]));
      } else {
        const productIdSet = new Set(products.map((product) => product._id));
        setSelectedProductIds(
          selectedProductIds.filter((id) => !productIdSet.has(id))
        );
      }
    },
    [products, selectedProductIds]
  );

  const handleCheckedChange = useCallback(
    (productId: string) => (checked: boolean) => {
      if (checked) {
        setSelectedProductIds(uniq([...selectedProductIds, productId]));
        return;
      }

      setSelectedProductIds(
        selectedProductIds.filter((id) => id !== productId)
      );
    },
    [selectedProductIds]
  );

  const selectedProductIdSet = new Set(selectedProductIds);
  const isTotalChecked =
    products.length !== 0 &&
    products.every((product) => selectedProductIdSet.has(product._id));

  const isChecked = (productId: string) =>
    selectedProductIds.includes(productId);

  return {
    selectedProductIds,
    clearSelectedProductIds,
    isTotalChecked,
    isChecked,
    onTotalCheckedChange: handleTotalCheckedChange,
    onCheckedChange: handleCheckedChange,
  };
}
