import { http, HttpResponse } from 'msw';
import { Order } from '@sweep/contract';
import { isNotNil, LocalStorageValue, sleep } from '@sweep/utils';
import { BACKEND_URL } from 'src/env';
import { LOCALSTORAGE_FASTAPI_PREFIX } from 'src/mocks/common/constants';
import {
  CheckedAddress,
  CheckOrdersAddressResponse,
} from 'src/network/fastapi';

const FASTAPI_URL = `${BACKEND_URL}/fastapi`;

export type CheckAddressType =
  | 'success'
  | 'success-long-time'
  | 'fail-long-time'
  | 'fail'
  | 'none';
export const checkAddressTypeStorage = new LocalStorageValue<CheckAddressType>({
  key: `${LOCALSTORAGE_FASTAPI_PREFIX}/check-address`,
  defaultValue: 'none',
});

export function createCheckAddressHandler() {
  const checkAddressType = checkAddressTypeStorage.value;

  if (checkAddressType === 'none') {
    return null;
  }

  return http.post(`${FASTAPI_URL}/correct_orders`, async ({ request }) => {
    if (
      checkAddressType === 'success-long-time' ||
      checkAddressType === 'fail-long-time'
    ) {
      await sleep(2000);
    }

    const orders = (await request.json()) as Order[];
    const orderAddressList = orders
      .map((order) => order.address)
      .filter(isNotNil);

    const checkedAddressList = orderAddressList.map<CheckedAddress>(
      (address) =>
        checkAddressType === 'fail'
          ? {
              result: 'fail',
              data: address,
              orig_data: address,
              main_address: '',
              detail_address: '',
              tried_data: address,
              message: ['시도가 없습니다.'],
              tried_tried_data: [],
            }
          : {
              result: 'okay',
              data: address,
              main_address: address,
              detail_address: '',
            }
    );

    return HttpResponse.json<CheckOrdersAddressResponse>({
      success: true,
      data: checkedAddressList,
    });
  });
}
