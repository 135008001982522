import * as Sentry from '@sentry/react';
import { NODE_ENV } from 'src/env';
// import { useEffect } from 'react';
// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useLocation,
//   useNavigationType,
// } from 'react-router-dom';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN_FOR_FRONTEND;

const IS_LOCAL = NODE_ENV === 'local';
const IS_SENTRY_ENABLED = !IS_LOCAL;

if (IS_SENTRY_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: NODE_ENV,

    // NOTE @hunghoang: heap size 초과로 빌드 실패하는 문제 해결 전까지 주석 처리
    // integrations: [
    //   Sentry.reactRouterV6BrowserTracingIntegration({
    //     useEffect,
    //     useLocation,
    //     useNavigationType,
    //     createRoutesFromChildren,
    //     matchRoutes,
    //   }),
    //   Sentry.replayIntegration(),
    // ],
    // tracesSampleRate: 1.0,
    // tracePropagationTargets: [/^https:\/\/api(-dev)?\.sweepingoms\.com/],
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  });
}
