import { Order } from '@sweep/contract';
import { Sort } from '../TableFilterStore';

export function sortOrders<T extends Order, U = T | T[]>(
  orders: U[],
  sort: Sort
): U[] {
  if (sort.key === null || sort.type === null) {
    return orders;
  }

  const { key, type } = sort;
  return orders.sort((a, b) => {
    const aItem = Array.isArray(a) ? a.at(0) : a;
    const bItem = Array.isArray(b) ? b.at(0) : b;
    const aValue = aItem?.[key]?.toLocaleString();
    const bValue = bItem?.[key]?.toLocaleString();

    if (aValue === bValue) {
      return 0;
    }

    if (aValue == null) {
      return 1;
    }

    if (bValue == null) {
      return -1;
    }

    if (type === 'ASC') {
      return aValue.localeCompare(bValue);
    }

    return bValue.localeCompare(aValue);
  });
}
