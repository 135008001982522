import { observer } from 'mobx-react-lite';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { IconArrowRightLarge } from 'src/icons';
import { OrderProcessType } from '../../OrderProcessScreen';
import { getCountByProcess } from '../../services/getCountByProcess';
import OrderProcessRadio from './OrderProcessRadio';

interface OrderProcessRadioGroupProps {
  value: OrderProcessType;
  onChange: (process: OrderProcessType) => void;
}

function OrderProcessRadioGroup({
  value,
  onChange,
}: OrderProcessRadioGroupProps) {
  const oms = useOMSStore();
  const counts = getCountByProcess(oms.order.normalizedOrders);

  return (
    <div className="gap-24px p-20px rounded-8px shadow-line flex flex-wrap bg-white">
      <OrderProcessRadio
        process="total"
        active={value === 'total'}
        count={counts.total}
        onSelect={() => onChange('total')}
      />
      <div className="w-1px shrink-0 bg-gray-200" />
      <div className="gap-8px flex flex-1 flex-wrap items-center">
        <OrderProcessRadio
          process="pending"
          active={value === 'pending'}
          count={counts.pending}
          onSelect={() => onChange('pending')}
        />
        <IconArrowRightLarge className="text-gray-300" />
        <OrderProcessRadio
          process="shipping-process"
          active={value === 'shipping-process'}
          count={counts['shipping-process']}
          onSelect={() => onChange('shipping-process')}
        />
        <IconArrowRightLarge className="text-gray-300" />
        <OrderProcessRadio
          process="shipping-complete"
          active={value === 'shipping-complete'}
          count={counts['shipping-complete']}
          onSelect={() => onChange('shipping-complete')}
        />
      </div>
    </div>
  );
}

export default observer(OrderProcessRadioGroup);
