import {
  logoMallAli,
  logoMallAlwayz,
  logoMallAuction,
  logoMallCafe24,
  logoMallCoupang,
  logoMallEleven,
  logoMallEmart,
  logoMallGmarket,
  logoMallKakao,
  logoMallMpoint,
  logoMallNaverss,
  logoMallOhouse,
  logoMallShop,
  logoMallTmon,
} from 'images/logo-mall';
import { cva } from '@sweep/tailwind';

export const BRANDS = [
  'shop',
  'gmarket',
  'eleven',
  'tmon',
  'mpoint',
  'ohouse',
  'auction',
  'emart',
  'ali',
  'naver',
  'kakao',
  'coupang',
  'alwayz',
  'cafe24',
] as const;

export type Brand = (typeof BRANDS)[number];

export interface LogoMallProps {
  brand: Brand;
  size?: 'medium' | 'small';
  className?: string;
}

export function LogoMall({
  brand,
  size = 'medium',
  className = '',
}: LogoMallProps) {
  const alt = `${brand} 로고`;
  const image = IMAGE_MAP[brand];

  return (
    <img src={image} alt={alt} className={imageClass({ size, className })} />
  );
}

const imageClass = cva('rounded-full', {
  variants: {
    size: {
      medium: 'size-[30px]',
      small: 'size-[24px]',
    },
  },
});

const IMAGE_MAP: Record<Brand, string> = {
  shop: logoMallShop,
  gmarket: logoMallGmarket,
  eleven: logoMallEleven,
  tmon: logoMallTmon,
  mpoint: logoMallMpoint,
  ohouse: logoMallOhouse,
  auction: logoMallAuction,
  emart: logoMallEmart,
  ali: logoMallAli,
  naver: logoMallNaverss,
  kakao: logoMallKakao,
  coupang: logoMallCoupang,
  alwayz: logoMallAlwayz,
  cafe24: logoMallCafe24,
};
