import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../../../components/buttons/Button';
import LoadingIndicator from '../../../components/LoadingIndicator';
import OMSContainer from '../../../components/OMSContainer';
import SupplierForm from '../../../forms/SupplierForm';
import Modal from '../../../modals/Modal';
import LoadingStore from '../../../stores/LoadingStore';
import backendApi from '../../../utils/backendApi';
import PartnerList from '../components/PartnerList';

const SupplierManagementScreen = observer(() => {
  const oms = useOMSStore();
  const [selectedSupplierId, setSelectedSupplierId] = useState(null); // 수정할 파트너 선택
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // 파트너 추가 또는 수정 폼 제출
  const handleRegisterSupplier = async (data) => {
    try {
      const res = await backendApi.registerSupplier(data);

      if (res.success) {
        const newSupplierInfo = res.data;
        const updatedSupplierInfos = [
          ...oms.supplier.suppliers,
          newSupplierInfo,
        ];
        oms.supplier.setSuppliers(updatedSupplierInfos);

        alert('공급사 등록이 완료되었어요.');

        setIsRegistrationModalOpen(false);
      }
    } catch (err) {
      console.error('Error registering product :', err);
      throw err;
    }
  };

  // 파트너 추가 또는 수정 폼 제출
  const handleEditSupplier = async (data) => {
    const res = await backendApi.updateSupplier(selectedSupplierId, data);

    if (res.success) {
      const updatedSupplierInfos = oms.supplier.suppliers.map((info) => {
        if (info._id === selectedSupplierId) {
          return { ...info, ...data };
        }
        return info;
      });
      oms.supplier.setSuppliers(updatedSupplierInfos);

      alert('공급사 정보 수정이 완료되었어요.');

      setIsEditModalOpen(false);
      setSelectedSupplierId(null);
    }
  };

  const handleDeleteSupplier = async (supplierId) => {
    const isConfirmed = window.confirm(
      '정말로 해당 공급사를 삭제하시겠습니까?'
    );

    if (!isConfirmed) {
      return; // 사용자가 취소를 선택한 경우, 작업 중단
    }

    try {
      const res = await backendApi.deleteSupplier(supplierId);

      if (res.success) {
        const updatedSupplierInfos = oms.supplier.suppliers.filter((info) => {
          return info._id !== supplierId;
        });
        oms.supplier.setSuppliers(updatedSupplierInfos);

        alert('공급사가 삭제되었어요.');

        setIsRegistrationModalOpen(false);
      }
    } catch (err) {
      console.error('Error deleting supplier :', err);
      throw err;
    }
  };

  // 파트너 정보 수정 모달 열기
  const handleEditModalOpen = (supplierId) => {
    setSelectedSupplierId(supplierId);
    setIsEditModalOpen(true);
  };

  return (
    <OMSContainer>
      <div className="flex justify-between">
        <h1 className="font-pretendard text-[24px] font-bold leading-[30px] text-[color:var(--Gray-600,#343D4B)]">
          공급사 등록 및 관리
        </h1>
        <Button
          name={`+ 공급사 추가`}
          onClick={() => {
            setSelectedSupplierId(null);
            setIsRegistrationModalOpen(true);
          }}
        />
      </div>

      {/* PartnerList의 구조가 어차피 SupplierList의 구조와 같을 것이므로 PartnerList 컴포넌트 사용 */}
      <div className="mt-[20px]">
        <PartnerList
          partners={oms.supplier.suppliers}
          onClick={(supplierId) => {
            handleEditModalOpen(supplierId);
          }}
          onDelete={(supplierId) => {
            handleDeleteSupplier(supplierId);
          }}
        />
      </div>

      {isRegistrationModalOpen && (
        <Modal
          header="공급사 추가"
          onClose={() => {
            const isConfirmed = window.confirm(
              `공급사를 추가하지 않고 화면을 닫으시겠습니까?`
            );

            if (!isConfirmed) {
              return; // 사용자가 취소를 선택한 경우, 작업 중단
            }

            setIsRegistrationModalOpen(false);
          }}
          backdropClick={false}
          tailwindWidth="w-10/12"
        >
          <SupplierForm type="register" onFormSubmit={handleRegisterSupplier} />
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal
          header="공급사 정보 수정"
          onClose={() => {
            const isConfirmed = window.confirm(
              `공급사 정보를 수정하지 않고 화면을 닫으시겠습니까?`
            );

            if (!isConfirmed) {
              return; // 사용자가 취소를 선택한 경우, 작업 중단
            }

            setIsEditModalOpen(false);
            setSelectedSupplierId(null);
          }}
          backdropClick={false}
          tailwindWidth="w-10/12"
        >
          <SupplierForm
            type="update"
            supplier={oms.supplier.suppliers.find(
              (info) => info._id === selectedSupplierId
            )}
            onFormSubmit={handleEditSupplier}
          />
        </Modal>
      )}

      {LoadingStore?.isLoading && <LoadingIndicator />}
    </OMSContainer>
  );
});

export default SupplierManagementScreen;
