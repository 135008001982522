export async function tryCatch<T>(
  fn: () => Promise<T>,
  onError?: (error: any) => void
): Promise<T | null> {
  try {
    return await fn();
  } catch (e) {
    onError?.(e);
    return null;
  }
}
