import { size } from '@floating-ui/react';

export const maxHeightOnViewport = (maxHeight?: number) =>
  size({
    apply({ availableHeight: givenAvailableHeight, rects, elements }) {
      const availableHeight = Math.max(0, givenAvailableHeight);

      Object.assign(elements.floating.style, {
        width: `${rects.reference.width}px`,
        maxHeight: `${Math.min(maxHeight ?? Infinity, availableHeight)}px`,
      });
    },
  });
