import DefaultTableCell from './DefaultTableCell';
import { CellContext, ColumnDef } from './interface';

interface TableCellRenderProps<T> {
  row: T;
  column: ColumnDef<T>;
  context: CellContext<T>;
}

function TableCellRender<T>({ row, column, context }: TableCellRenderProps<T>) {
  const cellContent = column.cell?.(context);
  if (cellContent != null) {
    return cellContent;
  }

  const value = column.accessorFn?.(row);
  return <DefaultTableCell context={context}>{value}</DefaultTableCell>;
}

export default TableCellRender;
