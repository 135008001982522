import { NormalizedOrder } from '@sweep/contract';
import { isNotEmptyString } from 'src/utils/string';

export function isShippingCompleteOrder(order: NormalizedOrder) {
  return (
    order.process === 'shipping' &&
    isNotEmptyString(order.shippingCompany) &&
    isNotEmptyString(order.shippingNumber)
  );
}
