import { createContext, useContext } from 'react';
import { noop } from '@sweep/utils';

export interface TableContextValue {
  columnWidths: Record<number, number>;
  onWidthChange: (index: number, width: number) => void;
}

const tableContext = createContext<TableContextValue>({
  columnWidths: {},
  onWidthChange: noop,
});

export const TableContextProvider = tableContext.Provider;

export const useTableContext = () => useContext(tableContext);
