import { useEffect, useRef } from 'react';
import { cva } from '@sweep/tailwind';
import { useIsOverflow } from '@sweep/utils';
import { Tooltip } from '../../Tooltip';
import { TableCellProps } from './interface';
import { useTableContext } from './TableContext';
import { spanToBaggageHeader } from '@sentry/react';

const SCROLL_WIDTH_TOLERANCE = 3;

function DefaultTableCell<T>({
  context,
  className,
  children,
  ...rest
}: TableCellProps<T>) {
  const { columnWidths, onWidthChange } = useTableContext();
  const { lastColumn = false, lastRow = false, columnIndex } = context;
  const columnWidth = columnWidths[columnIndex];
  const width = columnWidth != null ? `${columnWidth}px` : undefined;

  const ref = useRef<HTMLDivElement | null>(null);
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const isOverflow = useIsOverflow(spanRef, 'x');

  useEffect(() => {
    if (ref.current != null && columnWidth == null) {
      onWidthChange(
        columnIndex,
        ref.current.scrollWidth + SCROLL_WIDTH_TOLERANCE
      );
    }
  }, [columnIndex, onWidthChange, columnWidth]);

  return (
    <Tooltip offset={0}>
      <Tooltip.Trigger>
        <div
          ref={ref}
          className={cellClass({ lastColumn, lastRow, className })}
          style={{ maxWidth: width, width, ...rest.style }}
          {...rest}
        >
          {typeof children === 'string' ? (
            <span ref={spanRef} className="text-ellipsis-nowrap">
              {children}
            </span>
          ) : (
            children
          )}
        </div>
      </Tooltip.Trigger>
      {isOverflow && typeof children === 'string' && (
        <Tooltip.Content>{children}</Tooltip.Content>
      )}
    </Tooltip>
  );
}

const cellClass = cva(
  [
    'flex h-[37px] items-center',
    'px-16px bg-white py-0',
    'text-medium-s text-left text-gray-700',
  ],
  {
    variants: {
      lastColumn: {
        true: '',
        false: 'border-r border-gray-200',
      },
      lastRow: {
        true: '',
        false: 'border-b border-gray-200',
      },
    },
  }
);

export default DefaultTableCell;
