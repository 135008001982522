import Button from 'components/buttons/Button';
import PartnerColumnMatchingInput from 'forms/partner/common/PartnerColumnMatchingInput';
import { useOMSStore } from 'hooks/useOMSStore';
import { IconArrowModal } from 'icons/index';
import { observer } from 'mobx-react-lite';
import { Matching, Partner } from 'models/Partner';
import { Fragment, useState } from 'react';
import { ColumnMappingGuide } from 'services/column-mapping/columnMappingGuide';
import { findEstimatedColumnMapping } from 'services/column-mapping/findEstimatedColumnMapping';
import { isNotNil } from '@sweep/utils';
import { ColumnMapping } from 'src/models/ColumnMapping';
import { FileMatching } from '../interface';
import WarningBanner from './WarningBanner';

interface PartnerUpdateHeaderFormProps {
  fileMatchings: FileMatching[];
  onSubmit: (partners: Partner[]) => void;
}

function PartnerUpdateHeaderForm({
  fileMatchings,
  onSubmit,
}: PartnerUpdateHeaderFormProps) {
  const oms = useOMSStore();

  const [matchings, setMatchings] = useState<Record<string, Matching>>(
    getInitialMatchings(fileMatchings)
  );

  const handleColumnMapping =
    (partnerId: string) => (columnMapping: ColumnMapping) => {
      setMatchings({
        ...matchings,
        [partnerId]: {
          ...matchings[partnerId],
          columnMapping,
        },
      });
    };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const partners = fileMatchings.map(({ partner }) => partner);

    await Promise.all(
      partners.map((partner) =>
        oms.partner.update(partner._id, {
          matchings: [...(partner.matchings ?? []), matchings[partner._id]],
        })
      )
    );

    onSubmit(oms.partner.partners);
  };

  return (
    <form className="px-8" onSubmit={handleSubmit}>
      <WarningBanner>
        올리신 파일과 선택하신 파트너사의 양식에 차이가 있어요.
        <br />
        확인 후 매칭을 완료해주세요.
      </WarningBanner>

      <div className="mt-8 flex">
        <div className="flex flex-1 items-center bg-[#F5F7FA] px-[20px] py-[10px]">
          <p className="text-[14px] font-bold">파일명</p>
        </div>
        <div className="flex w-[40px] items-center justify-center">
          <IconArrowModal color="white" />
        </div>
        <div className="flex flex-1 items-center bg-[#F5F7FA] px-[20px] py-[10px]">
          <p className="text-[14px] font-bold">파트너사</p>
        </div>
      </div>

      {fileMatchings.map((fileMatching, index) => {
        const { header, columnMapping, exampleContents } =
          matchings[fileMatching.partner._id];
        const filename = fileMatching.file.name;

        return (
          <Fragment key={filename}>
            {index !== 0 && <hr className="my-4 border-[#EBF0F5]" />}
            <div className="flex items-center pb-2 pt-4">
              <span className="flex-1 text-lg font-semibold">{filename}</span>
              <div className="w-[40px]" />
              <span className="flex-1 text-lg font-semibold">
                {fileMatching.partner.name}
              </span>
            </div>
            <PartnerColumnMatchingInput
              key={filename}
              header={header}
              columnMapping={columnMapping}
              exampleContents={exampleContents}
              onChange={handleColumnMapping(fileMatching.partner._id)}
            />
          </Fragment>
        );
      })}
      <Button name="적용" type="submit" className="float-right mr-10" />
    </form>
  );
}

function getInitialMatchings(
  fileMatchings: FileMatching[]
): Record<string, Matching> {
  return Object.fromEntries(
    fileMatchings
      .map(({ file, partner }) => {
        const header = file.data.at(0);
        if (header == null) {
          return null;
        }

        const guides = partner.matchings?.map((matching) =>
          transformationToGuide(matching.columnMapping)
        );
        const columnMapping = findEstimatedColumnMapping(header, guides);
        const matching: Matching = {
          id: self.crypto.randomUUID(),
          header,
          columnMapping,
          exampleContents: file.data.slice(1, 7),
        };

        return [partner._id, matching] as const;
      })
      .filter(isNotNil)
  );
}

function transformationToGuide(
  transformation: ColumnMapping
): ColumnMappingGuide {
  return Object.fromEntries(
    Object.entries(transformation)
      .map(([key, value]) => (value == null ? null : [key, [value]]))
      .filter(isNotNil)
  );
}

export default observer(PartnerUpdateHeaderForm);
