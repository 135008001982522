import { Nullable } from '@sweep/utils';
import { StringSchema } from '../schema';

interface JsonStringInputProps {
  schema: StringSchema;
  value: Nullable<string>;
  onChange: (value: string) => void;
}

export function JsonStringInput({
  schema,
  value,
  onChange,
}: JsonStringInputProps) {
  return (
    <input
      type="text"
      value={value ?? ''}
      onChange={(event) => onChange(event.target.value)}
    />
  );
}
