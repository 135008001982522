import { z } from 'zod';
import { ShoppingMall } from '../type/DispatchAutoToken';
import { DispatchedOrder } from '../type/order/DispatchedOrder';

const DISPATCH_ORDER_URL = '/dispatch-order';

// GET /dispatch-order/fetch-orders-polling
export const DISPATCH_ORDER_POllING_URL = `/dispatch-order/fetch-orders-polling`;

export const DispatchOrderPollingQuery = z.object({
  requestId: z.string(),
});
export type DispatchOrderPollingQuery = z.infer<
  typeof DispatchOrderPollingQuery
>;

export type DispatchOrderPollingResponse =
  | { status: 'ON_GOING' }
  | {
      status: 'FINISH';
      data: DispatchedOrder[];
    };

// GET /dispatch-order/orders/search
export const DISPATCH_ORDER_SEARCH_URL = `${DISPATCH_ORDER_URL}/orders/search`;

export const DispatchOrderSearchQuery = z.object({
  shoppingMall: z.string().optional(),
  orderNumber: z.string().optional(),
  contactNumber: z.string().optional(),
  name: z.string().optional(),
});

export type DispatchOrderSearchQuery = z.infer<typeof DispatchOrderSearchQuery>;

export type DispatchOrderSearchResponse = DispatchedOrder[];

// GET /dispatch-order/shopping-malls
export const GET_SHOPPING_MALLS_URL = `${DISPATCH_ORDER_URL}/shopping-malls`;

export type GetShoppingMallsResponse = ShoppingMall[];
