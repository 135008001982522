import { ColumnMapping } from 'src/models/ColumnMapping';

export function findHeaderKeys(
  header: string[],
  columnMapping: ColumnMapping
): (string | null)[] {
  return header.map((value, index) => {
    const matchedEntry = Object.entries(columnMapping).find(([_, header]) => {
      return header === value || header === index + 1;
    });
    return matchedEntry ? matchedEntry[0] : null;
  });
}
