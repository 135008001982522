import { useId } from 'react';
import { Nullable } from '@sweep/utils';
import { Checkbox } from '../../Checkbox';
import { BooleanSchema } from '../schema';

interface JsonBooleanInputProps {
  schema: BooleanSchema;
  value: Nullable<boolean>;
  onChange: (value: boolean) => void;
}

export function JsonBooleanInput({
  schema,
  value,
  onChange,
}: JsonBooleanInputProps) {
  const id = useId();
  return (
    <div className="gap-8px flex items-center">
      <label htmlFor={id} className="text-medium-m text-gray-700">
        {schema.description}
      </label>
      <Checkbox
        id={id}
        checked={value ?? schema.defaultValue ?? false}
        onCheckedChange={onChange}
      />
    </div>
  );
}
