import { Button } from 'design-system/components/Button';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { MatchedProduct } from 'models/CompositionMatching';
import { useState } from 'react';
import { isNotNil } from '@sweep/utils';
import { CMOrder, ProductMatching } from '../../interface';
import { transformToCMProducts } from '../../services/transformToCMProducts';
import CMHeader from '../common/CMHeader';
import CMInput from '../common/CMInput';
import CMOrderLabel from '../common/CMOrderLabel';

interface MatchedCMEditFormProps {
  matchings: ProductMatching[];
  onSubmit: (matchings: ProductMatching[]) => void;
}

interface InternalProductMatching {
  cmOrder: CMOrder;
  products: Partial<MatchedProduct>[];
}

function MatchedCMEditForm({
  matchings: givenMatchings,
  onSubmit,
}: MatchedCMEditFormProps) {
  const oms = useOMSStore();
  const [matchings, setMatchings] =
    useState<InternalProductMatching[]>(givenMatchings);

  const handleProductsChange =
    (index: number) => (value: Partial<MatchedProduct>[]) => {
      const newInternalMatchings = [
        ...matchings.slice(0, index),
        { ...givenMatchings[index], products: value },
        ...matchings.slice(index + 1),
      ];

      setMatchings(newInternalMatchings);
    };

  const onSaveClick = () => {
    const newMatchings = matchings
      .map((internalMatching) => {
        if (internalMatching == null) {
          return null;
        }

        const transformedProducts = transformToCMProducts(
          internalMatching.products,
          oms
        );

        if (transformedProducts.length === 0) {
          return null;
        }

        return { ...internalMatching, products: transformedProducts };
      })
      .filter(isNotNil);

    const isContainEmptyProducts = newMatchings.length !== matchings.length;

    if (isContainEmptyProducts) {
      alert('상품을 모두 입력해주세요.');
      return;
    }

    onSubmit(newMatchings);
  };

  return (
    <div className="flex flex-col">
      <CMHeader />
      {matchings.map(({ cmOrder, products }, index) => (
        <div key={index} className="flex border-b border-gray-200 py-4">
          <CMOrderLabel cmOrder={cmOrder} />
          <div className="w-[42px] shrink-0" />
          <CMInput
            required
            value={products}
            onChange={handleProductsChange(index)}
          />
        </div>
      ))}
      <div className="h-[20px]" />
      <Button onClick={onSaveClick} className="m-auto">
        수정사항 저장하기
      </Button>
    </div>
  );
}

export default observer(MatchedCMEditForm);
