import { NormalizedOrder } from '@sweep/contract';
import { isEmptyString } from 'src/utils/string';

export function filterCMOrders(orders: NormalizedOrder[]): {
  unmatchedOrders: NormalizedOrder[];
  matchedOrders: NormalizedOrder[];
} {
  const unmatchedOrders: NormalizedOrder[] = [];
  const matchedOrders: NormalizedOrder[] = [];

  orders.forEach((order) => {
    if (order.data == null || order.data.length === 0) {
      unmatchedOrders.push(order);
      return;
    }

    const products = order.data;
    if (products.some((product) => isEmptyString(product.productId))) {
      unmatchedOrders.push(order);
      return;
    }

    matchedOrders.push(order);
  });

  return { unmatchedOrders, matchedOrders };
}
