import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { SwitchCase } from '@sweep/utils';
import useFileHandling from 'src/hooks/useFileHandling';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { OrderXX } from 'src/models/OrderXX';
import OrderProcessRadioGroup from './components/OrderProcessRadioGroup/OrderProcessRadioGroup';
import OrderPendingSection from './sections/OrderPendingSection';
import OrderShippingCompleteSection from './sections/OrderShippingCompleteSection';
import OrderShippingProcessSection from './sections/OrderShippingProcessSection';
import OrderTotalSection from './sections/OrderTotalSection';

export type OrderProcessType =
  | 'total'
  | 'pending'
  | 'shipping-process'
  | 'shipping-complete';

function OrderProcessScreen() {
  const oms = useOMSStore();

  const [process, setProcess] = useState<OrderProcessType>('pending');
  const { normalizeOrders } = useFileHandling();

  useEffect(() => {
    const draftFiles = oms.order.draftFiles;
    const { filenames, orders } = draftFiles;
    if (orders.length === 0) {
      return;
    }

    normalizeOrders(orders as OrderXX[]).then(() =>
      oms.loading.batch(() => oms.order.draft.process(filenames))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oms.loading, oms.order.draft, oms.order.draftFiles]);

  if (oms.order.component) {
    return oms.order.component;
  }

  return (
    <div className="px-40px py-20px gap-32px flex h-full flex-col bg-gray-100">
      <h1 className="text-extrabold-l font-bold text-gray-700">
        발주 진행하기
      </h1>
      <div className="gap-20px flex flex-col">
        <OrderProcessRadioGroup value={process} onChange={setProcess} />
        <SwitchCase
          value={process}
          caseBy={{
            total: <OrderTotalSection />,
            pending: <OrderPendingSection />,
            'shipping-process': <OrderShippingProcessSection />,
            'shipping-complete': <OrderShippingCompleteSection />,
          }}
        />
      </div>
    </div>
  );
}

export default observer(OrderProcessScreen);
