import { HTMLAttributes, ReactNode } from 'react';
import { cva } from '@sweep/tailwind';

export interface DialogFooterProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export function DialogFooter({
  children,
  className,
  ...rest
}: DialogFooterProps) {
  return (
    <div className={footer({ className })} {...rest}>
      {children}
    </div>
  );
}

const footer = cva('flex justify-between gap-[12px]');
