import { observer } from 'mobx-react-lite';
import { UserSetting } from '@sweep/contract';
import { JsonInput, Schema } from 'src/design-system/components/JsonInput';

interface UserSettingProps {
  value: UserSetting;
  onChange: (setting: UserSetting) => void;
}

function UserSettingInput({ value, onChange }: UserSettingProps) {
  return (
    <div>
      <p className="text-semibold-2xl">User Setting</p>
      <JsonInput
        schema={USER_SETTING_SCHEMA}
        value={value}
        onChange={onChange}
        collapse={false}
      />
    </div>
  );
}

const USER_SETTING_SCHEMA: Schema = {
  type: 'object',
  properties: {
    doseosangan: {
      type: 'object',
      description: '도서산간',
      properties: {
        enabled: {
          type: 'boolean',
        },
        standard: {
          type: 'enum',
          description: '택배사 기준',
          options: [
            { value: 'CJ', label: 'CJ대한통운' },
            { value: 'LOTTE', label: '롯데' },
          ],
        },
      },
    },
    compositionMatching: {
      type: 'object',
      description: '컴포지션 매칭',
      properties: {
        optionCodeMAtching: {
          type: 'boolean',
          description: '옵션코드 매칭',
        },
      },
    },
    uploadHandWrittenFile: {
      type: 'boolean',
      description: '수기 엑셀 업로드',
    },
  },
};

export default observer(UserSettingInput);
