import { cva } from '@sweep/tailwind';

interface DividerProps {
  type: 'horizontal' | 'vertical';
}

function Divider({ type }: DividerProps) {
  return <div className={divider({ type })} />;
}

const divider = cva('shrink-0 bg-gray-200', {
  variants: {
    type: {
      vertical: 'w-1px',
      horizontal: 'h-1px',
    },
  },
});

export default Divider;
