import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  deleteProduct,
  getProducts,
  registerProduct,
  updateProduct,
  updateProductMany,
} from 'network/product';
import {
  Product,
  RegisterProductDTO,
  UpdateProductDTO,
  UpdateProductManyDTO,
} from '@sweep/contract';
import { isEmptyString } from 'src/utils/string';
import { OMSStore } from './OMSStore';

export class ProductStore {
  products: Product[] = [];

  get productNames() {
    return this.products.map((product) => product.productName);
  }

  get productIdByName() {
    return new Map<string, string>(
      this.products.map((product) => [product.productName, product._id])
    );
  }

  getProduct(id: string) {
    return this.products.find((product) => product._id === id);
  }

  getProductByName(name: string) {
    return this.products.find((product) => product.productName === name);
  }

  getSupplierId(
    productId: string,
    context?: {
      optionName?: string | null;
      partnerId?: string | null;
      shoppingMallId?: string | null;
    }
  ) {
    const { optionName, partnerId, shoppingMallId } = context ?? {};

    const product = this.getProduct(productId);
    if (product == null) {
      return null;
    }

    if (product.useSupplierByOption === true && optionName != null) {
      const option = product.units?.find((unit) => unit.unit === optionName);
      if (option == null) {
        return null;
      }

      return option.supplierId;
    }

    if (product.useSupplierBySalesChannel === true) {
      if (partnerId != null) {
        const salesChannelSupplierId = product.salesChannelSupplierIds?.find(
          (salesChannelSupplierId) =>
            'partnerId' in salesChannelSupplierId &&
            salesChannelSupplierId.partnerId === partnerId
        );

        if (salesChannelSupplierId != null) {
          return salesChannelSupplierId.supplierId;
        }
      }

      if (shoppingMallId != null) {
        const salesChannelSupplierId = product.salesChannelSupplierIds?.find(
          (salesChannelSupplierId) =>
            'shoppingMallId' in salesChannelSupplierId &&
            salesChannelSupplierId.shoppingMallId === shoppingMallId
        );

        if (salesChannelSupplierId != null) {
          return salesChannelSupplierId.supplierId;
        }
      }
    }

    // TODO(@이지원): supplierId가 어디서 ""으로 들어오는지 확인하고 삭제
    return isEmptyString(product.supplierId) ? null : product.supplierId;
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      products: observable,

      productNames: computed,
      productIdByName: computed,

      init: action.bound,
      loadProducts: action.bound,
      register: action.bound,
      update: action.bound,
      deleteMany: action.bound,
    });
  }

  async init() {
    await this.loadProducts();
  }

  loadProducts = async () => {
    const response = await getProducts();
    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      const products = response.data ?? [];
      this.products = products.sort((p1, p2) =>
        p1.productName.localeCompare(p2.productName)
      );
    });
  };

  async register(product: RegisterProductDTO) {
    const response = await registerProduct(product);
    if (response.success === false) {
      return null;
    }
    const registeredProduct = response.data;

    runInAction(() => {
      this.products = [registeredProduct, ...this.products];
    });

    return registeredProduct;
  }

  async update(
    productId: string,
    product: UpdateProductDTO
  ): Promise<Product | null> {
    const index = this.products.findIndex(
      (product) => product._id === productId
    );
    if (index === -1) {
      return null;
    }

    const prevProduct = this.products[index];
    this.products[index] = {
      ...prevProduct,
      ...product,
    };

    const response = await updateProduct(productId, product);
    if (response?.success !== true) {
      this.products[index] = prevProduct;
      return null;
    }

    return this.products[index];
  }

  async updateMany(products: UpdateProductManyDTO[]) {
    this.products = this.products.map((product) => {
      const updatedProduct = products.find((p) => p._id === product._id);
      if (updatedProduct == null) {
        return product;
      }

      return {
        ...product,
        ...updatedProduct,
      };
    });

    const response = await updateProductMany(products);
    if (response?.success !== true) {
      await this.loadProducts();

      return false;
    }

    return true;
  }

  async deleteMany(productIds: string[]) {
    this.products = this.products.filter(
      (product) => !productIds.includes(product._id)
    );

    const deletePromises = productIds.map((productId) =>
      deleteProduct(productId)
    );

    const responses = await Promise.all(deletePromises);

    if (responses.some((response) => response?.success !== true)) {
      await this.loadProducts();
      return false;
    }

    return true;
  }
}
