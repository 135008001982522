import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

export class AddSupplierNamePlugin extends AbstractPlugin<undefined> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map((order) => this.transformOrder(order)));
  };

  transformOrders = (orders: Order[]): Order[] => {
    if (this.oms.supplier.suppliers.length === 0) {
      return orders;
    }

    return orders.map((order) => this.transformOrder(order));
  };

  transformOrder = (order: Order): Order => {
    const supplierId = order.supplierId;
    if (supplierId == null) {
      return order;
    }

    const supplier = this.oms.supplier.getSupplier(supplierId);
    if (supplier == null) {
      return order;
    }

    return {
      ...order,
      supplierName: supplier.name,
    };
  };
}
