import { Number } from 'design-system/components/Number';
import { IconControlNext } from 'icons/index';
import { PropsWithChildren, ReactNode } from 'react';
import { cva } from '@sweep/tailwind';

interface CMProgressProps {
  children?: ReactNode;
  rightAccesory?: ReactNode;
}

function CMProgress({ children, rightAccesory }: CMProgressProps) {
  return (
    <div className="flex w-full justify-between rounded-[12px] bg-white p-4 shadow-sm">
      <div className="flex items-center gap-[6px]">{children}</div>
      {rightAccesory}
    </div>
  );
}

CMProgress.Title = CMProgressTitle;
CMProgress.NextIcon = CMProgressNextIcon;
CMProgress.Description = CMProgressDescription;

interface CMProgressTitleProps {
  active?: boolean;
  index: number;
  children: ReactNode;
}

function CMProgressTitle({
  active = false,
  index,
  children,
}: CMProgressTitleProps) {
  return (
    <div className="flex gap-[6px]">
      <Number active={active}>{index}</Number>
      <p className={title({ active })}>{children}</p>
    </div>
  );
}

const title = cva('text-bold-m', {
  variants: {
    active: {
      true: 'text-gray-600',
      false: 'text-gray-400',
    },
  },
});

function CMProgressNextIcon({ className }: { className?: string }) {
  return <IconControlNext className={icon({ className })} />;
}

const icon = cva('text-gray-400');

function CMProgressDescription({ children }: PropsWithChildren) {
  return (
    <div className="text-bold-m rounded-[4px] bg-gray-100 px-[20px] py-[10px] text-gray-600">
      {children}
    </div>
  );
}

export default CMProgress;
