import axios, { isAxiosError } from 'axios';
import { BACKEND_URL } from 'src/env';

const token = localStorage.getItem('@authToken');

const api = axios.create({
  baseURL: BACKEND_URL,
  headers: token != null ? { Authorization: token } : {},
});

api.interceptors.request.use(function (config) {
  const token =
    localStorage.getItem('@authToken') ??
    localStorage.getItem('LocalStorageValue#User#authToken');

  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
});

let isServerErrorAlert = false;
let isNetworkConnectErrorAlert = false;

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!navigator.onLine) {
      if (!isNetworkConnectErrorAlert) {
        alert('네트워크 연결이 끊겼어요. 인터넷 연결을 확인해주세요.');
        isNetworkConnectErrorAlert = true;
      }
      return Promise.reject(error);
    }

    if (isAxiosError(error)) {
      const response = error.response;
      const isServerError = response != null && response.status >= 500;
      if (isServerError) {
        if (!isServerErrorAlert) {
          alert('서버에 문제가 발생했어요. 새로고침 후 다시 시도해주세요.');
          isServerErrorAlert = true;
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;

export type APIResponse<T> =
  | { success: true; data: T }
  | { success: false; error: string; customErrorCode?: string };
