import { Button } from 'design-system/components/Button';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { CreateMatching } from 'models/CompositionMatching';
import { overlay } from 'overlay-kit';
import { useState } from 'react';
import { isNotNil } from '@sweep/utils';
import { CMOrder } from '../../interface';
import { transformToCMProducts } from '../../services/transformToCMProducts';
import CMHeader from '../common/CMHeader';
import CMProgress from '../common/CMProgress';
import NotMatchedDialog from './NotMatchedDialog';
import UnmatchedCMFormInput from './UnmatchedCMFormInput';
import { UnmatchedCMFormStore } from './UnmatchedCMFormStore';

interface UnmatchedCMFormProps {
  cmOrders: CMOrder[];
  onSubmit: (value: CreateMatching[] | null) => void;
}

function PreDivideUnmatchedCMForm({
  cmOrders: givenCMOrders,
  onSubmit,
}: UnmatchedCMFormProps) {
  const oms = useOMSStore();
  const companyType = oms.user.companyType;
  const [store] = useState(() => new UnmatchedCMFormStore(givenCMOrders, oms));

  const isSaveDisabled = store.matchings.some(
    (matching) => matching.status !== 'confirm'
  );

  const handleExit = () => {
    onSubmit(null);
  };

  const handleSave = () => {
    const compositionMatchings = store.matchings
      .filter((matching) => matching.status === 'confirm')
      .map<CreateMatching | null>((matching) => {
        const { cmOrder, products: partailProducts } = matching;
        const products = transformToCMProducts(partailProducts, oms);

        if (products.length === 0) {
          return null;
        }

        return {
          productName: cmOrder.productName,
          option: cmOrder.option,
          optionCode: cmOrder.optionCode,
          products,
        };
      })
      .filter(isNotNil);

    if (compositionMatchings.length !== store.matchings.length) {
      overlay.open(({ isOpen, close }) => (
        <NotMatchedDialog
          open={isOpen}
          onClose={close}
          onSave={() => onSubmit(compositionMatchings)}
        />
      ));
      return;
    }

    onSubmit(compositionMatchings);
  };

  return (
    <div className="h-full bg-gray-100 px-10 py-4">
      <div className="h-42px flex items-center justify-between">
        <p className="text-extrabold-l">발주 진행하기</p>
      </div>
      <div className="h-[40px]" />
      <CMProgress>
        <CMProgress.Title index={1} active>
          상품 구성 매칭
        </CMProgress.Title>
        <CMProgress.Description>
          <span className="text-blue-500">{store.matchings.length}개</span>{' '}
          상품에 대해 확인이 필요합니다.
        </CMProgress.Description>
        <CMProgress.Description>
          <span className="text-blue-500">{store.matchedCount}</span>/
          {store.matchings.length}
        </CMProgress.Description>
      </CMProgress>
      <div className="h-[20px]" />
      <div className="items-center rounded-[8px] bg-white p-5 shadow-sm">
        <CMHeader />
        {store.matchings.map((matching, index) => (
          <UnmatchedCMFormInput
            key={index}
            store={store}
            matching={matching}
            index={index}
            disabledHold
          />
        ))}
        <div className="h-[20px]" />
        <div className="gap-12px mx-auto flex w-fit">
          <Button color="lightGray" onClick={handleExit}>
            나가기
          </Button>
          <Button color="blue" onClick={handleSave} disabled={isSaveDisabled}>
            저장하기
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(PreDivideUnmatchedCMForm);
