import { isDispatchedOrder, Order, OrderProduct } from '@sweep/contract';
import { isEmptyString } from 'src/utils/string';
import { AbstractPlugin } from '../../interface';

const NULL_SUPPLIER_ID = 'SUPPLIER_NULL';

export class AddSupplierPlugin extends AbstractPlugin<undefined> {
  transform = (orders: Order[]): Promise<Order[]> => {
    const result = orders.map((order) => this.transformOrder(order)).flat();
    return Promise.resolve(result);
  };

  transformOrder = (order: Order): Order[] => {
    const products = order.data;
    if (products == null) {
      return [order];
    }

    const supplierProducts: Record<string, OrderProduct[]> = {};
    products.forEach((product) => {
      const productId = product.productId;
      const optionName = isEmptyString(product.unit) ? undefined : product.unit;
      const supplierId =
        this.oms.product.getSupplierId(productId, {
          optionName,
          partnerId: order.partnerId,
          shoppingMallId: isDispatchedOrder(order)
            ? order.shoppingMallId
            : undefined,
        }) ?? NULL_SUPPLIER_ID;

      supplierProducts[supplierId] = [
        ...(supplierProducts[supplierId] ?? []),
        product,
      ];
    });

    const supplierIds = Object.keys(supplierProducts);
    if (supplierIds.length === 0) {
      return [order];
    }

    return supplierIds.map<Order>((supplierId, index) => {
      const products = supplierProducts[supplierId];
      const uniqueCodeAfterCustomization =
        index === 0 ? order.uniqueCodeAfterCustomization : null;

      if (supplierId === NULL_SUPPLIER_ID) {
        return {
          ...order,
          data: products,
          uniqueCodeAfterCustomization,
        };
      }

      return {
        ...order,
        supplierId,
        data: products,
        uniqueCodeAfterCustomization,
      };
    });
  };
}
