import { Order, Supplier } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

export class AddSupplierByNamePlugin extends AbstractPlugin<undefined> {
  transform = (orders: Order[]): Promise<Order[]> => {
    const suppliersByName: Record<string, Supplier> = {};
    this.oms.supplier.suppliers.forEach((supplier) => {
      suppliersByName[supplier.name] = supplier;
    });

    return Promise.resolve(
      orders.map((order) => this.transformOrder(suppliersByName, order))
    );
  };

  transformOrder = (
    suppliersByName: Record<string, Supplier>,
    order: Order
  ): Order => {
    const supplierName = order.supplierName;
    if (supplierName == null) {
      return order;
    }

    const supplier = suppliersByName[supplierName];
    if (supplier == null) {
      return order;
    }

    return {
      ...order,
      supplierId: supplier._id,
    };
  };
}
