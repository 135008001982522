import { evaluate } from 'services/excel-function';
import { Order } from '@sweep/contract';
import { KeyOf } from '@sweep/utils';
import { AbstractPlugin } from '../../interface';

/**
 * @param target - field name
 * @param value - excel function
 * @example
 * [
 *   { target: 'shippingCompany', value: '"CJ"' },
 *   { target: 'option', value: '"카)" & {option}' },
 *   { target: 'quantity', value: '{quantity} + 1' },
 * ]
 */
export type ExcelFunctionPluginConfig = Array<{
  target: KeyOf<Order>;
  value: string;
}>;

export class ExcelFunctionPlugin extends AbstractPlugin<ExcelFunctionPluginConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(orders.map(this.transformOrder));
  };

  transformOrder = (order: Order): Order => {
    let transformedOrder = { ...order };

    this.config.forEach(({ target, value }) => {
      const newValue = this.evaluateExpression(value, transformedOrder);
      if (newValue != null) {
        transformedOrder = {
          ...transformedOrder,
          [target]: newValue,
        };
      }
    });

    return transformedOrder;
  };

  private evaluateExpression = (
    expression: string,
    order: Order
  ): string | null => {
    const result = evaluate(expression, order);
    if (result.success === false) {
      return null;
    }

    const value = result.result;
    if (value == null) {
      return null;
    }

    return value.toString();
  };
}
