import { getFileExtension } from 'services/file/getFileExtension';
import { getFilename } from 'services/file/getFilename';
import { ExcelFile } from '../../interface';
import { isHTMLFile } from '../read/isHTMLFile';
import { isProtected } from '../read/isProtected';
import { readHTMLFile } from '../read/readHTMLFile';
import { readProtectedExcel } from '../read/readProtectedExcel';
import { readUnProtectedExcel } from '../read/readUnprotectedExcel';

/**
 * @deprecated Use readExcelV2 instead
 */
export async function readExcel(file: File): Promise<ExcelFile | null> {
  const name = getFilename(file);
  const extension = getFileExtension(file) ?? '';

  const isHTML = await isHTMLFile(file);
  const isProtectedFile = !isHTML && (await isProtected(file));

  const data = isHTML
    ? await readHTMLFile(file)
    : isProtectedFile
      ? await readProtectedExcel(file)
      : await readUnProtectedExcel(file);

  if (data == null) {
    return null;
  }

  return {
    name,
    extension,
    data: data,
  };
}
