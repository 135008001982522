import { ButtonHTMLAttributes, ReactNode } from 'react';
import { cva } from '@sweep/tailwind';
import { Slot, Slottable } from '../Slot';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'solid' | 'line';
  color?: 'blue' | 'gray' | 'lightGray';
  size?: 'large' | 'medium' | 'xsmall';
  disabled?: boolean;
  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;
  asChild?: boolean;
  children: ReactNode;
}

export function Button({
  variant = 'solid',
  color = 'blue',
  size = 'medium',
  disabled = false,
  className,
  leftAccessory,
  rightAccessory,
  asChild = false,
  children,
  ...rest
}: ButtonProps) {
  const existLeftAccessory = leftAccessory != null;
  const existRightAccessory = rightAccessory != null;
  const Element = asChild ? Slot : 'button';

  return (
    <Element
      className={button({
        variant,
        color,
        size,
        disabled,
        existLeftAccessory,
        existRightAccessory,
        className,
      })}
      disabled={disabled}
      {...rest}
    >
      {leftAccessory}
      <Slottable>{children}</Slottable>
      {rightAccessory}
    </Element>
  );
}

const button = cva(
  'text-bold-s gap-6px flex shrink-0 cursor-pointer items-center justify-center',
  {
    variants: {
      variant: {
        solid: '',
        line: '',
      },
      color: {
        blue: '',
        gray: '',
        lightGray: '',
      },
      size: {
        large: 'h-42px rounded-8px',
        medium: 'h-40px px-20px rounded-8px',
        xsmall: 'h-26px px-8px rounded-6px',
      },
      disabled: {
        true: '',
        fasle: '',
      },
      existLeftAccessory: {
        true: '',
        false: '',
      },
      existRightAccessory: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      // Size
      {
        size: 'large',
        existLeftAccessory: true,
        className: 'pl-16px',
      },
      {
        size: 'large',
        existLeftAccessory: false,
        className: 'pl-20px',
      },
      {
        size: 'large',
        existRightAccessory: true,
        className: 'pr-16px',
      },
      {
        size: 'large',
        existRightAccessory: false,
        className: 'pr-20px',
      },
      {
        size: 'medium',
        existLeftAccessory: true,
        className: 'pl-16px',
      },
      {
        size: 'medium',
        existLeftAccessory: false,
        className: 'pl-20px',
      },
      {
        size: 'medium',
        existRightAccessory: true,
        className: 'pr-16px',
      },
      {
        size: 'medium',
        existRightAccessory: false,
        className: 'pr-20px',
      },
      // Color
      {
        variant: 'solid',
        color: 'blue',
        className: 'text-bold-s bg-blue-500 text-white',
      },
      {
        variant: 'solid',
        color: 'blue',
        size: 'xsmall',
        className: 'text-semibold-xs',
      },
      {
        variant: 'solid',
        color: 'blue',
        disabled: true,
        className: 'bg-gray-300 text-white',
      },
      {
        variant: 'solid',
        color: 'gray',
        className: 'text-bold-s bg-gray-500 text-white',
      },
      {
        variant: 'solid',
        color: 'gray',
        size: 'xsmall',
        className: 'text-semibold-xs',
      },
      {
        variant: 'solid',
        color: 'lightGray',
        className: 'text-semibold-s bg-gray-200 text-gray-600',
      },
      {
        variant: 'solid',
        color: 'lightGray',
        size: 'xsmall',
        className: 'text-semibold-xs',
      },
      {
        variant: 'line',
        color: 'blue',
        className:
          'border-inner-1px-blue-500 text-semibold-s bg-transparent text-blue-500',
      },
      {
        variant: 'line',
        color: 'blue',
        size: 'xsmall',
        className: 'text-semibold-xs',
      },
      {
        variant: 'line',
        color: 'gray',
        className:
          'border-inner-1px-gray-300 text-semibold-s bg-transparent text-gray-500',
      },
      {
        variant: 'line',
        color: 'gray',
        size: 'xsmall',
        className: 'text-semibold-xs',
      },
      {
        variant: 'line',
        color: 'lightGray',
        className:
          'border-inner-1px-gray-300 text-semibold-s bg-transparent text-gray-600',
      },
      {
        variant: 'line',
        color: 'lightGray',
        size: 'xsmall',
        className: 'text-semibold-xs',
      },
    ],
  }
);
