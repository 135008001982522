import { Tooltip } from 'design-system/index';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { MatchedProduct } from 'models/CompositionMatching';
import { useRef } from 'react';
import { cva } from '@sweep/tailwind';
import { useIsOverflow } from '@sweep/utils';

interface MatchedCMConfirmFormProps {
  products: MatchedProduct[];
}

function MatchedCMConfirmFormRow({ products }: MatchedCMConfirmFormProps) {
  const oms = useOMSStore();
  const productNameRef = useRef<HTMLDivElement>(null);
  const unitRef = useRef<HTMLDivElement>(null);

  const isProductNameOverflow = useIsOverflow(productNameRef, 'x');
  const isUnitOverflow = useIsOverflow(unitRef, 'x');

  return (
    <div className="flex w-full min-w-0 flex-col gap-[10px]">
      {products.map((cmProduct, index) => {
        const product = oms.product.getProduct(cmProduct.productId);
        if (product == null) {
          return null;
        }

        const existUnit = cmProduct.unit != null;

        return (
          <div key={index} className="flex w-full justify-between">
            <Tooltip>
              <Tooltip.Trigger>
                <div
                  className={label({ existUnit, type: 'name' })}
                  ref={productNameRef}
                >
                  {product.productName}
                </div>
              </Tooltip.Trigger>
              {isProductNameOverflow && (
                <Tooltip.Content>{product.productName}</Tooltip.Content>
              )}
            </Tooltip>
            {cmProduct.unit != null && (
              <Tooltip>
                <Tooltip.Trigger>
                  <div
                    className={label({ existUnit, type: 'unit' })}
                    ref={unitRef}
                  >
                    {cmProduct.unit}
                  </div>
                </Tooltip.Trigger>
                {isUnitOverflow && (
                  <Tooltip.Content>{cmProduct.unit}</Tooltip.Content>
                )}
              </Tooltip>
            )}
            <div className={label({ existUnit, type: 'quantity' })}>
              x{cmProduct.quantity}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const label = cva(
  'text-medium-s flex h-[40px] items-center overflow-hidden text-clip whitespace-nowrap rounded-[8px] border border-gray-200 px-4 text-gray-600',
  {
    variants: {
      type: {
        name: '',
        unit: '',
        quantity: '',
      },
      existUnit: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        type: 'name',
        existUnit: true,
        className: 'min-w-0 basis-[calc(50%-10px)]',
      },
      {
        type: 'unit',
        existUnit: true,
        className: 'min-w-0 basis-[calc(40%-10px)]',
      },
      {
        type: 'quantity',
        existUnit: true,
        className: 'min-w-0 basis-[10%]',
      },
      {
        type: 'name',
        existUnit: false,
        className: 'w-[calc(90%-10px)]',
      },
      {
        type: 'quantity',
        existUnit: false,
        className: 'w-[10%]',
      },
    ],
  }
);

export default observer(MatchedCMConfirmFormRow);
