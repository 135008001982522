import { isEqualArray } from '@sweep/utils';
import { Matching, Partner } from 'src/models/Partner';
import { ExcelData, ExcelFile, MultiSheetExcelFile } from '../file/interface';
import { formatExcelData } from './formatExcelData';
import { sliceUntilHeader } from './sliceUntilHeader';

interface MatchingResult {
  sheet: ExcelFile;
  partners: Partner[];
}

interface PartnerCandidatesForFormattedSheet {
  formattedSheet: ExcelData;
  sheetIndex: number;
  partners: Partner[];
}

export function matchFileToPartners(
  file: MultiSheetExcelFile,
  partners: Partner[]
): MatchingResult {
  const sheets = file.data;
  const sheetCount = sheets.length;
  let partnerCandidatesForFile: PartnerCandidatesForFormattedSheet[] = [];
  for (let i = 0; i < sheetCount; i++) {
    partnerCandidatesForFile = partnerCandidatesForFile.concat(
      findPartnerCandidatesForSheet(sheetCount, i, sheets[i], partners)
    );
  }
  if (partnerCandidatesForFile.length === 0) {
    return {
      sheet: {
        name: file.name,
        extension: file.extension,
        data: file.data[0],
        sheetIndex: 0,
      },
      partners: [],
    };
  }
  // TODO(@yechan)
  // 현재 서로 다른 시트에 서로 다른 파트너가 매칭되는 경우 배제
  // 같은 시트에서도 행 병합 요건이 각기 다른 파트너가 매칭되는 경우 배제
  const result = partnerCandidatesForFile[0];
  return {
    sheet: {
      name: file.name,
      extension: file.extension,
      data: result.formattedSheet,
      sheetIndex: result.sheetIndex,
    },
    partners: result.partners,
  };
}

function findPartnerCandidatesForSheet(
  sheetCount: number,
  sheetIndex: number,
  sheet: ExcelData,
  partners: Partner[]
) {
  const partnerCandidatesForSheet: PartnerCandidatesForFormattedSheet[] = [];
  for (const partner of partners) {
    const matchings = partner.matchings;
    if (matchings == null) {
      continue;
    }
    for (const matching of matchings) {
      const matchResult = matchSheetToMatching(
        sheetCount,
        sheetIndex,
        sheet,
        matching
      );
      if (!matchResult.isMatched) {
        continue;
      }
      const candidate = partnerCandidatesForSheet.find((candidate) =>
        isSameExcelData(candidate.formattedSheet, matchResult.formattedSheet)
      );
      if (candidate == null) {
        partnerCandidatesForSheet.push({
          formattedSheet: matchResult.formattedSheet,
          sheetIndex,
          partners: [partner],
        });
        continue;
      }
      candidate.partners.push(partner);
    }
  }
  return partnerCandidatesForSheet;
}

function matchSheetToMatching(
  sheetCount: number,
  sheetIndex: number,
  sheet: ExcelData,
  matching: Matching
): { isMatched: false } | { isMatched: true; formattedSheet: ExcelData } {
  const validSheetIndex = matching.validSheetIndex ?? 0;
  const positiveValidSheetIndex =
    validSheetIndex >= 0 ? validSheetIndex : sheetCount + validSheetIndex;
  if (positiveValidSheetIndex === sheetIndex) {
    const header = matching.header;
    // isOldMatching: 행 병합 고려하기 전의 matching인지 여부
    const isOldMatching = matching.headerStartIndex == null;
    const formattedSheet = isOldMatching
      ? sliceUntilHeader(sheet)
      : formatExcelData(sheet, { ...matching });
    if (isEqualArray(header, formattedSheet[0] ?? [])) {
      return { isMatched: true, formattedSheet };
    }
  }
  return { isMatched: false };
}

function isSameExcelData(a: ExcelData, b: ExcelData) {
  return (
    a.length > 0 &&
    a.length === b.length &&
    a.every((row, index) => isEqualArray(row, b[index]))
  );
}
