import { cva } from '@sweep/tailwind';
import {
  IconOrderAll,
  IconOrderCancel,
  IconOrderDelivery,
  IconOrderEtc,
  IconOrderPaid,
  IconOrderReady,
} from 'src/icons';
import { OrderStatusType } from '../../interface';

interface OrderStatusRadioProps {
  orderStatusType: OrderStatusType;
  count: number;
  selected: boolean;
  onChange: () => void;
}

const LABEL_RECORD = {
  Total: '전체 주문',
  PaymentComplete: '결제 완료',
  ProductPreparing: '상품 준비 중',
  InDelivery: '배송 중',
  CancelRequest: '취소 요청',
  Etc: '기타',
} as const;

const COLOR_RECORD = {
  Total: 'blue',
  PaymentComplete: 'deep-blue',
  ProductPreparing: 'deep-blue',
  InDelivery: 'deep-blue',
  CancelRequest: 'red',
  Etc: 'gray',
} as const;

const ICON_RECORD = {
  Total: IconOrderAll,
  PaymentComplete: IconOrderPaid,
  ProductPreparing: IconOrderReady,
  InDelivery: IconOrderDelivery,
  CancelRequest: IconOrderCancel,
  Etc: IconOrderEtc,
};

function OrderStatusRadio({
  orderStatusType,
  count,
  selected,
  onChange,
}: OrderStatusRadioProps) {
  const label = LABEL_RECORD[orderStatusType];
  const color = COLOR_RECORD[orderStatusType];
  const Icon = ICON_RECORD[orderStatusType];
  const countColor = getCountColor(orderStatusType, count);

  return (
    <button onClick={onChange} className={card({ selected, color })}>
      <div className={iconContainer({ selected, color })}>
        <Icon className="text-white" />
      </div>
      <div className="flex flex-col items-start">
        <span className="text-medium-s text-ellipsis whitespace-nowrap text-gray-700">
          {label}
        </span>
        <div>
          <span className={countClass({ countColor })}>{count}</span>
          <span className="text-medium-s ml-px align-text-bottom">건</span>
        </div>
      </div>
    </button>
  );
}

function getCountColor(
  orderStatusType: string,
  count: number
): 'default' | 'red' {
  if (orderStatusType === 'CancelRequest' && count > 0) {
    return 'red';
  }
  return 'default';
}

const card = cva(
  'flex flex-1 items-center gap-[18px] rounded-[8px] border p-[20px]',
  {
    variants: {
      selected: {
        true: '',
        false: 'border-gray-300 bg-white hover:bg-gray-100',
      },
      color: {
        blue: '',
        'deep-blue': '',
        red: '',
        gray: '',
      },
    },
    compoundVariants: [
      {
        selected: true,
        color: 'blue',
        className: 'border-blue-500 bg-blue-50',
      },
      {
        selected: true,
        color: 'deep-blue',
        className: 'border-blue-500 bg-blue-50',
      },
      {
        selected: true,
        color: 'red',
        className: 'border-red-500 bg-red-50',
      },
      {
        selected: true,
        color: 'gray',
        className: 'border-gray-500 bg-gray-100',
      },
    ],
  }
);

const iconContainer = cva(
  'flex size-[34px] items-center justify-center rounded-[8px]',
  {
    variants: {
      selected: {
        true: '',
        false: 'bg-gray-300',
      },
      color: {
        blue: '',
        'deep-blue': '',
        red: '',
        gray: '',
      },
    },
    compoundVariants: [
      {
        selected: true,
        color: 'blue',
        className: 'bg-blue-500',
      },
      {
        selected: true,
        color: 'deep-blue',
        className: 'bg-blue-600',
      },
      {
        selected: true,
        color: 'red',
        className: 'bg-red-500',
      },
      {
        selected: true,
        color: 'gray',
        className: 'bg-gray-600',
      },
    ],
  }
);

const countClass = cva('text-semibold-2xl', {
  variants: {
    countColor: {
      default: 'text-gray-700',
      red: 'text-red-500',
    },
  },
});

export default OrderStatusRadio;
