import { useCallback, useState } from 'react';
import { Order } from '@sweep/contract';
import { filterOrders as _filterOrders } from '../services/filterOrders';
import { sortOrders } from '../services/sortOrders';
import { TableFilterStore } from '../TableFilterStore';

export function useTableFilter() {
  const [store] = useState(() => new TableFilterStore());

  const filterOrders = useCallback(
    <T extends Order, U extends T | T[]>(orders: U[]): U[] => {
      const removedFieldsOrders = _filterOrders(orders, store.removedFields);
      const sortedOrders = sortOrders(removedFieldsOrders, store.sort);

      return sortedOrders;
    },
    [store.removedFields, store.sort]
  );

  return { store, filterOrders };
}
