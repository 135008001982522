import { ReactNode } from 'react';
import { TooltipOptions } from './interface';
import TooltipContent from './TooltipContent';
import { TooltipContextProvider } from './TooltipContext';
import TooltipTrigger from './TooltipTrigger';
import { useTooltip } from './useTooltip';

export function Tooltip({
  children,
  ...options
}: { children: ReactNode } & TooltipOptions) {
  const tooltip = useTooltip(options);

  return (
    <TooltipContextProvider value={tooltip}>{children}</TooltipContextProvider>
  );
}

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;
