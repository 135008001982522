import { useHover } from 'react-aria';
import { cva } from '@sweep/tailwind';
import { Checkbox, CheckboxProps } from './Checkbox';

interface ExpandedCheckboxProps extends CheckboxProps {}

export function ExpandedCheckbox({
  checked,
  onCheckedChange,
  className,
  ...rest
}: ExpandedCheckboxProps) {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onCheckedChange?.(!checked);
  };

  const { isHovered, hoverProps } = useHover({});

  return (
    <div
      className={container({ className })}
      onClick={handleClick}
      {...hoverProps}
    >
      <Checkbox
        checked={checked}
        onCheckedChange={onCheckedChange}
        isHovered={isHovered}
        {...rest}
      />
    </div>
  );
}

const container = cva(
  'size-36px flex shrink-0 cursor-pointer items-center justify-center'
);
