import { Button } from 'design-system/components/Button';
import { observer } from 'mobx-react-lite';
import { IconControlDownload } from 'src/icons';
import { ProductMatching } from '../../interface';
import CMHeader from '../common/CMHeader';
import CMOrderLabel from '../common/CMOrderLabel';
import MatchedCMConfirmFormRow from './MatchedCMConfirmFormRow';

interface PreDivideMatchedCMConfirmFormProps {
  matchings: ProductMatching[];
  onEdit: () => void;
  onSubmit: () => void;
}

function PreDivideMatchedConfirmCM({
  matchings,
  onEdit,
  onSubmit,
}: PreDivideMatchedCMConfirmFormProps) {
  return (
    <div className="flex flex-col">
      <CMHeader />
      {matchings.map(({ cmOrder, products }, index) => (
        <div key={index} className="flex border-b border-gray-200 py-4">
          <CMOrderLabel cmOrder={cmOrder} />
          <div className="w-[42px] shrink-0" />
          <MatchedCMConfirmFormRow products={products} />
        </div>
      ))}
      <div className="m-auto flex gap-[12px] pt-5">
        <Button color="gray" onClick={() => onEdit()}>
          수정하기
        </Button>
        <Button
          onClick={() => onSubmit()}
          rightAccessory={<IconControlDownload className="text-blue-200" />}
        >
          <div className="gap-8px flex items-center">
            <p className="text-bold-s text-white">완료하기</p>
            <div className="h-15px w-[1.5px] bg-blue-200" />
            <p className="text-semibold-s text-blue-200">발주서</p>
          </div>
        </Button>
      </div>
    </div>
  );
}

export default observer(PreDivideMatchedConfirmCM);
