import { useEffect, useState } from 'react';

const KEY_ENTER = 13;
const KEY_UP = 38;
const KEY_DOWN = 40;

const Autocomplete = ({ productInfos, productIds, setProductIds }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

  useEffect(() => {
    const filtered = productInfos
      .filter(
        (info) =>
          (!inputValue ||
            info.productName
              .toLowerCase()
              .includes(inputValue.toLowerCase())) &&
          !productIds.includes(info._id)
      )
      .map((info) => ({ productId: info._id, productName: info.productName }));

    setFilteredSuggestions(filtered);

    setActiveSuggestionIndex(0);
  }, [inputValue, productInfos, productIds]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setShowSuggestions(true);
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case KEY_ENTER:
        e.preventDefault();
        const selectedProduct = filteredSuggestions[activeSuggestionIndex];
        const productInfo = productInfos.find(
          (info) => info.productName === selectedProduct.productName
        );
        if (productInfo) {
          setProductIds((prev) => [...prev, productInfo._id]);
        }
        setShowSuggestions(false);
        setInputValue('');
        break;
      case KEY_UP:
        e.preventDefault();
        if (activeSuggestionIndex > 0) {
          setActiveSuggestionIndex(activeSuggestionIndex - 1);
        }
        break;
      case KEY_DOWN:
        e.preventDefault();
        if (activeSuggestionIndex < filteredSuggestions.length - 1) {
          setActiveSuggestionIndex(activeSuggestionIndex + 1);
        }
        break;
      default:
    }
  };

  const handleSuggestionClick = (productId) => {
    setInputValue('');
    setShowSuggestions(false);
    // 선택한 productName을 가진 productInfos의 객체 찾기
    const productInfo = productInfos.find((info) => info._id === productId);
    if (productInfo) {
      // productIds에 선택된 객체를 추가
      setProductIds((prevList) => [...prevList, productInfo._id]);
    }
  };

  const handleInputBlur = () => {
    // 포커스가 벗어나면 제안 목록을 숨기기
    // setTimeout을 사용하여 클릭 이벤트가 제안 선택보다 먼저 발생하지 않도록 함
    setTimeout(() => {
      setShowSuggestions(false);
    }, 100);
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        onFocus={(e) => {
          e.target.select();
          setShowSuggestions(true);
        }}
        className="mt-2 w-full rounded-md border border-gray-200 px-3 py-2 shadow-sm focus:border-blue-400 focus:outline-none focus:ring-blue-400"
        placeholder="상품을 검색해보세요"
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="absolute inset-x-0 top-full z-10 mt-1 rounded-lg border bg-white">
          {filteredSuggestions.map(({ productId, productName }, index) => (
            <li
              key={productId}
              className={`cursor-pointer rounded-lg p-2 hover:bg-gray-100 ${
                index === activeSuggestionIndex ? 'bg-gray-100' : ''
              }`}
              onMouseDown={() => handleSuggestionClick(productId)}
            >
              {productName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
