import { isValid } from './utils';

const sortSummaryByProductId = (summary) => {
  return Object.values(summary).sort((a, b) => {
    // partnerId가 없는 경우를 맨 뒤로 정렬
    const idA = a.partnerId || Infinity;
    const idB = b.partnerId || Infinity;

    return idA < idB ? -1 : idA > idB ? 1 : 0;
  });
};

export const createDailySettlementSummary = (orders, settings = {}) => {
  const { userType = 'retailer' } = settings;

  let summary = {};
  let orderSummary = {};
  orders?.forEach((order) => {
    let data = [];
    if (isValid(order?.data)) {
      if (userType === 'retailer') {
        const productName = order.option ? order.option : order.productName;
        const unit = '';
        const quantity = order.quantity ?? 1;
        const partnerId = order.partnerId ?? '';
        const supplierId = order.supplierId ?? '';

        data = order.data.map((item) => ({
          productId: item.productId ?? '',
          unit: item.unit ?? '',
          quantity: item.quantity ?? 1,
        }));

        const key = `${productName} ${unit} ${partnerId}`;
        if (!orderSummary[key]) {
          orderSummary[key] = {
            productName,
            unit,
            quantity: 0,
            partnerId,
            supplierId,
            data: data,
          };
        }

        orderSummary[key].quantity += quantity;
      } else {
        order.data.forEach((data) => {
          const productName = data.productName;
          const unit = data.unit;
          const quantity = (data.quantity ?? 1) * (order.quantity ?? 1);
          const partnerId = isValid(order?.partnerId) ? order.partnerId : '';
          const newData = [
            {
              productId: data.productId ?? '',
              unit: data.unit ?? '',
              quantity: data.quantity ?? 1,
            },
          ];

          const key = `${productName} ${unit} ${partnerId}`;
          if (!orderSummary[key]) {
            orderSummary[key] = {
              productName,
              unit,
              quantity: 0,
              partnerId,
              data: newData,
            };
          }

          orderSummary[key].quantity += quantity;
        });
      }
    } else {
      const productName = order.productName ?? '';
      const unit = order.option ?? '';
      const quantity = order.quantity ?? 1;
      // data 해석이 안 된 경우 partnerId가 없음

      const key = `${productName} ${unit}`;
      if (!orderSummary[key]) {
        orderSummary[key] = {
          productName,
          unit,
          quantity: 0,
          data: [
            {
              productName,
              unit,
              quantity: 0,
            },
          ],
        };
      }

      orderSummary[key].quantity += quantity;
    }
  });

  summary = {
    ...orderSummary,
    // ...shippingSummary,
  };

  //   const totalSum = result.reduce((acc, item) => acc + item.total, 0);

  return sortSummaryByProductId(summary);
};
