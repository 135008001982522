import { IconCheckSmall } from 'src/icons';

interface TableSelectedCountProps {
  count: number;
}

function TableSelectedCount({ count }: TableSelectedCountProps) {
  return (
    <div className="gap-6px flex items-center">
      <IconCheckSmall className="text-gray-400" />
      <p className="text-semibold-s text-blue-500">{count}</p>
      <p className="text-medium-s text-gray-500">선택됨</p>
    </div>
  );
}

export default TableSelectedCount;
