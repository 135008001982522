import { useMemo } from 'react';
import { cva } from '@sweep/tailwind';
import { ExpandedCheckbox } from '../../Checkbox';
import { Slot } from '../../Slot';
import DefaultTableCell from '../core/DefaultTableCell';
import { CellContext, ColumnDef } from '../core/interface';
import { Table, TableProps } from '../core/Table';

interface SelectableTableProps<T> extends Omit<TableProps<T>, 'items'> {
  items: (T | T[])[];
  selectedItems: T[];
  onSelect: (items: T[]) => void;
  getKey: (data: T) => string;
}

export function SelectableTable<T>({
  items,
  columns: givenColumns,
  getKey: gievnGetKey,
  selectedItems,
  onSelect,
  ...rest
}: SelectableTableProps<T>) {
  const falttenedItems = useMemo(
    () => items.flatMap((item) => (Array.isArray(item) ? item : [item])),
    [items]
  );
  const itemByKey = useMemo(() => {
    return new Map(
      [...falttenedItems, ...selectedItems].map(
        (item) => [gievnGetKey(item), item] as const
      )
    );
  }, [falttenedItems, gievnGetKey, selectedItems]);

  const selectedKeySet = new Set(selectedItems.map(gievnGetKey));
  const isAllSelected =
    falttenedItems.length > 0 &&
    falttenedItems.every((row) => selectedKeySet.has(gievnGetKey(row)));

  const isSelectedItem = (row: T) => selectedKeySet.has(gievnGetKey(row));
  const isSelectedArray = (row: T[]) =>
    row.every((item) => isSelectedItem(item));
  const isSelected = (row: T | T[]) =>
    Array.isArray(row) ? isSelectedArray(row) : isSelectedItem(row);

  const handleAllSelectedChange = (checked: boolean) => {
    const keys = falttenedItems.map(gievnGetKey);
    const keySet = new Set(keys);
    if (checked) {
      const newSelectedKeySet = new Set([...selectedKeySet, ...keys]);
      onSelect(Array.from(newSelectedKeySet).map((key) => itemByKey.get(key)!));

      return;
    }

    const newSelectedItems = selectedItems.filter(
      (item) => !keySet.has(gievnGetKey(item))
    );
    onSelect(newSelectedItems);
  };

  const handleItemSelectedChange = (row: T) => (checked: boolean) => {
    const key = gievnGetKey(row);
    if (checked) {
      const newSelectedItems = [...selectedItems, row];
      onSelect(newSelectedItems);
      return;
    }

    const newSelectedItems = selectedItems.filter(
      (item) => gievnGetKey(item) !== key
    );
    onSelect(newSelectedItems);
  };

  const handleArraySelectedChange = (row: T[]) => (checked: boolean) => {
    const keys = row.map(gievnGetKey);
    const keySet = new Set(keys);
    if (checked) {
      const newSelectedKeySet = new Set([...selectedKeySet, ...keys]);
      onSelect(Array.from(newSelectedKeySet).map((key) => itemByKey.get(key)!));

      return;
    }

    const newSelectedItems = selectedItems.filter(
      (item) => !keySet.has(gievnGetKey(item))
    );
    onSelect(newSelectedItems);
  };

  const handleSelectedChange = (row: T | T[]) => (checked: boolean) =>
    Array.isArray(row)
      ? handleArraySelectedChange(row)(checked)
      : handleItemSelectedChange(row)(checked);

  const columns: ColumnDef<T | T[]>[] = [
    {
      header: (context) => (
        <Table.HeaderCell
          className={checkboxHeaderClass({ selected: isAllSelected })}
          context={context}
        >
          <ExpandedCheckbox
            className="z-[1]"
            checked={isAllSelected}
            onCheckedChange={handleAllSelectedChange}
          />
        </Table.HeaderCell>
      ),
      cell: (context) => (
        <Table.Cell
          className={checkboxCellClass({ selected: isSelected(context.row) })}
          context={context}
        >
          <ExpandedCheckbox
            checked={isSelected(context.row)}
            onCheckedChange={handleSelectedChange(context.row)}
          />
        </Table.Cell>
      ),
    },
    ...givenColumns.map<ColumnDef<T | T[]>>((column) => ({
      header: column.header,
      cell: (context) => {
        const { row } = context;
        const rows = Array.isArray(row) ? row : [row];

        const component = (context: CellContext<T>) => {
          if (column.cell != null) {
            const cell = column.cell(context);
            if (cell != null) {
              return cell;
            }
          }

          return (
            <DefaultTableCell context={context}>
              {column.accessorFn?.(context.row)}
            </DefaultTableCell>
          );
        };

        return (
          <div className="flex flex-col">
            {rows.map((item, index) => (
              <Slot
                key={index}
                className={cellClass({ selected: isSelected(row) })}
              >
                {component({
                  ...context,
                  row: item,
                  lastRow: context.lastRow && index === rows.length - 1,
                })}
              </Slot>
            ))}
          </div>
        );
      },
    })),
  ];

  const getKey = (item: T | T[]) => {
    if (Array.isArray(item)) {
      const keys = item.map(gievnGetKey);
      return keys.join(',');
    }

    return gievnGetKey(item);
  };

  return <Table items={items} columns={columns} getKey={getKey} {...rest} />;
}

const checkboxHeaderClass = cva('sticky left-0 items-start bg-gray-200 px-0', {
  variants: {
    selected: {
      true: 'bg-gray-200',
    },
  },
});

const checkboxCellClass = cva('sticky left-0 h-auto items-start px-0', {
  variants: {
    selected: {
      true: 'bg-gray-100',
      false: '',
    },
  },
});

const cellClass = cva('', {
  variants: {
    selected: {
      true: 'bg-gray-100',
      false: '',
    },
  },
});

SelectableTable.Cell = DefaultTableCell;
SelectableTable.HeaderCell = Table.HeaderCell;
