import { observer } from 'mobx-react-lite';
import SearchSelect, {
  SearchSelectOption,
} from 'src/design-system/components/SearchSelect/SearchSelect';
import { useOMSStore } from 'src/hooks/useOMSStore';

interface SupplierInputProps {
  value?: string | null;
  onChange: (value: string) => void;
  className?: string;
}

function SupplierInput({ value, onChange, className }: SupplierInputProps) {
  const oms = useOMSStore();
  const suppliers = oms.supplier.suppliers;

  const options: SearchSelectOption[] = [
    {
      value: '',
      label: '없음',
      className: 'text-gray-400',
    },
    ...suppliers.map((supplier) => ({
      value: supplier._id,
      label: supplier.name,
    })),
  ];

  return (
    <SearchSelect
      value={value ?? ''}
      onChange={onChange}
      className={className}
      options={options}
      placeholder="공급사 검색"
    />
  );
}

export default observer(SupplierInput);
