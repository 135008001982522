import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { NormalizedOrder } from '@sweep/contract';
import {
  TableFilterHeaderCell,
  TableFilterStore,
} from 'src/components/TableFilter';
import {
  CellContext,
  ColumnDef,
  HeaderCellContext,
  SelectableTable,
} from 'src/design-system/components/Table';
import { useOMSStore } from 'src/hooks/useOMSStore';
import OrderPendingTableCell from './OrderPendingTableCell';

interface OrderPendingTableProps {
  store: TableFilterStore;
  toatlOrders: NormalizedOrder[];
  orders: NormalizedOrder[];
  selectedOrders: NormalizedOrder[];
  onSelect: (orders: NormalizedOrder[]) => void;
}

function OrderPendingTable({
  store,
  toatlOrders,
  orders,
  selectedOrders,
  onSelect,
}: OrderPendingTableProps) {
  const oms = useOMSStore();
  const headerKeys = oms.user.excelHeaderKeys;
  const columnTranslation = oms.user.excelColumnMapping;

  const columns = useMemo(
    () =>
      headerKeys.map<ColumnDef<NormalizedOrder>>((key) => ({
        header: (context: HeaderCellContext) => (
          <TableFilterHeaderCell
            orders={toatlOrders}
            store={store}
            headerKey={key}
            context={context}
          >
            {columnTranslation[key]}
          </TableFilterHeaderCell>
        ),
        cell: (context: CellContext<NormalizedOrder>) => (
          <OrderPendingTableCell orderKey={key} context={context} />
        ),
      })),
    [columnTranslation, headerKeys, store, toatlOrders]
  );

  return (
    <SelectableTable
      items={orders}
      columns={columns}
      getKey={(order) => order.uniqueCodeAfterCustomization}
      selectedItems={selectedOrders}
      onSelect={onSelect}
      className="max-h-[calc(100vh-300px)]"
    />
  );
}

export default observer(OrderPendingTable);
