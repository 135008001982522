import { Order } from '@sweep/contract';

export function filterOrders<T extends Order, U extends T | T[]>(
  orders: U[],
  removedFields: Record<string, string[] | undefined>
): U[] {
  return orders.filter((order) =>
    Array.isArray(order)
      ? order.some((o) => filterOrder(o, removedFields))
      : filterOrder(order, removedFields)
  );
}

function filterOrder<T extends Order>(
  order: T,
  removedFilters: Record<string, string[] | undefined>
) {
  return Object.keys(removedFilters).every((key) => {
    const removedFieldValues = removedFilters[key];
    if (removedFieldValues == null) {
      return true;
    }

    const value = order[key]?.toString() ?? '';
    return removedFieldValues.every((field) => value !== field);
  });
}
