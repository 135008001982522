import { DispatchedOrder } from '@sweep/contract';

export function filterShoppingMall(
  orders: DispatchedOrder[],
  shoppingMalls: string[]
) {
  if (shoppingMalls.length === 0) {
    return orders;
  }

  const shoppingMallSet = new Set(shoppingMalls);

  return orders.filter(
    (order) =>
      order.shoppingMall != null && shoppingMallSet.has(order.shoppingMall)
  );
}
