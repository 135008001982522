import { action, makeObservable, observable, runInAction } from 'mobx';
import { NormalizedOrder, Order } from '@sweep/contract';
import { getNormalizedOrders } from 'src/network/order/order';
import { OMSStore } from 'src/stores/OMSStore';
import { PluginExecutionService } from 'src/stores/plugin/PluginExecutionService';

export class NormalizeOrderStore {
  normalizedOrders: NormalizedOrder[] = [];

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      normalizedOrders: observable,

      setNormalizedOrders: action.bound,
      init: action.bound,
      loadNormalizedOrders: action.bound,
      normalizeOrders: action.bound,
      removeOrderByFilename: action.bound,
      removeByUniqueCodes: action.bound,
    });
  }

  /**
   * @deprecated OrderStore를 마이그레이션 하기 위한 임시 메서드
   */
  setNormalizedOrders(orders: NormalizedOrder[]) {
    this.normalizedOrders = orders;
  }

  async init() {
    await this.loadNormalizedOrders();
  }

  async loadNormalizedOrders() {
    const normalizedOrders = await getNormalizedOrders();
    runInAction(() => {
      this.normalizedOrders = normalizedOrders;
    });
  }

  normalizeOrders = async (orders: Order[]): Promise<Order[]> => {
    const pluginExecutionService = new PluginExecutionService(
      this.oms,
      this.oms.user.plugins,
      'normalize'
    );

    const transformedOrders = await pluginExecutionService.execute(orders);

    return transformedOrders;
  };

  removeOrderByFilename(filename: string) {
    this.normalizedOrders = this.normalizedOrders.filter(
      (order) => order.originFile !== filename
    );
  }

  removeByUniqueCodes(
    uniqueCodes: string[],
    standard: 'uniqueCode' | 'uniqueCodeAfterCustomization'
  ) {
    this.normalizedOrders = this.normalizedOrders.filter((order) => {
      const standardValue = order[standard];
      if (standardValue == null) {
        return true;
      }

      return !uniqueCodes.includes(standardValue);
    });
  }
}
