import { DispatchedOrder } from './DispatchedOrder';
import { UploadedOrder } from './UploadedOrder';

export type OrderProcess = 'pending' | 'shipping';

export type Order = DispatchedOrder | UploadedOrder;

export type PluginOrder<P extends Record<string, unknown>> = Order & {
  plugin?: P;
};

export type NormalizedOrder = Order & {
  uniqueCodeAfterCustomization: string;
  process?: OrderProcess;
};

export function isDispatchedOrder(order: Order): order is DispatchedOrder {
  return order.orderStatus != null;
}

export function isNormalizedOrder(order: Order): order is NormalizedOrder {
  return order.uniqueCodeAfterCustomization != null;
}
