import { useEffect, useRef } from 'react';
import { Slot } from '../../Slot';
import DefaultTableHeaderCell from './DefaultTableHeaderCell';
import { ColumnDef, HeaderCellContext } from './interface';
import { useTableContext } from './TableContext';

interface TableHeaderCellRenderProps<T> {
  header: ColumnDef<T>['header'];
  context: HeaderCellContext;
  index: number;
}

const BORDER_WIDTH = 1;

function TableHeaderCellRender<T>({
  header,
  context,
  index,
}: TableHeaderCellRenderProps<T>) {
  const ref = useRef<HTMLTableCellElement | null>(null);
  const { columnWidths, onWidthChange } = useTableContext();
  const columnWidth = columnWidths[index];
  const width = columnWidth != null ? `${columnWidth}px` : undefined;
  const style: React.CSSProperties = {
    width,
    maxWidth: width,
  };

  useEffect(() => {
    if (columnWidth == null && ref.current) {
      onWidthChange(index, ref.current.clientWidth + BORDER_WIDTH);
    }
  }, [index, onWidthChange, columnWidth]);

  const headerComponent = header(context);

  if (typeof headerComponent === 'string') {
    return (
      <DefaultTableHeaderCell ref={ref} context={context} style={style}>
        {headerComponent}
      </DefaultTableHeaderCell>
    );
  }

  return (
    <Slot key={index} ref={ref} style={style}>
      {headerComponent}
    </Slot>
  );
}

export default TableHeaderCellRender;
