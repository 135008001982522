import { DispatchedOrder } from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import { amplitude } from 'src/third-parties/amplitude';
import api from '../api';

export type ConfirmAndUpdateOrderResponse =
  | { success: true; result: ConfirmedOrder[] }
  | { success: false; result: { err: string } };

export type ConfirmedOrder =
  | ({ success: true } & DispatchedOrder)
  | ({ success: false; failReason: string } & DispatchedOrder);

export async function confirmAndUpdateOrder(orders: DispatchedOrder[]) {
  const url = '/confirm-order/confirm-update-orders';
  const response = await tryCatch(async () => {
    const res = await api.post<ConfirmAndUpdateOrderResponse>(url, {
      infoArray: orders,
    });

    return res.data;
  });

  if (
    response?.success === true &&
    response.result.some((order) => order.success === true)
  ) {
    amplitude.track('Confirm Order (Server)', { 'Order Count': orders.length });
  }

  return response;
}
