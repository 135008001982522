import { NormalizedOrder } from '@sweep/contract';
import { OrderProcessType } from '../OrderProcessScreen';
import { isPendingOrder } from './isPendingOrder';
import { isShippingCompleteOrder } from './isShippingCompleteOrder';
import { isShippingProcessOrder } from './isShippingProcessOrder';

export function getCountByProcess(
  orders: NormalizedOrder[]
): Record<OrderProcessType, number> {
  const counts: Record<OrderProcessType, number> = {
    total: orders.length,
    pending: 0,
    'shipping-process': 0,
    'shipping-complete': 0,
  };

  orders.forEach((order) => {
    if (isPendingOrder(order)) {
      counts['pending'] += 1;
      return;
    }

    if (isShippingProcessOrder(order)) {
      counts['shipping-process'] += 1;
      return;
    }

    if (isShippingCompleteOrder(order)) {
      counts['shipping-complete'] += 1;
      return;
    }
  });

  return counts;
}
