import { useCallback } from 'react';
import { TableFilterStore } from '../TableFilterStore';

export function useSort(store: TableFilterStore, headerKey: string) {
  const { key: sortKey, type: sortType } = store.sort;
  const isASC = sortKey === headerKey && sortType === 'ASC';
  const isDESC = sortKey === headerKey && sortType === 'DESC';

  const handleASCClick = useCallback(() => {
    const { key, type } = store.sort;
    if (key === headerKey && type === 'ASC') {
      store.setSort(null, null);
      return;
    }

    store.setSort(headerKey, 'ASC');
  }, [headerKey, store]);

  const handleDESCClick = useCallback(() => {
    const { key, type } = store.sort;
    if (key === headerKey && type === 'DESC') {
      store.setSort(null, null);
      return;
    }

    store.setSort(headerKey, 'DESC');
  }, [headerKey, store]);

  return {
    isASC,
    isDESC,
    onASCClick: handleASCClick,
    onDESCClick: handleDESCClick,
  };
}
