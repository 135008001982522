import { ExcelData } from '../file/interface';
import { flattenMultiHeader } from './flattenMultiHeader';
import { truncateAfterEmptyRow } from './truncateAfterEmptyRows';

interface OptionsProps {
  headerStartIndex?: number;
  headerEndIndex?: number;
  orderStartIndex?: number;
  isTruncateEnabled?: boolean;
}

export function formatExcelData(data: ExcelData, options: OptionsProps) {
  const headerStartIndex = options.headerStartIndex ?? 0;
  const headerEndIndex = options.headerEndIndex ?? headerStartIndex;
  const orderStartIndex = options.orderStartIndex ?? headerEndIndex + 1;
  const isTruncateEnabled = options.isTruncateEnabled ?? false;

  const header = flattenMultiHeader(
    data.slice(headerStartIndex, headerEndIndex + 1)
  );
  const orders = data.slice(orderStartIndex);
  const result = [header, ...orders];
  if (isTruncateEnabled) {
    return truncateAfterEmptyRow(result);
  }
  return result;
}
