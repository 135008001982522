import { HTMLAttributes, ReactNode } from 'react';
import { cva } from '@sweep/tailwind';

export interface DialogTitleProps extends HTMLAttributes<HTMLParagraphElement> {
  children: ReactNode;
}

export function DialogTitle({
  className,
  children,
  ...rest
}: DialogTitleProps) {
  return (
    <p className={title({ className })} {...rest}>
      {children}
    </p>
  );
}

const title = cva('text-bold-xl text-gay-700 px-[8px]');
