import { observer } from 'mobx-react-lite';
import { SwitchCase } from '@sweep/utils';
import { Button } from 'src/design-system/components/Button';
import { IconAddAll, IconAddExcel, IconConfirmOrders } from 'src/icons';
import { OrderDispatchScreenStore } from '../store/OrderDispatchScreenStore';

interface OrderDispatchControlProps {
  store: OrderDispatchScreenStore;
  onShippingFilesChange: (files: FileList) => Promise<void>;
}

function OrderDispatchControl({
  store,
  onShippingFilesChange,
}: OrderDispatchControlProps) {
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files == null) {
      return;
    }

    await onShippingFilesChange(files);

    event.target.value = '';
  };

  return (
    <SwitchCase
      value={store.orderStatus}
      caseBy={{
        PaymentComplete: [
          <Button
            key={1}
            leftAccessory={<IconAddExcel />}
            onClick={store.mergedSelectedDispatchedOrders}
          >
            통합 엑셀에 추가
          </Button>,
          <Button
            key={2}
            leftAccessory={<IconConfirmOrders />}
            onClick={store.confirmSelectedOrders}
          >
            주문 확인하기
          </Button>,
        ],
        ProductPreparing: [
          <Button
            key="combine"
            leftAccessory={<IconAddExcel />}
            onClick={store.mergedSelectedDispatchedOrders}
          >
            통합 엑셀에 추가
          </Button>,
          <Button
            key="upload"
            variant="line"
            leftAccessory={<IconAddAll />}
            asChild
          >
            <label>
              운송장 일괄 등록
              <input
                className="hidden"
                type="file"
                accept=".xlsx, .xls, .csv"
                multiple
                onChange={handleFileChange}
              />
            </label>
          </Button>,
        ],
        CancelRequest: [
          <Button key="confirm" onClick={store.acceptCanceledSelectedOrders}>
            취소 승인
          </Button>,
          <Button
            key="reject"
            color="gray"
            onClick={store.openRejectCanceledSelectedOrdersDialog}
          >
            취소 거부
          </Button>,
        ],
      }}
    />
  );
}

export default observer(OrderDispatchControl);
