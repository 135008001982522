import {
  NormalizedOrder,
  Order,
  REPLACE_ALL_ORDERS_URL,
  REPLACE_NORMALIZED_ORDERS_URL,
  REPLACE_ORDERS_URL,
  ReplaceAllOrdersBody,
  ReplaceNormalizedOrderSBody,
  ReplaceOrdersBody,
  UPSERT_NORMALIZED_ORDERS_URL,
  UpsertNormalizedOrdersBody,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import api from '../api';

export async function getMergedOrders() {
  const url = '/order';

  const response = await tryCatch(() => api.get<Order[]>(url));

  return response?.data;
}

interface OrderDataResponse {
  message: string;
}

export async function upsertOrders(orders: Order[]) {
  const url = '/order/upsert-orderData';
  const response = await api.post<OrderDataResponse>(url, {
    orderData: orders,
  });

  return response.data;
}

interface GetNormalizedOrdersResponse {
  data: NormalizedOrder[];
}

export async function getNormalizedOrders() {
  const url = '/order/fetch-customizedOrderByDate';

  const response = await api.get<GetNormalizedOrdersResponse>(url);

  return response.data.data;
}

export async function updateNormalizedOrders(orders: NormalizedOrder[]) {
  const url = '/order/custom-orders-update';
  const response = await tryCatch(() => api.post(url, { orders }));

  return response?.data;
}

export async function replaceOrders(orders: Order[]) {
  const body: ReplaceOrdersBody = { orders };
  const response = await tryCatch(() => api.put(REPLACE_ORDERS_URL, body));

  return response?.data;
}

export async function replaceNormalizedOrders(orders: NormalizedOrder[]) {
  const body: ReplaceNormalizedOrderSBody = { orders };
  const response = await tryCatch(() =>
    api.put(REPLACE_NORMALIZED_ORDERS_URL, body)
  );

  return response?.data;
}

export async function replaceAllOrders(
  orders: Order[],
  normalizedOrders: NormalizedOrder[]
) {
  const body: ReplaceAllOrdersBody = { orders, normalizedOrders };
  const response = await tryCatch(() => api.put(REPLACE_ALL_ORDERS_URL, body));

  return response?.data;
}

export async function upsertNormalizedOrders(orders: NormalizedOrder[]) {
  const body: UpsertNormalizedOrdersBody = { orders };
  const response = await tryCatch(() =>
    api.put(UPSERT_NORMALIZED_ORDERS_URL, body)
  );

  return response?.data;
}
