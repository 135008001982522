import { observer } from 'mobx-react-lite';
import { createRef, useRef } from 'react';
import { isNotNil } from '@sweep/utils';
import { Unit } from 'src/models/Product';
import { ProductFormStore } from '../../ProductFormStore';
import OptionInput from './OptionInput';
import PlusButton from './PlusButton';

export interface OptionsWithSupplier {
  options: Unit[];
  supplierId?: string | null;
  useSupplierByOption: boolean;
}

interface OptionInputSectionProps {
  store: ProductFormStore;
}

function OptionInputSection({ store }: OptionInputSectionProps) {
  const inputRefs = useRef(
    store.options.map(() => createRef<HTMLInputElement>())
  );

  const handleOptionChange = (index: number) => (option: Unit | null) => {
    if (option == null) {
      inputRefs.current = [
        ...inputRefs.current.slice(0, index),
        ...inputRefs.current.slice(index + 1),
      ];
    }

    const newOptions = [
      ...store.options.slice(0, index),
      option == null ? null : { ...option, unit: option.unit.trim() },
      ...store.options.slice(index + 1),
    ].filter(isNotNil);
    store.setOptions(newOptions);
  };

  const handleAddOption = () => {
    inputRefs.current.push(createRef<HTMLInputElement>());
    const lastOptionSupplierId = store.options.at(-1)?.supplierId;
    store.setOptions([
      ...store.options,
      { unit: '', supplierId: lastOptionSupplierId },
    ]);

    setTimeout(() => {
      inputRefs.current[inputRefs.current.length - 1].current?.focus();
    });
  };

  const isDuplicated = (option: string) =>
    option !== '' && store.options.filter((o) => o.unit === option).length > 1;

  return (
    <div className="flex flex-col gap-[8px]">
      <div className="flex h-[24px] items-center gap-[4px]">
        <p className="tex-gray-700 text-medium-m">옵션</p>
      </div>
      {store.options.map((option, index) => (
        <OptionInput
          ref={inputRefs.current[index]}
          key={index}
          value={option}
          onChange={handleOptionChange(index)}
          onAddOption={handleAddOption}
          isDuplicated={isDuplicated(option.unit)}
          useSupplierByOption={store.useSupplierByOption}
        />
      ))}
      <PlusButton onClick={handleAddOption} />
    </div>
  );
}

export default observer(OptionInputSection);
