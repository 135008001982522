import { Plugin } from 'stores/plugin/interface';
import { GET_SUPPLIERS, GetSuppliersResponse, Supplier } from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import api, { APIResponse } from './api';
import { CreatePluginDTO } from './plugin';

export async function getSuppliers() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetSuppliersResponse>>(GET_SUPPLIERS)
  );

  return response?.data;
}

interface CreateSupplierPlugin {
  supplier: Supplier;
  plugin: Plugin;
}

export async function createSupplierPlugin(
  supplierId: string,
  createPluginDTO: CreatePluginDTO
) {
  const url = `/supplier/${supplierId}/plugin`;
  const response = await api.post<APIResponse<CreateSupplierPlugin>>(
    url,
    createPluginDTO
  );

  return response.data;
}

export async function deleteSupplierPlugin(
  supplierId: string,
  pluginId: string
) {
  const url = `/supplier/${supplierId}/plugin/${pluginId}`;
  const response = await api.delete<APIResponse<Supplier>>(url);

  return response.data;
}
