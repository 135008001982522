export const COLUMN_WIDTHS: { [key: string]: number } = {
  productName: 30,
  quantity: 10,
  name: 15,
  postCode: 10,
  address: 50,
  shippingCompany: 15,
  shippingNumber: 25,
  uniqueCode: 18,
  failReason: 30,
};
