import { CreateMatching } from 'models/CompositionMatching';
import { deepEqual } from 'utils/index';
import { Order } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { isNotEmptyString } from 'src/utils/string';
import { AbstractPlugin } from '../../interface';
import { openMatchedCMForm } from './components/MatchedCMForm/openMatchedCMForm';
import { openUnmatchedCMForm } from './components/UnmatchedCMForm/openUnmatchedCMForm copy';
import { CMOrder, MatchedOrder, ProductMatching } from './interface';
import { isProductEmptyOrder } from './services/isProductEmptyOrder';
import { matchByOptionCode } from './services/matchByOptionCode';
import { matchByProductNameOption } from './services/matchByProductNameOption';
import { separateOrdersByMatched } from './services/separateOrdersByMatched';
import { transformToCMOrder } from './services/transformToCMOrder';
import { transformToCMProduct } from './services/transformToCMProduct';
import { transformToCreateCompositionMatching } from './services/transformToCreateCompositionMatching';
import { transformToCreateOptionCodeMatching } from './services/transformToCreateOptionCodeMatching';
import { uniqueByOptionCode } from './services/uniqueByOptionCode';
import { uniqueByProductNameOption } from './services/uniqueByProductNameOption';

export type CompositionMatchingStage = 'normalize' | 'pre-divide';

export interface CompositionMatchingConfig {
  stage: CompositionMatchingStage;
}

export class CompositionMatchingPlugin extends AbstractPlugin<CompositionMatchingConfig> {
  get stage() {
    return this.config.stage;
  }

  get usingOptionCodeMatching() {
    return (
      this.oms.user.setting?.compositionMatching?.optionCodeMatching ?? false
    );
  }

  transform = async (orders: Order[]): Promise<Order[]> => {
    const { emptyOrders, nonEmptyOrders } =
      this.separateProductEmptyOrders(orders);
    const { matched: initialMatched, unmatched } = this.match(nonEmptyOrders);

    if (unmatched.length !== 0) {
      const cmOrders = unmatched.map(transformToCMOrder);
      const uniqueCMOrders = this.uniqCMOrders(cmOrders);

      const createMatchings = await openUnmatchedCMForm(
        this.oms.order.render,
        uniqueCMOrders,
        this.stage
      );

      if (createMatchings == null) {
        return [];
      }

      if (createMatchings.length > 0) {
        await this.createMatchings(createMatchings);
      }
    }

    const matched =
      unmatched.length === 0
        ? initialMatched
        : this.match(nonEmptyOrders).matched;

    const matchings = matched
      .map<ProductMatching | null>((order) =>
        order.data == null
          ? null
          : {
              cmOrder: transformToCMOrder(order),
              products: order.data.map(transformToCMProduct),
              optionCodeMatchingId: order.plugin?.cm?.optionCodeMatchingId,
              compositionMatchingId: order.plugin?.cm?.compositionMatchingId,
            }
      )
      .filter(isNotNil);
    const uniqueMatchings = this.uniqMatchings(matchings);

    const confirmedMatchings = await openMatchedCMForm(
      this.oms.order.render,
      uniqueMatchings,
      this.stage
    );
    await this.updateMatchings(confirmedMatchings, uniqueMatchings);
    const { matched: matchedFinal, unmatched: unmatchedFinal } =
      this.match(nonEmptyOrders);

    return [
      ...emptyOrders.map((order) => ({ ...order, data: [] })),
      ...matchedFinal.map((order) => ({
        ...order,
        data: order.data!.map((product) => ({
          ...product,
          // TODO(@이지원): 합배송, formatOrders를 ts로 전환한 후 없애기
          unit: product.unit ?? '',
        })),
      })),
      ...unmatchedFinal.map<Order>((order) => {
        const productNameOption = [order.productName, order.option]
          .filter(isNotNil)
          .join(' ');

        return {
          ...order,
          data: [
            {
              productId: '',
              unit: '',
              productName: productNameOption,
              quantity: 1,
            },
          ],
        };
      }),
    ];
  };

  separateProductEmptyOrders = (orders: Order[]) => {
    const emptyOrders: Order[] = [];
    const nonEmptyOrders: Order[] = [];

    orders.forEach((order) => {
      isProductEmptyOrder(order)
        ? emptyOrders.push(order)
        : nonEmptyOrders.push(order);
    });

    return { emptyOrders, nonEmptyOrders };
  };

  match(orders: Order[]): { matched: MatchedOrder[]; unmatched: Order[] } {
    if (this.usingOptionCodeMatching) {
      const optionCodeMatchedOrders = matchByOptionCode(orders, this.oms);
      const { matched: optionCodeMatched, unmatched: optionCodeUnmatched } =
        separateOrdersByMatched(optionCodeMatchedOrders);

      const productNameOptionMatchedOrders = matchByProductNameOption(
        optionCodeUnmatched,
        this.oms
      );

      const {
        matched: productNameOptionMatched,
        unmatched: productNameOptionUnMatched,
      } = separateOrdersByMatched(productNameOptionMatchedOrders);

      return {
        matched: [...optionCodeMatched, ...productNameOptionMatched],
        unmatched: productNameOptionUnMatched,
      };
    }

    const productNameOptionMatchedOrders = matchByProductNameOption(
      orders,
      this.oms
    );
    return separateOrdersByMatched(productNameOptionMatchedOrders);
  }

  uniqCMOrders(orders: CMOrder[]): CMOrder[] {
    const uniqueOrdersByProductNameOption = uniqueByProductNameOption(
      orders,
      (order) => order
    );

    if (this.usingOptionCodeMatching) {
      return uniqueByOptionCode(
        uniqueOrdersByProductNameOption,
        (order) => order
      );
    }

    return uniqueOrdersByProductNameOption;
  }

  uniqMatchings(matchings: ProductMatching[]) {
    const uniqueOrdersByProductNameOption = uniqueByProductNameOption(
      matchings,
      (order) => order.cmOrder
    );

    if (this.usingOptionCodeMatching) {
      return uniqueByOptionCode(
        uniqueOrdersByProductNameOption,
        (order) => order.cmOrder
      );
    }

    return uniqueOrdersByProductNameOption;
  }

  createMatchings = async (
    createMatchings: CreateMatching[]
  ): Promise<void> => {
    if (this.usingOptionCodeMatching) {
      const optionCodeMatchings = createMatchings
        .filter((m) => isNotEmptyString(m.optionCode))
        .map(transformToCreateOptionCodeMatching);

      const compositionMatchings = createMatchings
        .filter((m) => !isNotEmptyString(m.optionCode))
        .map(transformToCreateCompositionMatching);

      if (optionCodeMatchings.length > 0) {
        await this.oms.cm.createOptionCodeMatchings(optionCodeMatchings);
      }

      if (compositionMatchings.length > 0) {
        await this.oms.cm.createCompositionMatchings(compositionMatchings);
      }

      return;
    }

    const compositionMatchings = createMatchings.map(
      transformToCreateCompositionMatching
    );

    if (compositionMatchings.length > 0) {
      await this.oms.cm.createCompositionMatchings(compositionMatchings);
    }
  };

  updateMatchings = async (
    matchings: ProductMatching[],
    prevMatchings: ProductMatching[]
  ) => {
    await Promise.all(
      matchings.map(async (matching, index) => {
        if (deepEqual(matching, prevMatchings.at(index))) {
          return;
        }

        if (matching.compositionMatchingId != null) {
          await this.oms.cm.updateCompositionMatching({
            _id: matching.compositionMatchingId,
            data: matching.products,
          });
        }

        if (matching.optionCodeMatchingId != null) {
          await this.oms.cm.updateOptionCodeMatching({
            _id: matching.optionCodeMatchingId,
            products: matching.products,
          });
        }
      })
    );
  };
}
