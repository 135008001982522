import { z } from 'zod';
import { Product, ZProduct } from '../type/Product';

// GET /product
export const GET_PRODUCTS_URL = '/product';

export type GetProductsResponse = Product[] | null;

// POST /product/register
export const REGISTER_PRODUCT_URL = '/product/register';

export const ZRegisterProductDTO = ZProduct.omit({ _id: true });
export type RegisterProductDTO = z.infer<typeof ZRegisterProductDTO>;

export const ZRegisterProductBody = z.object({
  productInfo: ZRegisterProductDTO,
});
export type RegisterProductBody = z.infer<typeof ZRegisterProductBody>;

export type RegisterProductResponse = Product;

// PUT /product/update
export const UPDATE_PRODUCT_URL = '/product/update';

export const ZUpdateProductDTO = ZProduct.omit({ _id: true }).partial();
export type UpdateProductDTO = z.infer<typeof ZUpdateProductDTO>;

export const ZUpdateProductBody = z.object({
  productId: z.string(),
  productInfo: ZUpdateProductDTO,
});
export type UpdateProductBody = z.infer<typeof ZUpdateProductBody>;

export type UpdateProductResponse = Product | null;

// PUT /product/many
export const UPDATE_PRODUCT_MANY_URL = '/product/many';

export const ZUpdateProductManyDTO = ZProduct.partial().extend({
  _id: z.string(),
});
export type UpdateProductManyDTO = z.infer<typeof ZUpdateProductManyDTO>;

export const ZUpdateManyBody = z.array(ZUpdateProductManyDTO);
export type UpdateManyBody = z.infer<typeof ZUpdateManyBody>;

export type UpdateProductManyResponse = Product[];
