import { NormalizedOrder } from '@sweep/contract';

export function getRemainedOrdersByUniqueCodeAfterCustomization(
  orders: NormalizedOrder[],
  uniqueCodeAfterCustomizations: string[]
): NormalizedOrder[] {
  const uniqueCodeSet = new Set(uniqueCodeAfterCustomizations);

  return orders.filter(
    (order) => !uniqueCodeSet.has(order.uniqueCodeAfterCustomization)
  );
}
