import { ReactElement } from 'react';
import { OMSStore } from 'stores/OMSStore';
import { Order } from '@sweep/contract';

export type PluginContoller = ({
  close,
}: {
  close: () => void;
}) => ReactElement;

export interface Plugin {
  _id: string;
  pluginId: string;
  config?: unknown;
}

export abstract class AbstractPlugin<T = undefined> {
  oms: OMSStore;
  config: T;

  constructor(oms: OMSStore, config: T) {
    this.oms = oms;
    this.config = config;
  }

  render = (controller: PluginContoller) => {
    this.oms.order.component = controller({
      close: () => {
        this.oms.order.component = null;
      },
    });
  };

  abstract transform: (orders: Order[]) => Promise<Order[]>;
}

export type PluginImpl<T> = {
  new (oms: OMSStore, config: T): AbstractPlugin<T>;
};
