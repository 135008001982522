/**
 * @returns items의 값이 모두 같으면 해당 값을, 아니면 null을 반환합니다.
 */
export function getUniform<T>(items: T[]): T | null {
  if (items.length === 0) {
    return null;
  }

  const [first, ...rest] = items;
  return rest.every((item) => item === first) ? first : null;
}
