import { Button } from 'design-system/components/Button';
import { observer } from 'mobx-react-lite';
import { ProductMatching } from '../../interface';
import CMHeader from '../common/CMHeader';
import CMOrderLabel from '../common/CMOrderLabel';
import MatchedCMConfirmFormRow from './MatchedCMConfirmFormRow';

interface NormalizeMatchedCMConfirmFormProps {
  matchings: ProductMatching[];
  onEdit: () => void;
  onSubmit: () => void;
}

function NormalizeMatchedConfirmCM({
  matchings,
  onEdit,
  onSubmit,
}: NormalizeMatchedCMConfirmFormProps) {
  return (
    <div className="flex flex-col">
      <CMHeader />
      {matchings.map(({ cmOrder, products }, index) => (
        <div key={index} className="flex border-b border-gray-200 py-4">
          <CMOrderLabel cmOrder={cmOrder} />
          <div className="w-[42px] shrink-0" />
          <MatchedCMConfirmFormRow products={products} />
        </div>
      ))}
      <div className="m-auto flex gap-[12px] pt-5">
        <Button color="gray" onClick={() => onEdit()}>
          수정하기
        </Button>
        <Button onClick={() => onSubmit()}>완료하기</Button>
      </div>
    </div>
  );
}

export default observer(NormalizeMatchedConfirmCM);
