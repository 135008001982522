import { action, makeObservable, observable, runInAction } from 'mobx';
import { getDrafts, processDrafts, registerDrafts } from 'src/network/draft';
import { OMSStore } from '../OMSStore';

export class DraftStore {
  filenames: string[] = [];

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      filenames: observable,

      init: action.bound,
      loadDraftFilenames: action.bound,

      setFilenames: action.bound,
      register: action.bound,
      process: action.bound,
    });
  }

  async init() {
    await this.loadDraftFilenames();
  }

  async loadDraftFilenames() {
    const response = await getDrafts();

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.setFilenames(response.data);
    });
  }

  setFilenames(filenames: string[]) {
    this.filenames = filenames;
  }

  async register(filenames: string[]) {
    this.filenames.push(...filenames);

    await registerDrafts(filenames);
  }

  async process(filenames: string[]) {
    const filenamesSet = new Set(filenames);
    this.filenames = this.filenames.filter(
      (filename) => !filenamesSet.has(filename)
    );

    await processDrafts(filenames);
  }
}
