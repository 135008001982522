import { Nullable } from '@sweep/utils';
import Select from '../../Select/Select';
import { EnumSchema } from '../schema';

interface JsonEnumInputProps {
  schema: EnumSchema;
  value: Nullable<string>;
  onChange: (value: string) => void;
}

export function JsonEnumInput({ schema, value, onChange }: JsonEnumInputProps) {
  return (
    <div className="gap-8px flex items-center">
      <p className="text-medium-m text-gray-700">{schema.description}</p>
      <Select
        value={value ?? schema.options.at(0)?.value}
        onChange={onChange}
        className="w-200px"
      >
        {schema.options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label ?? option.value}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
