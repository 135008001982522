import { Order } from '@sweep/contract';
import { findHeaderKeys } from 'src/services/column-mapping/findHeaderKeys';
import { ExcelData } from 'src/services/file/interface';
import { OMSStore } from 'src/stores/OMSStore';

type OrderWithOptionalUniqueCode = Omit<Order, 'uniqueCode'> & {
  uniqueCode?: string;
};

export function transformToOrdersWithoutUniqueCode(
  oms: OMSStore,
  excelFile: {
    name: string;
    data: ExcelData;
  }
) {
  if (excelFile.data.length <= 1) {
    return [];
  }

  const header = excelFile.data[0];
  const rows = excelFile.data.slice(1);
  const headerKeys = findHeaderKeys(header, oms.user.excelColumnMapping);

  return rows.map<OrderWithOptionalUniqueCode>((row, index) => {
    const order: Record<string, string> = {};
    row.forEach((value, index) => {
      const key = headerKeys.at(index);
      if (key == null) {
        return;
      }
      const trimmedValue = value.trim();
      if (trimmedValue === '') {
        return;
      }
      order[key] = trimmedValue;
    });

    return {
      originFile: excelFile.name,
      originFileIndex: index,
      ...order,
    };
  });
}
