import { observer } from 'mobx-react-lite';
import { If } from '@sweep/utils';
import { enableMockingStorage } from './browser';
import MockSettingSelect from './common/components/MockSettingSelect';
import { addressTypeStorage } from './common/factory/OrderFactory';
import DispatchSetting from './features/dispatch/setting/DispatchSetting';
import FastapiSetting from './features/fastapi/setting/FastapiSetting';

function MockSetting() {
  const enableMocking = enableMockingStorage.value;

  const handleEnableMocking = (value: boolean) => {
    enableMockingStorage.setValue(value);
  };

  return (
    <div className="flex flex-col gap-2 p-4">
      <div className="flex items-center gap-2">
        <input
          id="enableMocking"
          type="checkbox"
          checked={enableMockingStorage.value}
          onChange={(e) => handleEnableMocking(e.target.checked)}
        />
        <label htmlFor="enableMocking" className="text-medium-m">
          API Mocking 활성화
        </label>
      </div>
      <If is={enableMocking}>
        <DispatchSetting />
        <FastapiSetting />
        <MockSettingSelect
          label="주문수집"
          value={addressTypeStorage.value}
          onChange={addressTypeStorage.setValue}
          options={[
            { value: 'id', label: '유니크 id' },
            { value: 'jibun', label: '지번' },
          ]}
        />
      </If>
    </div>
  );
}

export default observer(MockSetting);
