import { observer } from 'mobx-react-lite';
import { useId } from 'react';
import { UserDeploy } from '@sweep/contract';
import { Checkbox } from 'src/design-system/components/Checkbox';

interface DeploySettingProps {
  deploy: UserDeploy;
  onDeployChange: (deploy: UserDeploy) => void;
}

function DeploySetting({ deploy, onDeployChange }: DeploySettingProps) {
  const handleDeployChange = (key: keyof UserDeploy) => {
    return (value: boolean) => {
      onDeployChange({ ...deploy, [key]: value });
    };
  };

  return (
    <div className="flex flex-col gap-3">
      <p className="text-semibold-2xl">User Deploy</p>
      <div className="flex flex-col gap-[12px]">
        {/* <DeployCheckbox
          label="발주 상태 분리"
          checked={deploy.orderStatusDivide ?? false}
          onCheckedChange={handleDeployChange('orderStatusDivide')}
        /> */}
      </div>
    </div>
  );
}

interface DeployCheckboxProps {
  label: string;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
}

function DeployCheckbox({
  label,
  checked,
  onCheckedChange,
}: DeployCheckboxProps) {
  const id = useId();

  return (
    <label htmlFor={id} className="flex cursor-pointer items-center gap-2">
      <Checkbox id={id} checked={checked} onCheckedChange={onCheckedChange} />
      <p className="text-medium-m">{label}</p>
    </label>
  );
}

export default observer(DeploySetting);
