import { isDispatchedOrder, Order } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';

export function isAliExpressOrder(order: Order, oms: OMSStore): boolean {
  if (order.partnerId != null) {
    const partner = oms.partner.getPartnerById(order.partnerId);
    const partnerName = partner?.name;

    if (partnerName == null) {
      return false;
    }

    return isAliExpressName(partnerName);
  }

  if (isDispatchedOrder(order) && order.shoppingMall != null) {
    return isAliExpressName(order.shoppingMall);
  }

  return false;
}

function isAliExpressName(name: string): boolean {
  return name.includes('알리') || name.toLowerCase().includes('ali');
}
