import { DAY_IN_MILLISECONDS, round } from '@sweep/utils';

export function randomDate(options?: { range?: number }): Date {
  const now = round(new Date().getTime(), -3);
  const random = round(
    Math.random() * (options?.range ?? DAY_IN_MILLISECONDS),
    -3
  );

  return new Date(now - random);
}
