import { FloatingPortal } from '@floating-ui/react';
import { ForwardedRef, forwardRef, HTMLProps } from 'react';
import { cva } from '@sweep/tailwind';
import { useRefs } from '@sweep/utils';
import { useTooltipContext } from './TooltipContext';

function TooltipContent(
  { className, ...rest }: HTMLProps<HTMLDivElement>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { open, refs, disabled, getFloatingProps, floatingStyles } =
    useTooltipContext();
  const innerRef = useRefs([refs.setFloating, ref]);

  if (!open || disabled) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        ref={innerRef}
        className={content({ className })}
        style={floatingStyles}
        {...getFloatingProps?.(rest)}
      />
    </FloatingPortal>
  );
}

const content = cva([
  'flex items-center justify-center',
  'px-12px py-4px rounded-full',
  'text-medium-xs z-50 bg-gray-700 text-white',
]);

export default forwardRef(TooltipContent);
