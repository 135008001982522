import { SalesChannelSupplierId } from '@sweep/contract';
import { SalesChannelsSupplierMatching } from '../interface';

export function getSalesChannelSupplierIds(
  matchings: SalesChannelsSupplierMatching[]
): SalesChannelSupplierId[] {
  const salesChannelSupplierIds: SalesChannelSupplierId[] = [];
  matchings.forEach((matching) => {
    matching.salesChannels.forEach((salesChannel) => {
      switch (salesChannel.type) {
        case 'partner':
          salesChannelSupplierIds.push({
            partnerId: salesChannel.id,
            supplierId: matching.supplierId,
          });
          break;
        case 'shoppingMall':
          salesChannelSupplierIds.push({
            shoppingMallId: salesChannel.id,
            supplierId: matching.supplierId,
          });
          break;
      }
    });
  });

  return salesChannelSupplierIds;
}
