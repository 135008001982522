export function flattenMultiHeader(headers: string[][]): string[] {
  // 현재 깊이 3 이상의 병합 헤더는 고려하지 않음
  if (headers.length < 2) {
    return headers[0] ?? [];
  }
  const filledFirstRow = forwardFill(headers[0]);
  const secondRow = headers[1];
  return filledFirstRow.map((first, i) => {
    const second = secondRow[i];
    if (second === first || second === '') {
      return first;
    }
    return `${first} > ${second}`;
  });
}

function forwardFill(strings: string[]): string[] {
  const filledStrings = [...strings];
  for (let i = 0; i < filledStrings.length - 1; i++) {
    if (filledStrings[i + 1] === '') {
      filledStrings[i + 1] = filledStrings[i];
    }
  }
  return filledStrings;
}
