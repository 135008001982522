import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useOMSStore } from 'src/hooks/useOMSStore';
import sweepIcon from '../../images/Sweep.png';
import NavigationButton from './NavigationButton';
import { ToggleIcon } from './ToggleIcon';

type ParentTab =
  | 'settlement'
  | 'retailerSettlement'
  | 'product'
  | 'setting'
  | 'retailerSetting';

type ToggleState = Record<ParentTab, boolean>;

const childTabsByParentTab: Record<ParentTab, string[]> = {
  settlement: ['/settlement/daily-management'],
  retailerSettlement: ['/retailer/settlement/daily-management'],
  product: [
    '/product',
    '/product/combination',
    '/product/composition-matching',
  ],
  setting: ['/setting/partner'],
  retailerSetting: ['/retailer/setting/partner', '/retailer/setting/supplier'],
};

const Sidebar = () => {
  const { pathname } = useLocation();
  const initialState = getInitialToggleState(pathname);

  const [toggleStates, setToggleStates] = useState<ToggleState>(initialState);

  const toggle = (key: keyof ToggleState) => {
    setToggleStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const oms = useOMSStore();
  const companyType = oms.user.companyType;
  const isSupplier = companyType === 'supplier';
  const isRetailer = companyType === 'retailer';
  const baseUrl = window.location.origin;
  const IS_ADMIN =
    baseUrl.includes('localhost:3000') ||
    baseUrl.includes('127.0.0.1:3000') ||
    oms.user.admin;

  return (
    <div
      className="fixed flex h-full w-[280px] flex-none shrink-0 flex-col items-start overflow-y-auto bg-white"
      style={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.12)' }}
    >
      <div className="h-[102px] w-full">
        <div className="py-[36px] pl-[40px]">
          <img src={sweepIcon} alt="service icon" className="h-auto w-[95px]" />
        </div>
      </div>

      <nav className="w-full">
        <div className="px-[20px] text-[16px] font-semibold leading-[19px] text-[color:var(--Gray-600,#343D4B)]">
          <div className="flex items-center gap-[6px] px-[20px] py-[6px]">
            <span className="font-pretendard text-[14px] font-bold text-[color:var(--Gray-400,#9FB1C1)]">
              {isSupplier ? '공급사' : isRetailer ? '위탁판매사' : <br />}
            </span>
          </div>
          <ul className="space-y-[8px]">
            <li>
              <NavigationButton to="order-dispatch">
                주문 수집하기
              </NavigationButton>
            </li>
            <li>
              <NavigationButton to="order-aggregation">
                발주 진행하기
              </NavigationButton>
            </li>
            <li>
              <NavigationButton to="order-dispatch-search">
                주문 조회하기
              </NavigationButton>
            </li>
            <button
              onClick={() =>
                isSupplier
                  ? toggle('settlement')
                  : isRetailer
                    ? toggle('retailerSettlement')
                    : null
              }
              className="flex h-[37px] w-full items-center rounded-lg px-[20px] text-left transition duration-300 ease-in-out hover:bg-[#F4F8FE]"
            >
              <div className="flex flex-1 items-center justify-between gap-[12px]">
                <div>정산 관리</div>
                <ToggleIcon
                  active={
                    isSupplier
                      ? toggleStates['settlement']
                      : isRetailer
                        ? toggleStates['retailerSettlement']
                        : false
                  }
                />
              </div>
            </button>
            {isSupplier && toggleStates['settlement'] && (
              <ul className="mt-[4px] space-y-[4px] px-[20px] text-sm">
                <li>
                  <NavigationButton to="settlement/daily-management">
                    일일 정산 관리
                  </NavigationButton>
                </li>
              </ul>
            )}
            {isRetailer && toggleStates['retailerSettlement'] && (
              <ul className="mt-[4px] space-y-[4px] px-[20px] text-sm">
                <li>
                  <NavigationButton to="retailer/settlement/daily-management">
                    일일 정산 관리
                  </NavigationButton>
                </li>
              </ul>
            )}

            <button
              onClick={() => toggle('product')}
              className="flex h-[37px] w-full items-center rounded-lg px-[20px] text-left transition duration-300 ease-in-out hover:bg-[#F4F8FE]"
            >
              <div className="flex flex-1 items-center justify-between gap-[12px]">
                <div>상품 관리</div>
                <ToggleIcon active={toggleStates['product'] ?? false} />
              </div>
            </button>
            {toggleStates['product'] && (
              <ul className="mt-[4px] space-y-[4px] px-[20px] text-sm">
                <li>
                  <NavigationButton to="product">상품 관리</NavigationButton>
                </li>
                <li>
                  <NavigationButton to="product/combination">
                    합배송 설정
                  </NavigationButton>
                </li>
                <li>
                  <NavigationButton to="product/composition-matching">
                    상품 구성 매칭 관리
                  </NavigationButton>
                </li>
              </ul>
            )}

            <button
              onClick={() =>
                isSupplier
                  ? toggle('setting')
                  : isRetailer
                    ? toggle('retailerSetting')
                    : null
              }
              className="flex h-[37px] w-full items-center rounded-lg px-[20px] text-left transition duration-300 ease-in-out hover:bg-[#F4F8FE]"
            >
              <div className="flex flex-1 items-center justify-between gap-[12px]">
                <div>설정</div>
                <ToggleIcon
                  active={
                    isSupplier
                      ? toggleStates['setting']
                      : isRetailer
                        ? toggleStates['retailerSetting']
                        : false
                  }
                />
              </div>
            </button>
            {isSupplier && toggleStates['setting'] && (
              <ul className="mt-[4px] space-y-[4px] px-[20px] text-sm">
                <li>
                  <NavigationButton to="setting/partner">
                    판매처 관리
                  </NavigationButton>
                </li>
              </ul>
            )}
            {isRetailer && toggleStates['retailerSetting'] && (
              <ul className="mt-[4px] space-y-[4px] px-[20px] text-sm">
                <li>
                  <NavigationButton to="retailer/setting/partner">
                    판매처 관리
                  </NavigationButton>
                </li>
                <li>
                  <NavigationButton to="retailer/setting/supplier">
                    공급사 관리
                  </NavigationButton>
                </li>
              </ul>
            )}
          </ul>

          {IS_ADMIN && (
            <>
              <div className="flex items-center gap-[6px] px-[20px] py-[6px]">
                <span className="font-pretendard text-[14px] font-bold text-[color:var(--Gray-400,#9FB1C1)]">
                  관리자
                </span>
              </div>
              <ul className="space-y-[8px]">
                <li>
                  <NavigationButton to="admin/user-setting">
                    사용자 설정 관리
                  </NavigationButton>
                </li>
                <li>
                  <NavigationButton to="setting/plugin">
                    플러그인 설정
                  </NavigationButton>
                </li>
                <li>
                  <NavigationButton to="setting/qa">QA 설정</NavigationButton>
                </li>
                <li>
                  <NavigationButton to="setting/integrated-excel-format">
                    통합 엑셀 양식 관리
                  </NavigationButton>
                </li>
              </ul>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

const getInitialToggleState = (pathname: string): ToggleState => {
  return Object.fromEntries(
    Object.entries(childTabsByParentTab).map(([parentTab, childTabs]) => [
      parentTab,
      childTabs.includes(pathname),
    ])
  ) as ToggleState;
};

export default observer(Sidebar);
