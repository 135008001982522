export function compareDate(a: any, b: any): number {
  const timeA = new Date(a).getTime();
  const timeB = new Date(b).getTime();

  if (isNaN(timeA) && isNaN(timeB)) {
    return 0;
  }

  if (isNaN(timeA)) {
    return -1;
  }

  if (isNaN(timeB)) {
    return 1;
  }

  return timeA - timeB;
}
