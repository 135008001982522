import { toast } from 'sonner';
import { FulfilledOrder, fulfillOrder } from 'src/network/order/fullfillOrder';
import backendApi from '../utils/backendApi';

const useMallOrderManagement = () => {
  const updateShippingInfoToMall = async (orders: FulfilledOrder[]) => {
    orders = orders.filter((order) => Object.keys(order).length > 0);
    const res = await fulfillOrder(orders);

    if (res?.success === true) {
      const failedOrders = res.data.filter((order) => !order.success);
      const successOrders = res.data.filter((order) => order.success);
      if (failedOrders.length > 0) {
        toast.error(
          '운송장 등록에 실패한 주문 건들이 있습니다. 해당 건들의 실패 사유를 확인해 주세요.'
        );
      } else {
        toast.success('운송장이 등록되었습니다.');
      }

      return { successOrders, failedOrders };
    }

    toast.error('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');

    return null;
  };
  const autoUpdateShippingInfoToMall = async (orders: any[]) => {
    orders = orders.filter((order) => Object.keys(order).length > 0);
    const res = await backendApi.autoUpdateShippingInfoToMall(orders);

    if (res.success) {
      const failedOrders = (res.data as any[]).filter(
        (order) => !order.success
      );
      const successOrders = (res.data as any[]).filter(
        (order) => order.success
      );

      return { successOrders, failedOrders };
    }

    toast.error('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');

    return null;
  };

  return { updateShippingInfoToMall, autoUpdateShippingInfoToMall };
};

export default useMallOrderManagement;
