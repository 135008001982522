import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useId,
  useRef,
  useState,
} from 'react';
import { mergeProps, useFocus } from 'react-aria';
import { cva } from '@sweep/tailwind';
import { useRefs } from '@sweep/utils';
import { useAutoSelect } from 'src/design-system/hooks/useAutoSelect';
import { IconDeleteAll } from 'src/icons';
import { isNotEmptyString } from 'src/utils/string';

export interface TextInputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'ref' | 'min' | 'max' | 'onChange' | 'defaultValue'
  > {
  value?: string;
  helperText?: string;
  status?: 'default' | 'error';
  onChange?: (value: string) => void;
  autoSelect?: boolean;
  saveOnBlur?: boolean;
  onEnterDown?: () => void;
}

function TextInput(
  {
    id: givenId,
    value: givenValue,
    onChange: givenOnChange,
    disabled,
    status = 'default',
    autoSelect = false,
    saveOnBlur = false,
    helperText,
    className,
    onBlur: givenOnBlur,
    onEnterDown,
    ...rest
  }: TextInputProps,
  givenRef: ForwardedRef<HTMLInputElement>
) {
  const randomId = useId();
  const id = givenId ?? randomId;
  const innerRef = useRef<HTMLInputElement>(null);
  const ref = useRefs([givenRef, innerRef]);

  const [focused, setFocused] = useState(false);
  const { focusProps } = useFocus({ onFocusChange: setFocused });

  // NOTE(@이지원): givenValue가 변경되었을 때, displayValue를 변경하기 위한 state
  const [prevValue, setPrevValue] = useState(givenValue);
  const [displayValue, setDisplayValue] = useState(givenValue);

  if (prevValue !== givenValue) {
    setPrevValue(givenValue);
    setDisplayValue(givenValue);
  }

  useAutoSelect({
    ref: innerRef,
    disabled: disabled || !autoSelect,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    focusProps?.onChange?.(e);
    setDisplayValue(e.target.value);
    if (!saveOnBlur) {
      givenOnChange?.(e.target.value);
      setPrevValue(e.target.value);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    focusProps?.onBlur?.(e);
    givenOnBlur?.(e);
    if (saveOnBlur) {
      givenOnChange?.(e.target.value);
      setPrevValue(e.target.value);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onEnterDown?.();
    }
  };

  return (
    <div className={container({ className })}>
      <div className={inputContainer({ focused, status })}>
        <input
          id={id}
          ref={ref}
          type="text"
          autoComplete="off"
          value={displayValue ?? ''}
          className={input()}
          {...mergeProps(focusProps, rest)}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
        />
        {isNotEmptyString(displayValue) && focused && (
          <button
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => givenOnChange?.('')}
          >
            <IconDeleteAll />
          </button>
        )}
      </div>
      {helperText != null && (
        <p className="text-medium-xs h-[25px] px-[8px] py-[4px] text-red-500">
          {!focused && helperText}
        </p>
      )}
    </div>
  );
}

const container = cva('flex flex-col');

const inputContainer = cva(
  [
    'flex h-[40px] w-full items-center gap-[8px] rounded-[8px] pl-[12px] pr-[16px]',
    'border bg-white',
  ],
  {
    variants: {
      focused: {
        true: '',
        false: '',
      },
      status: {
        default: '',
        error: '',
      },
    },
    compoundVariants: [
      { focused: false, status: 'default', className: 'border-gray-300' },
      {
        focused: false,
        status: 'error',
        className: 'border-red-500',
      },
      {
        focused: true,
        status: 'default',
        className: 'border-blue-500',
      },
      {
        focused: true,
        status: 'error',
        className: 'border-blue-500',
      },
    ],
  }
);

const input = cva('text-medium-s h-full flex-1 text-gray-700 outline-none');

export default forwardRef(TextInput);
