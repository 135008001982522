import { SpreadsheetError } from '../errors/SpreadsheetError';

const ALPHABET_SIZE = 26;
const ASCII_UPPERCASE_A = 65;

/**
 * Excel 열 문자를 0부터 시작하는 숫자 인덱스로 변환합니다
 * @param column - Excel 열 문자 (예: 'A', 'B', 'AA')
 * @returns 0부터 시작하는 숫자 인덱스 (예: 'A' -> 0, 'B' -> 1, 'Z' -> 25, 'AA' -> 26)
 * @throws {SpreadsheetError} 열이 유효하지 않거나 변환에 실패한 경우
 */
export function convertColumnAddressToIndex(column: string): number {
  try {
    if (column == null || typeof column !== 'string' || column.length === 0) {
      throw new Error(`column is required`);
    }

    if (!/^[A-Z]+$/.test(column)) {
      throw new Error(`column is invalid: ${column}`);
    }

    let index = 0;
    for (const char of column) {
      index *= ALPHABET_SIZE;
      index += char.charCodeAt(0) - ASCII_UPPERCASE_A + 1;
    }

    return index - 1;
  } catch (error) {
    throw new SpreadsheetError(
      'Failed to convert column address to index',
      'UTIL',
      error
    );
  }
}

/**
 * 0부터 시작하는 숫자 인덱스를 Excel 열 문자로 변환합니다
 * @param index - 0부터 시작하는 숫자 인덱스 (예: 0, 1, 25, 26)
 * @returns Excel 열 문자 (예: 0 -> 'A', 1 -> 'B', 25 -> 'Z', 26 -> 'AA')
 * @throws {SpreadsheetError} 인덱스가 유효하지 않거나 변환에 실패한 경우
 */
export function convertColumnIndexToAddress(index: number): string {
  try {
    if (index < 0) {
      throw new Error(`index is invalid: ${index}`);
    }

    // Excel 최대 열은 XFD (16384)이므로 4자리면 충분
    const MAX_EXCEL_COLUMN_LENGTH = 10;
    const columnAddress = convertIndexToColumnAddress(
      index,
      MAX_EXCEL_COLUMN_LENGTH
    );
    if (columnAddress.length >= MAX_EXCEL_COLUMN_LENGTH) {
      throw new Error(`Column index too large: ${index}`);
    }

    return columnAddress;
  } catch (error) {
    throw new SpreadsheetError(
      'Failed to convert column index to address',
      'UTIL',
      error
    );
  }
}

function convertIndexToColumnAddress(
  columnIndex: number,
  MAX_ITERATIONS: number
): string {
  const columnChars: string[] = [];
  let remainingIndex = columnIndex + 1;
  let iterations = 0;
  while (remainingIndex > 0 && iterations < MAX_ITERATIONS) {
    const remainder = (remainingIndex - 1) % ALPHABET_SIZE;
    const char = String.fromCharCode(ASCII_UPPERCASE_A + remainder);
    columnChars.push(char);
    remainingIndex = Math.floor((remainingIndex - 1) / ALPHABET_SIZE);
    iterations++;
  }

  return columnChars.reverse().join('');
}
