import { Button } from 'design-system/components/Button';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { If, SwitchCase } from '@sweep/utils';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { IconControlDownload } from 'src/icons';
import { ProductMatching } from '../../interface';
import CMProgress from '../common/CMProgress';
import MatchedCMEditForm from './MatchedCMEditForm';
import PreDivideMatchedCMConfirmForm from './PreDivideMatchedCMConfirmForm';

interface MatchedCMFormProps {
  matchings: ProductMatching[];
  onSubmit: (value: ProductMatching[]) => void;
}

type Status = 'confirm' | 'edit';

function MatchedCMForm({
  matchings: givenMatching,
  onSubmit,
}: MatchedCMFormProps) {
  const oms = useOMSStore();
  const companyType = oms.user.companyType;

  const [matchings, setMatchings] = useState(givenMatching);
  const [status, setStatus] = useState<Status>('confirm');

  const onEdit = () => setStatus('edit');

  const onEditSubmit = (value: ProductMatching[]) => {
    setMatchings(value);
    setStatus('confirm');
  };

  const onConfirmSubmit = () => {
    onSubmit(matchings);
  };

  return (
    <div className="bg-gray-100 px-10 pb-[40px] pt-[20px]">
      <div className="h-42px flex items-center justify-between">
        <p className="text-extrabold-l flex-1">발주 진행하기</p>
        <If is={status === 'confirm'}>
          <Button
            onClick={onConfirmSubmit}
            rightAccessory={<IconControlDownload className="text-blue-200" />}
          >
            <div className="gap-8px flex items-center">
              <p className="text-bold-s text-white">완료</p>
              <div className="h-15px w-[1.5px] bg-blue-200" />
              <p className="text-semibo text-blue-200">발주서</p>
            </div>
          </Button>
        </If>
      </div>
      <div className="h-[40px]" />
      <CMProgress>
        <CMProgress.Title index={1} active>
          상품 구성 매칭
        </CMProgress.Title>
        <CMProgress.Description>
          이번에 사용된 상품 매칭을 확인해주세요.
        </CMProgress.Description>
      </CMProgress>
      <div className="h-[20px]" />
      <div className="items-center rounded-[8px] bg-white p-5 shadow-sm">
        <SwitchCase
          value={status}
          caseBy={{
            confirm: (
              <PreDivideMatchedCMConfirmForm
                matchings={matchings}
                onEdit={onEdit}
                onSubmit={onConfirmSubmit}
              />
            ),
            edit: (
              <MatchedCMEditForm
                matchings={matchings}
                onSubmit={onEditSubmit}
              />
            ),
          }}
        />
      </div>
    </div>
  );
}

export default observer(MatchedCMForm);
