import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { NormalizedOrder } from '@sweep/contract';
import { formatDate, If } from '@sweep/utils';
import { Button } from 'src/design-system/components/Button';
import SearchInput from 'src/design-system/components/SearchInput/SearchInput';
import { SelectableTable } from 'src/design-system/components/Table/selectable/SelectableTable';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import useShippingUpload from 'src/hooks/useShippingUpload';
import { IconControlDownload, IconControlUpload } from 'src/icons';
import { createOrderExcel } from 'src/services/file/excel/create';
import PurchaseOrderFileList from '../components/PurchaseOrderFileList';
import TableCount from '../components/TableCount';
import TableSelectedCount from '../components/TableSelectedCount';
import { useTableFilterColumns } from '../hooks/useTableFilterColumns';
import { OrderShippingSectionStore } from '../stores/OrderShippingSectionStore';

function OrderShippingProcessSection() {
  const oms = useOMSStore();
  const store = oms.getStore(OrderShippingSectionStore);

  const { retailerUploadShippingInfo, supplierUploadShippingInfo } =
    useShippingUpload();

  const [search, setSearch, filterBySearch] = useSearchState({
    keys: oms.user.excelHeaderKeys,
  });
  const searchFilteredOrders = useMemo(
    () => filterBySearch(store.shippingProcessOrders),
    [filterBySearch, store.shippingProcessOrders]
  );
  const { columns, filteredOrders } =
    useTableFilterColumns(searchFilteredOrders);

  const [selectedOrders, setSelectedOrders] = useState<NormalizedOrder[]>([]);
  const currentOrders =
    selectedOrders.length > 0 ? selectedOrders : filteredOrders;

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files == null) {
      alert('파일을 선택해주세요.');
      return;
    }

    const uploadShippingInfo = oms.user.isRetailer
      ? retailerUploadShippingInfo
      : supplierUploadShippingInfo;

    await oms.loading.batch(() => uploadShippingInfo(Array.from(files)));
    setSelectedOrders([]);
  };

  const handleRevert = async (filenames: string[]) => {
    await store.revertToPending(filenames);
    setSelectedOrders([]);
  };

  const download = () =>
    createOrderExcel(
      oms,
      currentOrders,
      formatDate(new Date(), 'yy.MM.dd 통합엑셀'),
      oms.user.excelHeaders,
      oms.user.excelColumnMapping
    );

  const handleUpload = async (files: FileList) => {
    await store.uploadHandwrittenFile(files);
    setSelectedOrders([]);
  };

  return (
    <div className="gap-20px flex flex-col">
      <PurchaseOrderFileList
        filenames={store.purchaseOrderFilenames}
        onRevert={handleRevert}
        onDownload={store.downloadPurchaseOrder}
        onUpload={handleUpload}
      />
      <div className="gap-16px px-20px py-24px rounded-8px shadow-line flex flex-col bg-white">
        <div className="flex justify-between">
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder="검색어를 입력하세요."
            className="max-w-400px"
          />
          <Button rightAccessory={<IconControlUpload />} asChild>
            <label>
              운송장 파일 업로드
              <input
                className="hidden"
                type="file"
                accept=".xlsx, .xls, .csv"
                multiple
                onChange={handleFileUpload}
              />
            </label>
          </Button>
        </div>
        <hr />
        <div className="flex flex-col">
          <TableCount count={filteredOrders.length} />
          <div className="flex items-end justify-between">
            <TableSelectedCount count={selectedOrders.length} />
            <Button
              color="gray"
              rightAccessory={<IconControlDownload />}
              onClick={download}
            >
              엑셀 파일
            </Button>
          </div>
        </div>
        <If is={store.shippingProcessOrders.length > 0}>
          <SelectableTable
            key={store.purchaseOrderFilenames.join(',')}
            items={filteredOrders}
            columns={columns}
            selectedItems={selectedOrders}
            onSelect={setSelectedOrders}
            getKey={(order) => order.uniqueCodeAfterCustomization}
            className="max-h-[calc(100vh-300px)]"
          />
        </If>
      </div>
    </div>
  );
}

export default observer(OrderShippingProcessSection);
