import { SalesChannelsSupplierMatching } from '../interface';

export function isExistEmptySalesChannel(
  matchings: SalesChannelsSupplierMatching[]
) {
  return matchings.some(
    (matching) =>
      matching.salesChannels.length === 0 && matching.supplierId != null
  );
}
