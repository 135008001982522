import * as Sentry from '@sentry/react';
import type {
  ErrorBreadcrumb,
  ErrorHint,
  ErrorReporter,
} from '@sweep/contract';

function getHintData(hint?: ErrorHint) {
  return hint?.extra ? { extra: hint.extra } : undefined;
}

function getBreadcrumbData(breadcrumb: ErrorBreadcrumb) {
  const { type, message, extra } = breadcrumb;
  return { type, message, data: extra };
}

const CustomErrorReporter: ErrorReporter = {
  captureError: (error, hint) => {
    const hintData = getHintData(hint);

    if (error instanceof Error) {
      return Sentry.captureException(error, hintData);
    }

    const unknownErrorString = String(error);
    return Sentry.captureMessage(unknownErrorString, hintData);
  },
  captureMessage: (message, hint) => {
    const hintData = getHintData(hint);

    return Sentry.captureMessage(message, hintData);
  },
  addBreadcrumb: (breadcrumb) => {
    const breadcrumbData = getBreadcrumbData(breadcrumb);

    Sentry.addBreadcrumb(breadcrumbData);
  },
  setUser: (userId, email) => {
    Sentry.setUser({
      id: userId,
      email: email,
    });
  },
};

const ErrorReporter = {
  ...CustomErrorReporter,

  /** @deprecated Use captureError instead */
  reportError: CustomErrorReporter.captureError,
  /** @deprecated Use captureMessage instead */
  reportMessage: CustomErrorReporter.captureMessage,
  /** @deprecated Use addBreadcrumb instead */
  leaveBreadcrumb: CustomErrorReporter.addBreadcrumb,
};

export default ErrorReporter;
