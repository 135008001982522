import { observer } from 'mobx-react-lite';
import MockSettingSelect from '../../../common/components/MockSettingSelect';
import { checkAddressTypeStorage } from '../handlers/checkAddressHandlers';

function FastapiSetting() {
  return (
    <div className="flex w-fit flex-col gap-2 rounded-lg border-2 border-gray-200 p-3">
      <p className="text-bold-l">FastAPI</p>
      <MockSettingSelect
        label="주소 확인"
        value={checkAddressTypeStorage.value}
        onChange={checkAddressTypeStorage.setValue}
        options={[
          { value: 'success', label: '성공' },
          { value: 'success-long-time', label: '성공 - 오래걸림' },
          { value: 'fail-long-time', label: '실패 - 오래걸림' },
          { value: 'fail', label: '실패' },
          { value: 'none', label: '안함' },
        ]}
      />
    </div>
  );
}

export default observer(FastapiSetting);
