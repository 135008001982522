import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { cva } from '@sweep/tailwind';
import { useIsOverflow } from '@sweep/utils';
import { Tooltip } from 'src/design-system';
import { CheckIcon } from 'src/design-system/components/CheckIcon';

interface FilterFieldItemProps {
  checked: boolean;
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

function TableFilterDropdownItem({
  checked,
  onClick,
  className,
  children,
}: FilterFieldItemProps) {
  const ref = useRef<HTMLParagraphElement>(null);
  const isOverflow = useIsOverflow(ref, 'x');

  return (
    <button className={container({ checked, className })} onClick={onClick}>
      <CheckIcon checked={checked} />
      <Tooltip>
        <Tooltip.Trigger>
          <p ref={ref} className="text-medium-s text-ellipsis-nowrap">
            {children}
          </p>
        </Tooltip.Trigger>
        {isOverflow && <Tooltip.Content>{children}</Tooltip.Content>}
      </Tooltip>
    </button>
  );
}

const container = cva(
  'h-36px px-12px gap-4px flex shrink-0 items-center text-gray-700',
  {
    variants: {
      checked: {
        true: 'bg-gray-100',
        false: 'bg-white',
      },
    },
  }
);

export default observer(TableFilterDropdownItem);
