import _ from 'lodash';
import { NormalizedOrder, Order, OrderStatus } from '@sweep/contract';
import { formatDate, isNotNil } from '@sweep/utils';
import { FulfilledOrderResult } from 'src/network/order/fullfillOrder';
import {
  CANCEL_REQUESTED_ORDER_COLUMN_MAPPING,
  CANCEL_REQUESTED_ORDER_HEADERS,
} from 'src/screens/dispatch/constants';
import { createOrderExcel } from 'src/services/file/excel/create';
import { toast } from 'src/third-parties/toast';
import { RawOrderXX } from '../models/OrderXX';
import backendApi from '../utils/backendApi';
import {
  extractEnglishShippingHeaders,
  mapKeysByColumnMapping,
  removeEndNulls,
} from '../utils/headerColumnMapping';
import { shoppingMallTemps } from '../utils/mappingArrays';
import { readExcelFile } from '../utils/readExcelFile';
import { areArraysEqual, areMutualSubsequences, isValid } from '../utils/utils';
import { detectEnglishByKoreanHeader } from './fileHandling/utils/fileHandlePartnerUtils';
import useMallOrderManagement from './useMallOrderManagement';
import { useOMSStore } from './useOMSStore';

const useShippingUpload = () => {
  const oms = useOMSStore();
  const { updateShippingInfoToMall } = useMallOrderManagement();

  const extractShippingInfo = async (files: File[]) => {
    let orders: { [key: string]: string | number | undefined }[] = [];

    for (const file of files) {
      let rows = [];

      const readExcelSettings = {
        isOrderFile: false,
      };
      rows = await readExcelFile(file, readExcelSettings);

      let koreanHeaderRow = removeEndNulls(rows[0]) || [];
      let englishHeaderRow: (string | null)[] =
        extractEnglishShippingHeaders(koreanHeaderRow) || [];

      const userCustomExcelFormat = [...oms.user.excelHeaderKeys];

      const shippingExcelTemplate =
        oms.user.setting?.shippingExcelTemplate || {};

      if (
        _.isEqual(
          koreanHeaderRow,
          userCustomExcelFormat.map(
            (english) => oms.user.excelColumnMapping[english]
          )
        )
      ) {
        englishHeaderRow = userCustomExcelFormat;
      } else if (
        isValid(shippingExcelTemplate) &&
        areMutualSubsequences(koreanHeaderRow, shippingExcelTemplate.headers)
      ) {
        englishHeaderRow = mapKeysByColumnMapping(
          koreanHeaderRow,
          shippingExcelTemplate.headerMatchingMap
        );
      }

      for (const partnerInfo of oms.partner.partners) {
        const temp = detectEnglishByKoreanHeader(koreanHeaderRow, partnerInfo);
        if (temp) {
          englishHeaderRow = temp;
          break;
        }
      }

      for (const shoppingMallTemp of shoppingMallTemps) {
        if (areArraysEqual(koreanHeaderRow, shoppingMallTemp.headers)) {
          if (['랭킹닭컴', '웰스토리'].includes(shoppingMallTemp.mallName)) {
            rows.splice(1, 1);
            koreanHeaderRow = shoppingMallTemp.realHeaders || [];
          }

          englishHeaderRow = mapKeysByColumnMapping(
            koreanHeaderRow,
            shoppingMallTemp.headerTranslationMap
          );
        }
      }

      const newOrders = rows.slice(1).map((row: string[]) => {
        const entry: { [key: string]: string | number } = {};
        row.forEach((cell, i) => {
          const key = englishHeaderRow[i];
          if (key && isValid(cell)) {
            entry[key] = cell;
          }

          const defaultShippingCompany: string =
            oms.user.setting?.defaultShippingCompany || '';

          if (isValid(defaultShippingCompany)) {
            entry['shippingCompany'] = defaultShippingCompany;
          }
        });

        return entry;
      });

      orders = [...orders, ...newOrders];
    }

    return orders;
  };

  const retailerUploadShippingInfo = async (files: File[]) => {
    const res = await backendApi.retailerUploadShippingInfo(files);

    const successOrderMap: Map<string, RawOrderXX> = new Map(
      res.successOrders.map((order: RawOrderXX) => [order.uniqueCode, order])
    );

    const successOrderMapByOrderNumber: Map<string, RawOrderXX> = new Map(
      res?.successOrders?.map((order: RawOrderXX) => [order.orderNumber, order])
    );

    let updatedOrders = [...oms.order.normalizedOrders] as RawOrderXX[];

    updatedOrders = updatedOrders.map((order: RawOrderXX) => {
      const updateInfo: RawOrderXX | undefined = successOrderMap.get(
        order.uniqueCode
      );
      if (updateInfo) {
        order.shippingCompany = updateInfo.shippingCompany ?? '';
        order.shippingNumber = updateInfo.shippingNumber ?? '';
      } else {
        const updateInfoWithOrderNumber: RawOrderXX | undefined =
          order.orderNumber != null
            ? successOrderMapByOrderNumber.get(order.orderNumber)
            : undefined;
        if (updateInfoWithOrderNumber) {
          order.shippingCompany =
            updateInfoWithOrderNumber.shippingCompany ?? '';
          order.shippingNumber = updateInfoWithOrderNumber.shippingNumber ?? '';
        }
      }
      return order;
    });

    oms.order.setNormalizedOrders(updatedOrders as NormalizedOrder[]);

    if (isValid(res?.failedOrders)) {
      toast.error(
        '운송장 입력에 실패한 주문이 있습니다. 실패 사유는 엑셀 파일을 확인해주세요.'
      );

      await createOrderExcel(
        oms,
        res?.failedOrders,
        formatDate(new Date(), 'yy.MM.dd 운송장 입력 실패 건'),
        ['실패 사유', ...oms.user.excelHeaders],
        { failReason: '실패 사유', ...oms.user.excelColumnMapping }
      );
    } else {
      toast.success('운송장이 입력되었습니다.');
    }
  };

  const supplierUploadShippingInfo = async (files: File[]) => {
    const res = await backendApi.supplierUploadShippingInfo(files);

    const successOrderMap: Map<string, RawOrderXX> = new Map(
      res.successOrders.map((order: RawOrderXX) => [order.uniqueCode, order])
    );

    const successOrderMapByOrderNumber: Map<string, RawOrderXX> = new Map(
      res?.successOrders?.map((order: RawOrderXX) => [order.orderNumber, order])
    );

    let updatedOrders = [...oms.order.normalizedOrders] as RawOrderXX[];

    updatedOrders = updatedOrders.map((order: RawOrderXX) => {
      const updateInfo: RawOrderXX | undefined = successOrderMap.get(
        order.uniqueCode
      );
      if (updateInfo) {
        order.shippingCompany = updateInfo.shippingCompany || '';
        order.shippingNumber = updateInfo.shippingNumber || '';
      } else {
        const updateInfoWithOrderNumber: RawOrderXX | undefined =
          order.orderNumber
            ? successOrderMapByOrderNumber.get(order.orderNumber)
            : undefined;
        if (updateInfoWithOrderNumber) {
          order.shippingCompany =
            updateInfoWithOrderNumber.shippingCompany || '';
          order.shippingNumber = updateInfoWithOrderNumber.shippingNumber || '';
        }
      }
      return order;
    });

    oms.order.setNormalizedOrders(updatedOrders as NormalizedOrder[]);

    if (isValid(res?.failedOrders)) {
      toast.error(
        '운송장 입력에 실패한 주문이 있습니다. 실패 사유는 엑셀 파일을 확인해주세요.'
      );

      await createOrderExcel(
        oms,
        res?.failedOrders,
        formatDate(new Date(), 'yy.MM.dd 운송장 입력 실패 건'),
        ['실패 사유', ...oms.user.excelHeaders],
        { failReason: '실패 사유', ...oms.user.excelColumnMapping }
      );
    } else {
      toast.success('운송장이 입력되었습니다.');
    }
  };

  const mallUploadShippingInfo = async (files: File[]) => {
    // extractOrdersFromFiles
    let shippingInfos = await extractShippingInfo(files);

    // shippingInfos[0]의 key 중에 shippingNumber, shippingCompany, uniqueCode가 없으면 return
    if (
      shippingInfos.length === 0 ||
      !shippingInfos[0].shippingNumber ||
      !shippingInfos[0].shippingCompany ||
      (!shippingInfos[0].uniqueCode && !shippingInfos[0].orderNumber)
    ) {
      alert(
        '송장번호, 택배사, 스윕고유번호 또는 주문번호가 포함된 엑셀 파일을 업로드해주세요.'
      );
      return;
    }
    shippingInfos = shippingInfos.flatMap((info: any) => {
      if (!info.uniqueCode && !info.orderNumber) {
        return [];
      }
      if (isValid(info.uniqueCode)) {
        return info.uniqueCode.split(',').map((uniqueCode: string) => ({
          ...info,
          uniqueCode,
          shippingNumber: info.shippingNumber?.toString()?.split(',')?.[0],
        }));
      } else {
        return info;
      }
    });

    //uniqueCode가 중복되는 경우 제거
    shippingInfos = shippingInfos.filter(
      (info: any, index: number, self: any[]) =>
        index ===
        self.findIndex(
          (t: any) =>
            t.uniqueCode === info.uniqueCode &&
            t.orderNumber === info.orderNumber
        )
    );

    await updateAndHandleShippingInfo(shippingInfos);
  };

  const mallUploadShippingInfoManually = async (shippingInfos: any[]) => {
    // shippingNumber 내용 없으면 return
    if (!shippingInfos[0].shippingNumber) {
      alert('운송장번호를 입력 후 업로드 버튼을 눌러주세요.');
      return;
    }

    await updateAndHandleShippingInfo(shippingInfos);
  };

  const updateAndHandleShippingInfo = async (shippingInfos: any[]) => {
    try {
      const { successOrders, failedOrders } = (await updateShippingInfoToMall(
        shippingInfos
      )) ?? { successOrders: [], failedOrders: [] };
      await handleShippingInfoResponse(successOrders, failedOrders);
    } catch (error) {
      console.error('Error updating shipping info:', error);
      alert('업로드 중 오류가 발생했습니다.');
    }
  };

  const handleShippingInfoResponse = async (
    successOrders: FulfilledOrderResult[],
    failedOrders: FulfilledOrderResult[]
  ) => {
    if (isValid(failedOrders)) {
      const date = new Date();
      const year = String(date.getFullYear()).slice(-2);
      const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}.${String(date.getDate()).padStart(2, '0')}`;

      await createOrderExcel(
        oms,
        failedOrders as unknown as Order[],
        `${formattedDate} 운송장 등록 실패 건`,
        ['실패 사유', ...CANCEL_REQUESTED_ORDER_HEADERS],
        { failReason: '실패 사유', ...CANCEL_REQUESTED_ORDER_COLUMN_MAPPING }
      );
    }

    if (successOrders.length === 0) {
      return;
    }

    const fulfilledOrders = new Map(
      successOrders
        .map((order) => {
          const key = order.uniqueCode ?? order.orderNumber;
          if (key == null) {
            return null;
          }
          return [
            key,
            {
              shippingCompany: order.shippingCompany,
              shippingNumber: order.shippingNumber,
            },
          ] as const;
        })
        .filter(isNotNil)
    );

    const updatedOrders = oms.order.dispatchedOrders.map((order) => {
      const fulfilledOrder =
        fulfilledOrders.get(order.uniqueCode) ??
        (order.orderNumber != null
          ? fulfilledOrders.get(order.orderNumber)
          : null);
      if (fulfilledOrder == null) {
        return order;
      }

      return {
        ...order,
        shippingCompany: fulfilledOrder.shippingCompany,
        shippingNumber: fulfilledOrder.shippingNumber,
        orderStatus: OrderStatus.inDelivery,
      };
    });

    oms.order.dispatch.setDispatchedOrders(updatedOrders);
  };

  return {
    retailerUploadShippingInfo,
    supplierUploadShippingInfo,
    mallUploadShippingInfo,
    mallUploadShippingInfoManually,
    extractShippingInfo,
  };
};

export default useShippingUpload;
