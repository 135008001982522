import { autoPlacement, offset, size } from '@floating-ui/react';
import { useDialog } from 'src/design-system/hooks/useDialog';

interface FilterDialogOptions {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  reference: React.RefObject<HTMLElement>;
}

export function useTableFilterDialog({
  open,
  onOpenChange,
  reference,
}: FilterDialogOptions) {
  return useDialog({
    open,
    onOpenChange,
    reference,
    placement: 'bottom-start',
    middleware: [
      offset(10),
      autoPlacement({
        alignment: 'start',
        allowedPlacements: ['bottom-start', 'bottom-end'],
      }),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(0, availableHeight - 12)}px`,
          });
        },
      }),
    ],
    dismiss: { ancestorScroll: true },
  });
}
