export type SpreadsheetErrorType =
  | 'READ'
  | 'DECRYPT'
  | 'ENCODE'
  | 'ENCODE:TIMEOUT'
  | 'PARSE'
  | 'UTIL'
  | 'TIMEOUT'
  | 'UNKNOWN';

export class SpreadsheetError extends Error {
  cause?: unknown;

  constructor(
    message: string,
    readonly type: SpreadsheetErrorType,
    cause?: unknown
  ) {
    super(message);
    this.name = `SpreadsheetError:${type}`;
    this.cause = cause;
  }
}
