import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { uniqueByUniqueCode } from 'services/order/uniqueByUniqueCode';
import { OMSStore } from 'stores/OMSStore';
import {
  DispatchedOrder,
  isDispatchedOrder,
  ShoppingMall,
} from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { getShoppingMalls } from 'src/network/order/dispatchOrder';
import { PluginExecutionService } from '../plugin/PluginExecutionService';

export class DispatchOrderStore {
  shoppingMalls: ShoppingMall[] = [];

  dispatchedOrders: DispatchedOrder[] = [];

  lastDispatchedAts: Record<string, number | undefined> = {};

  rejectedShoppingMallNames: string[] = [];

  get isNotLinkedShoppingMall() {
    return this.shoppingMallNames.length === 0;
  }

  get lastestDispatchedAt() {
    const lastDispatchedAts = Object.values(this.lastDispatchedAts).filter(
      isNotNil
    );

    if (lastDispatchedAts.length === 0) {
      return null;
    }

    return Math.max(...lastDispatchedAts);
  }

  get shoppingMallNames() {
    return this.shoppingMalls.map((mall) => mall.name);
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      shoppingMalls: observable,
      dispatchedOrders: observable,
      lastDispatchedAts: observable,
      rejectedShoppingMallNames: observable,

      isNotLinkedShoppingMall: computed,
      lastestDispatchedAt: computed,
      shoppingMallNames: computed,

      init: action.bound,
      loadShoppingMalls: action.bound,
      pushDispatchedOrders: action.bound,
      removeDispatchedOrdersByUniqueCodes: action.bound,
      setDispatchedOrders: action.bound,
      pushRejectedShoppingMallName: action.bound,
      removeRejectedShoppingMallName: action.bound,
      updateLastDispatchedAt: action.bound,
    });
  }

  async init() {
    await this.loadShoppingMalls();
  }

  async loadShoppingMalls() {
    const response = await getShoppingMalls();

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.shoppingMalls = response.data;
    });
  }

  pushRejectedShoppingMallName(shoppingMallName: string) {
    if (this.rejectedShoppingMallNames.includes(shoppingMallName)) {
      return;
    }

    this.rejectedShoppingMallNames = [
      ...this.rejectedShoppingMallNames,
      shoppingMallName,
    ];
  }

  removeRejectedShoppingMallName(shoppingMallNames: string[]) {
    const nameSet = new Set(this.rejectedShoppingMallNames);
    shoppingMallNames.forEach((name) => {
      nameSet.delete(name);
    });
    this.rejectedShoppingMallNames = Array.from(nameSet);
  }

  async updateLastDispatchedAt(shoppingMallName: string, date: number) {
    this.lastDispatchedAts = {
      ...this.lastDispatchedAts,
      [shoppingMallName]: date,
    };
  }

  setDispatchedOrders(orders: DispatchedOrder[]) {
    this.dispatchedOrders = orders;
  }

  pushDispatchedOrders(orders: DispatchedOrder[]) {
    this.dispatchedOrders = uniqueByUniqueCode([
      ...orders,
      ...this.dispatchedOrders,
    ]);
  }

  removeDispatchedOrdersByUniqueCodes(uniqueCodes: string[]) {
    const uniqueCodeSet = new Set(uniqueCodes);
    const filteredOrders = this.dispatchedOrders.filter(
      (order) => !uniqueCodeSet.has(order.uniqueCode)
    );
    this.dispatchedOrders = filteredOrders;
  }

  async transformOrders(orders: DispatchedOrder[]): Promise<DispatchedOrder[]> {
    const plugins = this.oms.plugin.getPlugins(this.oms.user.dispatchPlugins);
    const executionService = new PluginExecutionService(
      this.oms,
      plugins,
      'dispatch'
    );

    const transformedOrders = await executionService.execute(orders);
    const dispatchedOrders = transformedOrders.filter(isDispatchedOrder);
    const uniquedOrders = uniqueByUniqueCode(dispatchedOrders);

    return uniquedOrders;
  }
}
