import ErrorReporter from 'src/third-parties/ErrorReporter';
import { ExcelFile } from '../interface';
import { readExcel } from './partials/readExcel';
import { readExcelV2 } from './partials/readExcel-v2';

export async function readExcelMigration(
  file: File
): Promise<ExcelFile | null> {
  try {
    const result = await readExcelV2(file);
    if (result != null) {
      return result;
    }

    ErrorReporter.addBreadcrumb({
      message: 'READ_EXCEL v2 failed',
      extra: {
        errorTag: 'READ_EXCEL:MIGRATION:FAILED',
      },
    });

    return await readExcel(file);
  } catch (error) {
    ErrorReporter.captureError(error, {
      extra: {
        errorTag: 'READ_EXCEL',
      },
    });

    return null;
  }
}
