import saveAs from 'file-saver';
import JSZip from 'jszip';
import { NormalizedOrder, Supplier } from '@sweep/contract';
import { formatDate } from '@sweep/utils';
import {
  createOrderExcel,
  createOrderExcelBuffer,
} from 'src/services/file/excel/create';
import { divideBySupplier } from 'src/services/order/divideBySupplier';
import { OMSStore } from '../OMSStore';

export class FileOrderStore {
  constructor(private oms: OMSStore) {}

  downloadRetailerPurchaseOrder = async (
    orders: NormalizedOrder[],
    filename: string
  ) => {
    const { ordersBySupplier, unseparatedOrders } = divideBySupplier(orders);

    const files: { name: string; buffer: Promise<ArrayBuffer | null> }[] = [];

    const today = new Date();
    this.oms.supplier.suppliers.forEach((supplier) => {
      const supplierOrders = ordersBySupplier[supplier._id] ?? [];
      if (supplierOrders.length === 0) {
        return;
      }

      const { fileFormat, dateFormat } = this.getFileFormatting(supplier);
      const supplierFilename = fileFormat
        .replace('{date}', formatDate(today, dateFormat))
        .replace('{supplierName}', supplier.name)
        .replace('{name}', this.oms.user.name);

      const headers = supplier.header ?? [];
      const columnMapping = supplier.columnMapping ?? {};
      const customizedSettings = supplier.customizedSettings ?? {};
      const buffer = createOrderExcelBuffer(
        this.oms,
        supplierOrders,
        supplierFilename,
        headers,
        columnMapping,
        customizedSettings
      );
      files.push({ name: supplierFilename, buffer });
    });

    if (unseparatedOrders.length > 0) {
      const failedOrdersFileName = formatDate(
        today,
        'yy.MM.dd 발주 분리 실패주문'
      );

      const buffer = createOrderExcelBuffer(
        this.oms,
        unseparatedOrders,
        failedOrdersFileName,
        this.oms.user.excelHeaders,
        this.oms.user.excelColumnMapping
      );

      files.push({
        name: failedOrdersFileName,
        buffer,
      });
    }

    const zip = new JSZip();
    const bufferPromise = files.map((file) => file.buffer);
    const buffers = await Promise.all(bufferPromise);
    buffers.forEach((buffer, index) => {
      if (buffer == null) {
        return;
      }
      zip.file(`${files[index].name}.xlsx`, buffer);
    });

    const zipContent = await zip.generateAsync({ type: 'blob' });
    saveAs(zipContent, `${filename}.zip`);

    if (unseparatedOrders.length > 0) {
      alert('분류되지 않은 주문이 있습니다. 해당 상품들을 분류해주세요.');
    }
  };

  private getFileFormatting = (
    supplier: Supplier
  ): { fileFormat: string; dateFormat: string } => {
    const DEFAULT_FILE_FORMAT = '{date} {supplierName} 발주서';
    const DEFAULT_DATE_FORMAT = 'yy.MM.dd';

    const supplierSetting = supplier.settings?.fileFormatting;
    if (supplierSetting != null) {
      return {
        fileFormat: supplierSetting.fileFormat,
        dateFormat: supplierSetting.dateFormat ?? DEFAULT_DATE_FORMAT,
      };
    }

    const userSetting = this.oms.user.setting?.supplierFileFormatting;
    if (userSetting != null) {
      return {
        fileFormat: userSetting.fileFormat,
        dateFormat: userSetting.dateFormat ?? DEFAULT_DATE_FORMAT,
      };
    }

    return {
      fileFormat: DEFAULT_FILE_FORMAT,
      dateFormat: DEFAULT_DATE_FORMAT,
    };
  };

  downloadSupplierPurchaseOrder = async (
    orders: NormalizedOrder[],
    filename: string
  ) => {
    createOrderExcel(
      this.oms,
      orders,
      filename,
      this.oms.user.excelHeaders,
      this.oms.user.excelColumnMapping
    );
  };
}
