import deleteTextGray from '../../icons/deleteTextGray.png';

// components
const SupplierHeaderComponents = ({ supplier, handleSupplierInfoChange }) => {
  const labelComponent = ({ children, htmlFor }) => (
    <label
      htmlFor={htmlFor}
      className="font-pretendard text-base font-medium not-italic leading-[21px_] text-[color:var(--Gray-600,#343D4B)]"
    >
      {children}
    </label>
  );

  const mainInputComponent = ({
    id,
    type,
    name,
    value,
    onChange,
    required = false,
  }) => (
    <div className="relative">
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="mt-[8px] block h-[40px] w-full rounded-lg border border-[color:var(--Gray-300,#CED7E0)] bg-white px-[16px] text-sm
        font-medium not-italic text-[color:var(--Gray-600,#343D4B)]
        shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
        required={required}
      />
      {value && (
        <div
          className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-[16px]"
          onClick={() =>
            handleSupplierInfoChange({
              target: { name: name, value: '' },
            })
          }
        >
          <img src={deleteTextGray} alt="Close" className="size-[17px]" />
        </div>
      )}
    </div>
  );

  return (
    <div className="grid grid-cols-3 gap-6">
      <div>
        {labelComponent({ children: '회사명', htmlFor: 'name' })}
        {mainInputComponent({
          id: 'name',
          type: 'text',
          name: 'name',
          value: supplier.name,
          onChange: handleSupplierInfoChange,
        })}
      </div>
      <div>
        {labelComponent({ children: '메일 주소', htmlFor: 'email' })}
        {mainInputComponent({
          id: 'email',
          type: 'email',
          name: 'email',
          value: supplier.email,
          onChange: handleSupplierInfoChange,
        })}
      </div>

      <div>
        {labelComponent({ children: '전화번호', htmlFor: 'phone' })}
        {mainInputComponent({
          id: 'phone',
          type: 'tel',
          name: 'phone',
          value: supplier.phone,
          onChange: handleSupplierInfoChange,
        })}
      </div>
    </div>
  );
};

export default SupplierHeaderComponents;
