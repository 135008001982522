import { useEffect, useState } from 'react';
import { cva } from '@sweep/tailwind';
import { isNotNil } from '@sweep/utils';

interface SearchableSelectProps {
  options: string[];
  value?: string;
  onChange: (value: string) => void;
  onCreate: (value: string) => void;
  name?: string;
  required?: boolean;
  className?: string;
}

const SearchableSelect = ({
  options,
  value,
  onChange,
  onCreate,
  required,
  className,
  name,
}: SearchableSelectProps) => {
  const [inputValue, setInputValue] = useState(value ?? '');
  const [showOptions, setShowOptions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  const filteredOptions = options
    .filter((item) => item.toLowerCase().includes(inputValue.toLowerCase()))
    .sort((a, b) => a.length - b.length);

  const handleValue = (value: string) => {
    setInputValue(value);
    onChange(value);
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    setSelectedIndex(0);
    setShowOptions(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        const selectedValue = filteredOptions.at(selectedIndex);
        if (selectedValue != null) {
          handleValue(selectedValue);
        }
        setShowOptions(false);
        break;

      case 'ArrowUp':
        event.preventDefault();
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1);
        }
        break;

      case 'ArrowDown':
        event.preventDefault();
        if (selectedIndex < filteredOptions.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        }
        break;
      default:
    }
  };

  const handleSuggestionClick = (value: string) => {
    handleValue(value);
    setShowOptions(false);
  };

  const handleBlur = () => {
    setShowOptions(false);
    if (inputValue === '') {
      handleValue('');
      return;
    }

    if (value != null) {
      handleValue(value);
      return;
    }

    handleValue('');
    setInputValue('');
  };

  const placeholder = [name, showOptions ? '검색' : '선택']
    .filter(isNotNil)
    .join(' ');

  return (
    <div className={containerClass({ className })}>
      <input
        type="text"
        value={inputValue}
        onChange={(event) => handleInputChange(event.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onFocus={(e) => {
          e.target.select();
          setShowOptions(true);
        }}
        className={inputClass({
          warning: required && (inputValue == null || inputValue === ''),
        })}
        placeholder={placeholder}
      />
      {showOptions && (
        <ul className={optionContainerClass()}>
          {filteredOptions.length > 0 &&
            filteredOptions.map((option, index) => (
              <li
                key={option}
                className={optionClass({ active: index === selectedIndex })}
                onMouseDown={() => handleSuggestionClick(option)}
              >
                {option}
              </li>
            ))}
          {name && (
            <li
              className={optionCreateClass()}
              onMouseDown={() => onCreate(inputValue)}
            >
              {name ? `+ ${name} 추가하기` : '+ 추가하기'}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

const containerClass = cva('relative');

const inputClass = cva(
  [
    'text-medium-s h-10 w-full rounded-lg border border-gray-300 px-[16px] py-2 text-black shadow-sm placeholder:text-gray-500',
    'focus:border-blue-400 focus:outline-none',
  ],
  {
    variants: {
      warning: {
        true: 'border-red-500',
      },
    },
  }
);

const optionContainerClass = cva(
  'scrollable-list absolute inset-x-0 top-full z-50 mt-1 rounded-lg border bg-white'
);

const optionClass = cva(
  'text-medium-s cursor-pointer rounded-lg p-2 hover:bg-gray-100',
  {
    variants: {
      active: {
        true: 'bg-gray-100',
      },
    },
  }
);

const optionCreateClass = cva(
  'cursor-pointer rounded-lg p-2 font-bold text-blue-500 hover:bg-gray-100'
);

export default SearchableSelect;
