import {
  GET_EXCEL_SETTING_URL,
  GetExcelSettingResponse,
  UPDATE_USER_SETTING_URL,
  UpdateUserSettingDTO,
  UpdateUserSettingResponse,
  User,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import api, { APIResponse } from './api';

interface PreRegisterInfo {
  isFromOutbound?: boolean;
  name: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  monthlyOrderCount: string;
  memo: string;
}

export async function preRegister(preRegisterInfo: PreRegisterInfo) {
  return await api.post('/user/pre-register', {
    preRegisterInfo,
  });
}

interface LoginResponse {
  data?: {
    token: string;
    userId: string;
  };
  message?: string;
}

export async function login(email: string, password: string) {
  const response = await api.post<LoginResponse>(
    '/user/login',
    { loginData: { email, password } },
    { validateStatus: (status) => status === 200 || status === 400 }
  );

  return response.data;
}

interface GetUserResponse {
  data?: User;
}

export async function getUser() {
  const response = await api.get<GetUserResponse>('/user');

  return response.data;
}

export async function getCombinedExcelSetting() {
  const response = await api.get<APIResponse<GetExcelSettingResponse>>(
    GET_EXCEL_SETTING_URL
  );

  return response.data;
}

export async function updateUser(updateDTO: Partial<User>) {
  return tryCatch(async () => {
    const response = await api.put<APIResponse<User>>('/user', updateDTO);

    return response.data;
  });
}

export async function updateUserSetting(
  updateUserSettingDTO: UpdateUserSettingDTO
) {
  return tryCatch(async () => {
    const response = await api.put<APIResponse<UpdateUserSettingResponse>>(
      UPDATE_USER_SETTING_URL,
      updateUserSettingDTO
    );

    return response.data;
  });
}
