import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { toast } from 'sonner';
import { DispatchedOrder } from '@sweep/contract';
import { searchDispatchedOrders } from 'src/network/order/dispatchOrder';
import { OMSStore } from 'src/stores/OMSStore';
import { isEmptyString } from 'src/utils/string';

export class OrderDispatchSearchScreenStore {
  shoppingMall: string | null = null;
  orderNumber: string | null = null;
  name: string | null = null;
  phoneNumber: string | null = null;

  orders: DispatchedOrder[] | null = null;

  get searchDisabled() {
    return (
      this.orderNumber == null && this.phoneNumber == null && this.name == null
    );
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      shoppingMall: observable,
      orderNumber: observable,
      name: observable,
      phoneNumber: observable,

      orders: observable,

      searchDisabled: computed,

      setShoppingMall: action.bound,
      setOrderNumber: action.bound,
      setName: action.bound,
      setPhoneNumber: action.bound,
    });
  }

  setShoppingMall(shoppingMall: string | null) {
    this.shoppingMall = shoppingMall;
  }

  setOrderNumber(orderNumber: string) {
    this.orderNumber = isEmptyString(orderNumber) ? null : orderNumber;
  }

  setName(name: string) {
    this.name = isEmptyString(name) ? null : name;
  }

  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = isEmptyString(phoneNumber) ? null : phoneNumber;
  }

  async search() {
    const phoneNumber = this.phoneNumber;
    const hyphenRemovedPhoneNumber =
      phoneNumber != null ? removeHyphen(phoneNumber) : null;

    const orders = await this.oms.loading.batch(async () => {
      const response = await searchDispatchedOrders({
        shoppingMall: this.shoppingMall ?? undefined,
        orderNumber: this.orderNumber ?? undefined,
        contactNumber: hyphenRemovedPhoneNumber ?? undefined,
        name: this.name ?? undefined,
      });

      if (response == null || response.success === false) {
        toast.error('주문 조회에 실패했습니다.');
        return [];
      }

      const orders = response.data;
      if (orders.length === 0) {
        return [];
      }

      const transformedOrders =
        await this.oms.order.dispatch.transformOrders(orders);

      return transformedOrders;
    });

    runInAction(() => {
      this.orders = orders;
    });
  }
}

function removeHyphen(value: string) {
  return value.replace(/-/g, '');
}
