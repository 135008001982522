import { useVirtualizer } from '@tanstack/react-virtual';
import { useCallback, useRef, useState } from 'react';
import { cva } from '@sweep/tailwind';
import DefaultTableCell from './DefaultTableCell';
import DefaultTableHeaderCell from './DefaultTableHeaderCell';
import { ColumnDef } from './interface';
import TableCellRender from './TableCellRender';
import { TableContextProvider } from './TableContext';
import TableHeaderCellRender from './TableHeaderCellRender';

export interface TableProps<T> {
  items: T[];
  columns: ColumnDef<T>[];
  getKey: (item: T) => string;
  className?: string;
}

export function Table<T>({ items, columns, getKey, className }: TableProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [columnWidths, setColumnWidths] = useState<Record<number, number>>({});
  const handleWidthChange = useCallback((index: number, width: number) => {
    setColumnWidths((prev) => ({
      ...prev,
      [index]: Math.max(prev[index] ?? 0, width),
    }));
  }, []);

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 37,
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  return (
    <TableContextProvider
      value={{ columnWidths, onWidthChange: handleWidthChange }}
    >
      <div ref={containerRef} className={tableContainer({ className })}>
        <div className={table()}>
          <div className={rowClass({ type: 'header' })}>
            {columns
              .map((column) => column.header)
              .map((header, index) => (
                <TableHeaderCellRender
                  key={index}
                  header={header}
                  context={{ lastColumn: index === columns.length - 1 }}
                  index={index}
                />
              ))}
          </div>
          <div
            style={{
              height: `${virtualizer.getTotalSize()}px`,
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100%',
                top: 0,
                left: 0,
                transform: `translateY(${virtualItems.at(0)?.start ?? 0}px)`,
              }}
            >
              {virtualItems.map((virtualItem) => (
                <div
                  key={getKey(items[virtualItem.index])}
                  // NOTE(@이지원): key가 있을 때, virtualizer.measureElement를 바로 전달하면 totalHeight 계산에서 오류가 생김
                  ref={(ref) => virtualizer.measureElement(ref)}
                  className={rowClass()}
                  data-index={virtualItem.index}
                >
                  {columns.map((column, columnIndex) => (
                    <TableCellRender
                      key={`${virtualItem.index}-${columnIndex}`}
                      row={items[virtualItem.index]}
                      column={column}
                      context={{
                        row: items[virtualItem.index],
                        columnIndex: columnIndex,
                        lastRow: virtualItem.index === items.length - 1,
                        lastColumn: columnIndex === columns.length - 1,
                      }}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </TableContextProvider>
  );
}

const tableContainer = cva('relative overflow-auto border border-gray-200');

const table = cva('relative w-max');

const rowClass = cva('min-h-36px flex w-full', {
  variants: {
    type: {
      header: 'sticky top-0 left-0  z-[1]',
      row: '',
    },
  },
});

Table.HeaderCell = DefaultTableHeaderCell;
Table.Cell = DefaultTableCell;
