import { FloatingOverlay, offset } from '@floating-ui/react';
import { HTMLAttributes, ReactNode, useRef } from 'react';
import { mergeProps } from 'react-aria';
import { cva } from '@sweep/tailwind';
import { useRefs } from '@sweep/utils';
import { useDialog } from '../../hooks/useDialog';
import { ModalContextProvider } from './context/ModalContext';
import { ModalClose } from './ModalClose';
import { ModalDescription } from './ModalDescription';
import { ModalTitle } from './ModalTitle';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  size?: 'medium' | 'small';
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  dismiss?: {
    outsidePress?: boolean;
    escapeKey?: boolean;
  };
}

export function Modal({ open, ...rest }: ModalProps) {
  if (!open) {
    return null;
  }

  return (
    <>
      <FloatingOverlay lockScroll className="z-dialog bg-[rgb(0,0,0)]/40" />
      <InnerModal open={open} {...rest} />
    </>
  );
}

function InnerModal({
  open,
  onClose,
  size = 'medium',
  className,
  style,
  dismiss,
  children,
  ...rest
}: ModalProps) {
  const { floatingStyles, getFloatingProps, refs } = useDialog({
    open,
    onOpenChange: () => open && onClose(),
    placement: 'top',
    middleware: [
      offset(({ rects }) => {
        return -rects.floating.height / 2;
      }),
    ],
    reference: {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    },
    dismiss: {
      outsidePress: dismiss?.outsidePress ?? true,
      escapeKey: dismiss?.escapeKey ?? true,
    },
  });

  const modalRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRefs([refs.setFloating, modalRef]);

  return (
    <ModalContextProvider value={{ open, onClose }}>
      <div
        ref={innerRef}
        className={container({ size, className })}
        style={{ ...floatingStyles, ...style }}
        {...mergeProps(getFloatingProps(), rest)}
      >
        {children}
      </div>
    </ModalContextProvider>
  );
}

Modal.Title = ModalTitle;
Modal.Description = ModalDescription;
Modal.Close = ModalClose;

const container = cva(
  'z-dialog flex max-h-[600px] flex-col rounded-[16px] bg-white px-[32px] pb-[20px] pt-[32px] shadow-md',
  {
    variants: {
      size: {
        medium: 'w-590px',
        small: 'w-452px',
      },
    },
  }
);
