import { action, makeObservable, observable } from 'mobx';

export type Sort = {
  key: string | null;
  type: SortType;
};

export type SortType = 'ASC' | 'DESC' | null;

export class TableFilterStore {
  sort: Sort = { key: null, type: null };
  removedFields: Record<string, string[] | undefined> = {};

  constructor() {
    makeObservable(this, {
      sort: observable,
      removedFields: observable,

      setSort: action.bound,
      setRemovedFields: action.bound,
      reset: action.bound,
    });
  }

  setSort(key: string | null, type: SortType) {
    this.sort = { key, type };
  }

  setRemovedFields(key: string, values: string[]) {
    this.removedFields = { ...this.removedFields, [key]: values };
  }

  reset() {
    this.sort = { key: null, type: null };
    this.removedFields = {};
  }
}
