import { Control } from 'stores/order/NewOrderStore';
import { CompositionMatchingStage } from '../../CompositionMatchingPlugin';
import { ProductMatching } from '../../interface';
import NormalizeMatchedCMForm from './NormalizeMatchedCMForm';
import PreDivideMatchedCMForm from './PreDivideMatchedCMForm';

export function openMatchedCMForm(
  render: (control: Control) => void,
  matchings: ProductMatching[],
  stage: CompositionMatchingStage
): Promise<ProductMatching[]> {
  const MatchedCMForm =
    stage === 'normalize' ? NormalizeMatchedCMForm : PreDivideMatchedCMForm;

  return new Promise((resolve) => {
    render(({ close }) => (
      <MatchedCMForm
        matchings={matchings}
        onSubmit={(value) => {
          resolve(value);
          close();
        }}
      />
    ));
  });
}
