import saveAs from 'file-saver';
import { Order } from '@sweep/contract';
import { ColumnMapping } from 'src/models/ColumnMapping';
import { OMSStore } from 'src/stores/OMSStore';
import { createOrderExcelBuffer } from './createOrderExcelBuffer';

const EXCEL_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export async function createOrderExcel(
  oms: OMSStore,
  orders: Order[],
  fileName: string,
  headers: string[],
  columnMapping: ColumnMapping
) {
  if (orders.length === 0) {
    return;
  }

  const buffer = await createOrderExcelBuffer(
    oms,
    orders,
    fileName,
    headers,
    columnMapping
  );

  if (buffer == null) {
    return;
  }

  const blob = new Blob([buffer], { type: EXCEL_MIME_TYPE });
  saveAs(blob, `${fileName}.xlsx`);
}
