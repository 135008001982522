import { IconXDeleteModal } from 'icons/index';
import { observer } from 'mobx-react-lite';
import { cva } from '@sweep/tailwind';
import { If } from '@sweep/utils';
import { Modal } from 'src/design-system/components/Modal';
import { amplitude } from 'src/third-parties/amplitude';
import { DispatchModalStore } from '../../store/DispatchModalStore';
import DispatchFailureResult from './DispatchFailureResult';
import DispatchLoading from './DispatchLoading';
import DispatchSuccessResult from './DispatchSuccessResult';
import { LoadingLottie } from './LoadingLottie';

interface DispatchModalProps {
  open: boolean;
  onClose: () => void;
  store: DispatchModalStore;
}

function DispatchModal({ open, onClose, store }: DispatchModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: false, escapeKey: false }}
      className={modal({ loading: store.isDispatchLoading })}
    >
      <div className="flex items-center justify-between">
        <div className="flex h-[48px] items-center">
          <p className="text-extrabold-l">주문 수집하기</p>
          {store.isTransformLoading && <LoadingLottie />}
        </div>
        <button
          onClick={() => {
            amplitude.track('Click Close-order-dispatch');
            onClose();
          }}
        >
          <IconXDeleteModal className="text-gray-700" />
        </button>
      </div>
      <If is={store.isDispatchLoading}>
        <DispatchLoading store={store} />
      </If>
      <If is={!store.isDispatchLoading && !store.isSuccessful}>
        <DispatchFailureResult store={store} />
      </If>
      <If is={!store.isDispatchLoading && store.isSuccessful}>
        <DispatchSuccessResult store={store} onClose={onClose} />
      </If>
    </Modal>
  );
}

const modal = cva('px-32px pt-32px max-h-[612px]', {
  variants: {
    loading: {
      true: 'pb-40px',
      false: 'pb-20px',
    },
  },
});

export default observer(DispatchModal);
