import ErrorReporter from 'src/third-parties/ErrorReporter';
import { MultiSheetExcelFile } from '../interface';
import { readExcel } from './partials/readExcel';
import { readExcelV2MultiSheet } from './partials/readExcel-v2-multisheet';

export async function readExcelMigrationMultiSheet(
  file: File,
  options?: {
    includeRawData?: boolean;
  }
): Promise<MultiSheetExcelFile | null> {
  try {
    const result = await readExcelV2MultiSheet(file, options);
    if (result != null) {
      return result;
    }

    ErrorReporter.addBreadcrumb({
      message: 'READ_EXCEL v2 failed',
      extra: {
        errorTag: 'READ_EXCEL:MIGRATION:FAILED',
      },
    });

    const deprecatedParserResult = await readExcel(file);
    if (deprecatedParserResult != null) {
      return {
        name: deprecatedParserResult.name,
        extension: deprecatedParserResult.extension,
        data: [deprecatedParserResult.data],
      };
    }

    return null;
  } catch (error) {
    ErrorReporter.captureError(error, {
      extra: {
        errorTag: 'READ_EXCEL',
      },
    });

    return null;
  }
}
