import { Button } from 'design-system/components/Button';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { SwitchCase } from '@sweep/utils';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { ProductMatching } from '../../interface';
import CMProgress from '../common/CMProgress';
import MatchedCMEditForm from './MatchedCMEditForm';
import NormalizeMatchedCMConfirmForm from './NormalizeMatchedCMConfirmForm';

interface NormalizeMatchedCMFormProps {
  matchings: ProductMatching[];
  onSubmit: (value: ProductMatching[]) => void;
}

type Status = 'confirm' | 'edit';

function NormalizeMatchedCMForm({
  matchings: givenMatching,
  onSubmit,
}: NormalizeMatchedCMFormProps) {
  const oms = useOMSStore();
  const companyType = oms.user.companyType;

  const [matchings, setMatchings] = useState(givenMatching);
  const [status, setStatus] = useState<Status>('confirm');

  const onEdit = () => setStatus('edit');

  const onEditSubmit = (value: ProductMatching[]) => {
    setMatchings(value);
    setStatus('confirm');
  };

  const onConfirmSubmit = () => {
    onSubmit(matchings);
  };

  return (
    <div className="bg-gray-100 px-10 pb-[40px] pt-[20px]">
      <div className="h-42px flex items-center justify-between">
        <p className="text-extrabold-l flex-1">발주 진행하기</p>
        <Button
          disabled={status === 'edit'}
          variant="line"
          color="gray"
          onClick={onConfirmSubmit}
        >
          완료
        </Button>
      </div>
      <div className="h-[40px]" />
      <CMProgress>
        <CMProgress.Title index={1}>파트너사 매칭</CMProgress.Title>
        <CMProgress.NextIcon />
        <CMProgress.Title index={2} active>
          상품 구성 매칭
        </CMProgress.Title>
        <CMProgress.Description>
          이번에 사용된 상품 매칭을 확인해주세요.
        </CMProgress.Description>
      </CMProgress>
      <div className="h-[20px]" />
      <div className="items-center rounded-[8px] bg-white p-5 shadow-sm">
        <SwitchCase
          value={status}
          caseBy={{
            confirm: (
              <NormalizeMatchedCMConfirmForm
                matchings={matchings}
                onEdit={onEdit}
                onSubmit={onConfirmSubmit}
              />
            ),
            edit: (
              <MatchedCMEditForm
                matchings={matchings}
                onSubmit={onEditSubmit}
              />
            ),
          }}
        />
      </div>
    </div>
  );
}

export default observer(NormalizeMatchedCMForm);
