export async function isHTMLFile(file: File) {
  return new Promise<boolean>((resolve) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const content = event.target?.result;
      if (content == null) {
        resolve(false);
        return;
      }
      const stringContent = content
        .toString()
        .trim()
        .split(/\r?\n/, 10)
        .join(' ');

      const htmlStartPatterns = [
        /^<!DOCTYPE html/i,
        /^<html/i,
        /^<head/i,
        /^<meta/i,
        /^<title/i,
        /^<body/i,
        /^<!--/,
        /^<div/i,
        /^<span/i,
        /^<p/i,
        /^<h1/i,
      ];

      for (const pattern of htmlStartPatterns) {
        if (pattern.test(stringContent)) {
          resolve(true);
          return;
        }
      }

      resolve(false);
    };

    reader.readAsText(file);
  });
}
