import { PartnerFormModal } from 'forms/partner/PartnerFormModal';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { overlay } from 'overlay-kit';
import Button from '../../../components/buttons/Button';
import OMSContainer from '../../../components/OMSContainer';
import PartnerList from '../components/PartnerList';

const PartnerManagementScreen = observer(() => {
  const oms = useOMSStore();
  const handleRegisterClick = () => {
    overlay.open(({ isOpen, close }) => (
      <PartnerFormModal
        type="register"
        open={isOpen}
        onSubmit={async (partner) => {
          if (partner == null) {
            close();
            return;
          }

          const matchingLength = partner?.matchings?.length ?? 0;
          if (matchingLength === 0) {
            alert('판매처의 엑셀 양식을 올려주세요.');
            return;
          }

          oms.partner.register(partner);
          alert('판매처 등록이 완료되었어요.');

          close();
        }}
      >
        <PartnerFormModal.InfoInput />
        <hr />
        <PartnerFormModal.FileUpload />
        <hr />
        <PartnerFormModal.ColumnMatchingInput />
      </PartnerFormModal>
    ));
  };

  const handleEditClick = (partnerId: string) => {
    const partner = oms.partner.getPartnerById(partnerId);
    if (partner == null) {
      return;
    }

    overlay.open(({ isOpen, close }) => (
      <PartnerFormModal
        type="update"
        open={isOpen}
        defaultPartner={partner}
        onSubmit={(partner) => {
          if (partner != null) {
            oms.partner.update(partnerId, partner);
            alert('판매처 정보 수정이 완료되었어요.');
          }

          close();
        }}
      >
        <PartnerFormModal.InfoInput />
        <hr />
        <PartnerFormModal.FileUpload />
        <hr />
        <PartnerFormModal.ColumnMatchingInput />
      </PartnerFormModal>
    ));
  };

  const handleDeleteClick = async (partnerId: string) => {
    await oms.partner.delete(partnerId);
    alert('판매처가 삭제되었어요.');
  };

  return (
    <OMSContainer>
      <div className="flex justify-between">
        <h1 className="font-pretendard text-[24px] font-bold leading-[30px] text-[color:var(--Gray-600,#343D4B)]">
          판매처 등록 및 관리
        </h1>
        <Button name={`+ 판매처 추가`} onClick={handleRegisterClick} />
      </div>

      <div className="mt-[20px]">
        <PartnerList
          partners={oms.partner.partners}
          onClick={(partnerId) => handleEditClick(partnerId)}
          onDelete={(partnerId) => handleDeleteClick(partnerId)}
        />
      </div>
    </OMSContainer>
  );
});

export default PartnerManagementScreen;
