import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { cva } from '@sweep/tailwind';
import { If } from '@sweep/utils';
import { IconArrowRightSmall, IconCheck } from 'src/icons';
import { ProductFormStore } from '../../ProductFormStore';

interface SettingInputProps {
  store: ProductFormStore;
  className?: string;
}

function ProductSettingInput({ store, className }: SettingInputProps) {
  const [open, setOpen] = useState(
    store.useSupplierByOption || store.useSupplierBySalesChannel
  );
  const handleOpenClick = () => setOpen(!open);

  return (
    <div className={container({ className })}>
      <button className="flex items-center" onClick={handleOpenClick}>
        <p className="text-semibold-xs text-gray-400">세부설정</p>
        <IconArrowRightSmall className="text-gray-400" />
      </button>
      <If is={open}>
        <button
          className={button({
            active: store.useSupplierByOption,
            color: 'blue',
          })}
          onClick={store.toggleUseSupplierByOption}
        >
          <IconCheck />
          <p className="text-semibold-xs">옵션별 공급사 지정</p>
        </button>
        <button
          className={button({
            active: store.useSupplierBySalesChannel,
            color: 'green',
          })}
          onClick={store.toggleUseSupplierBySalesChannel}
        >
          <IconCheck />
          <p className="text-semibold-xs">판매처별 공급사 지정</p>
        </button>
      </If>
    </div>
  );
}

const container = cva('gap-6px h-24px flex shrink-0');

const button = cva(
  'h-24px gap-4px rounded-6px px-8px flex items-center border',
  {
    variants: {
      active: {
        true: '',
        false: 'border-gray-400 text-gray-400',
      },
      color: {
        blue: '',
        green: '',
      },
    },
    compoundVariants: [
      {
        active: true,
        color: 'blue',
        className: 'border-blue-500 text-blue-500',
      },
      {
        active: true,
        color: 'green',
        className: 'border-green-500 text-green-500',
      },
    ],
  }
);

export default observer(ProductSettingInput);
