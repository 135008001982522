import { ExcelData } from 'services/file/interface';
import { findHeaderIndex } from './findHeaderIndex';

export function sliceUntilHeader(excelData: ExcelData): ExcelData {
  const headerIndex = findHeaderIndex(excelData);
  if (headerIndex === -1) {
    return excelData;
  }
  return excelData.slice(headerIndex);
}
