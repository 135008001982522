import { Order } from '@sweep/contract';

export function getRemainedOrdersByUniqueCode<T extends Order>(
  orders: T[],
  uniqueCodes: string[]
): T[] {
  const uniqueCodeSet = new Set(uniqueCodes);

  return orders.filter((order) => !uniqueCodeSet.has(order.uniqueCode));
}
