import { Nullable } from '@sweep/utils';
import { NumberSchema } from '../schema';

interface JsonNumberInputProps {
  schema: NumberSchema;
  value: Nullable<number>;
  onChange: (value: number) => void;
}

export function JsonNumberInput({
  schema,
  value,
  onChange,
}: JsonNumberInputProps) {
  return (
    <div>
      <p>{schema.description}</p>
      <input
        type="number"
        value={value ?? 0}
        onChange={(event) => onChange(Number(event.target.value))}
      />
    </div>
  );
}
