import { action, makeObservable, observable, runInAction } from 'mobx';
import { createPartnerPlugin, deletePartnerPlugin } from 'network/partner';
import {
  createDispatchPlugin,
  createMergeDispatchPlugin,
  CreatePluginDTO,
  createUserPlugin,
  deleteDispatchPlugin,
  deleteMergeDispatchPlugin,
  deleteUserPlugin,
  getPlugins,
  updatePlugin,
} from 'network/plugin';
import { createSupplierPlugin, deleteSupplierPlugin } from 'network/supplier';
import { isNotNil } from '@sweep/utils';
import { Plugin } from './interface';

export class PlguinStore {
  plugins: Plugin[] = [];
  pluginMap = new Map<string, Plugin>();

  getPluginById(pluginId: string) {
    return this.pluginMap.get(pluginId);
  }

  getPlugins(pluginIds: string[]) {
    return pluginIds
      .map((pluginId) => this.pluginMap.get(pluginId))
      .filter(isNotNil);
  }

  constructor() {
    makeObservable(this, {
      plugins: observable,
      pluginMap: observable,

      init: action.bound,
      _loadPlugins: action.bound,

      update: action.bound,
      createUserPlugin: action.bound,
      deleteUserPlugin: action.bound,
      createPartnerPlugin: action.bound,
      deletePartnerPlugin: action.bound,
      createDispatchPlugin: action.bound,
      deleteDispatchPlugin: action.bound,
      createMergeDispatchPlugin: action.bound,
      deleteMergeDispatchPlugin: action.bound,

      _create: action.bound,
      _update: action.bound,
      _delete: action.bound,
    });
  }

  async init() {
    await this._loadPlugins();
  }

  async _loadPlugins() {
    const plugins = await getPlugins();
    runInAction(() => {
      this.plugins = plugins;
      this.pluginMap = new Map(plugins.map((plugin) => [plugin._id, plugin]));
    });
  }

  async update(pluginId: string, plugin: Partial<Plugin>) {
    this._update(pluginId, plugin);

    const response = await updatePlugin(pluginId, plugin);
    if (response.success === false) {
      // TODO(@이지원): undo update
    }
  }

  async createUserPlugin(createPluginDto: CreatePluginDTO) {
    const response = await createUserPlugin(createPluginDto);
    if (response.success === false) {
      return;
    }

    const { user, plugin } = response.data;

    this._create(plugin);

    return user.plugins;
  }

  async deleteUserPlugin(pluginId: string) {
    const response = await deleteUserPlugin(pluginId);
    if (response.success === false) {
      return;
    }

    this._delete(pluginId);
  }

  async createPartnerPlugin(
    partnerId: string,
    createPluginDTO: CreatePluginDTO
  ) {
    const response = await createPartnerPlugin(partnerId, createPluginDTO);
    if (response.success === false) {
      return;
    }

    const { partner, plugin } = response.data;

    this._create(plugin);

    return partner;
  }

  async deletePartnerPlugin(partnerId: string, pluginId: string) {
    const response = await deletePartnerPlugin(partnerId, pluginId);
    if (response.success === false) {
      return;
    }

    this._delete(pluginId);
  }

  async createDispatchPlugin(createPluginDTO: CreatePluginDTO) {
    const response = await createDispatchPlugin(createPluginDTO);
    if (response.success === false) {
      return;
    }

    const { user, plugin } = response.data;

    this._create(plugin);

    return user.dispatchPlugins;
  }

  async deleteDispatchPlugin(pluginId: string) {
    this._delete(pluginId);

    await deleteDispatchPlugin(pluginId);
  }

  async createMergeDispatchPlugin(createPluginDTO: CreatePluginDTO) {
    const response = await createMergeDispatchPlugin(createPluginDTO);
    if (response.success === false) {
      return;
    }

    const { user, plugin } = response.data;

    this._create(plugin);

    return user.mergeDispatchPlugins;
  }

  async deleteMergeDispatchPlugin(pluginId: string) {
    this._delete(pluginId);

    await deleteMergeDispatchPlugin(pluginId);
  }

  async createSupplierPlugin(
    supplierId: string,
    createPluginDTO: CreatePluginDTO
  ) {
    const response = await createSupplierPlugin(supplierId, createPluginDTO);
    if (response.success === false) {
      return;
    }

    const { supplier, plugin } = response.data;

    this._create(plugin);

    return supplier;
  }

  async deleteSupplierPlugin(supplierId: string, pluginId: string) {
    this._delete(pluginId);

    await deleteSupplierPlugin(supplierId, pluginId);
  }

  _create(plugin: Plugin) {
    this.plugins.push(plugin);
    this.pluginMap.set(plugin._id, plugin);
  }

  _update(pluginId: string, plugin: Partial<Plugin>) {
    const prevPlugin = this.pluginMap.get(pluginId);
    if (prevPlugin == null) {
      return;
    }

    const updatedPlugin = { ...prevPlugin, ...plugin };
    this.plugins = this.plugins.map((plugin) =>
      plugin._id === pluginId ? updatedPlugin : plugin
    );
    this.pluginMap.set(pluginId, updatedPlugin);
  }

  _delete(pluginId: string) {
    this.plugins = this.plugins.filter((plugin) => plugin._id !== pluginId);
    this.pluginMap.delete(pluginId);
  }
}
