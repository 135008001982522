import { useState } from 'react';
import { Tooltip } from 'src/design-system';
import AutocompleteByArray from '../../components/AutocompleteByArray';
import Button from '../../components/buttons/Button';

const OrderCombinationTypeSelector = ({
  condition,
  setCondition,
  setNewUnit,
  newCount,
  setNewCount,
  combinationType,
  handleTypeChange,
  handleAddCondition,
  handleDeleteCondition,
  handleUnitChange,
  handleCountChange,
  unitList,
  handleUnitQuantityChange,
  unitQuantity,
}) => {
  const [newCountString, setNewCountString] = useState(String(newCount));

  const handleNewCountString = (value) => {
    const intValue = parseInt(value);
    if (isNaN(intValue) || intValue < 0) {
      setNewCountString('');
      setNewCount(0);
      return;
    }
    setNewCountString(String(intValue));
    setNewCount(intValue);
  };

  return (
    <div className="my-6">
      <label
        htmlFor="unit"
        className="mt-4 block text-lg font-semibold text-gray-700"
      >
        함께 합칠 수 있는 기준은 아래와 같아요
      </label>

      <div className="my-2">
        <div className="flex items-center">
          <input
            type="radio"
            name="combinationType"
            value="maxCounts"
            className="mr-1"
            onChange={handleTypeChange}
            checked={combinationType === 'maxCounts'}
          />
          <span className="text-md mr-1 text-gray-600">구성 별 최대 개수</span>
          <Tooltip>
            <Tooltip.Trigger>
              <div className="w-16px h-16px flex-center text-medium-s flex rounded-full bg-gray-500 text-white">
                ?
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content>
              {
                '- 구성 별로 합배송할 수 있는 기준을 설정합니다.\n- 예) 30구를 기준으로 4세트까지 합배송 가능, 1.8L 구성의 경우 최대 2개까지 합배송 가능 등'
              }
            </Tooltip.Content>
          </Tooltip>
        </div>
        <div className="flex items-center py-2">
          <input
            type="radio"
            name="combinationType"
            value="maxUnits"
            className="mr-1"
            onChange={handleTypeChange}
            checked={combinationType === 'maxUnits'}
          />
          <span className="text-md mr-1 text-gray-600">최대 단위</span>
          <Tooltip>
            <Tooltip.Trigger>
              <div className="w-16px h-16px flex-center text-medium-s flex rounded-full bg-gray-500 text-white">
                ?
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content>
              {`- 총 무게나 부피 등으로 합배송 가능 기준을 설정합니다.\n- 예) 총 무게 기준 10kg까지 합배송 가능, 총 개수 기준 16개까지 합배송 가능 등`}
            </Tooltip.Content>
          </Tooltip>
        </div>
      </div>

      {combinationType === 'maxCounts' && (
        <div className="mb-6">
          {condition &&
            typeof condition === 'object' &&
            Object.keys(condition).length > 0 &&
            Object.entries(condition).map(([unit, count], index) => (
              <div key={index} className=" mb-4 rounded-xl bg-gray-100 p-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  구성
                </label>
                <AutocompleteByArray
                  dataArray={unitList}
                  selectedValue={unit}
                  onChange={(v) => handleUnitChange(index, v)}
                  className=" mt-2 w-full"
                />
                <label
                  htmlFor="name"
                  className="mt-4 block text-sm font-medium text-gray-700"
                >
                  택배 당 최대 개수
                </label>
                <input
                  type="number"
                  id={`count-${index}`}
                  placeholder="ex) 2, 4"
                  value={count}
                  onChange={(e) =>
                    handleCountChange(index, Number(e.target.value))
                  }
                  className="mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                />
                <div className="flex">
                  <button
                    onClick={() => handleDeleteCondition(unit)}
                    className="ml-auto mt-4 text-sm font-semibold text-gray-400 hover:text-gray-500"
                  >
                    삭제
                  </button>
                </div>
              </div>
            ))}

          <div className=" rounded-xl bg-gray-100 p-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              구성
            </label>
            <AutocompleteByArray
              dataArray={unitList}
              selectedValue={''}
              onChange={(v) => setNewUnit(v)}
              className=" mt-2 w-full"
            />
            <label
              htmlFor="name"
              className="mt-4 block text-sm font-medium text-gray-700"
            >
              택배 당 최대 개수
            </label>
            <input
              type="number"
              id="newCount"
              placeholder="ex) 2, 4"
              value={newCountString}
              onChange={(e) => {
                handleNewCountString(e.target.value);
              }}
              className="mt-2 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
            />
          </div>

          <Button
            name="+ 추가"
            color="skyblue"
            onClick={handleAddCondition}
            className="mt-4"
          />
        </div>
      )}

      {combinationType === 'maxUnits' && (
        <div className="mb-6">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            최대 단위
          </label>
          <input
            type="text"
            id="condition"
            placeholder="예) 10kg, 16개 등"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            className="mb-4 mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
            required
          />
          {unitList.map((unit, index) => (
            <div
              key={index}
              className="mb-2 mt-1 flex items-center justify-between"
            >
              <span className="text-md ml-3 mr-1 text-gray-600">{unit} :</span>
              <div className="flex flex-1 items-center justify-end">
                <input
                  type="number"
                  value={unitQuantity[unit]}
                  onChange={(e) =>
                    handleUnitQuantityChange(unit, e.target.value)
                  }
                  className="mt-1 block w-32 rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                />
                <span className="text-md ml-4 mr-1 text-gray-600">
                  {typeof condition === 'string'
                    ? condition.replace(/[0-9]/g, '')
                    : ''}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderCombinationTypeSelector;
