import {
  cloneElement,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from 'react';
import { useRefs } from '@sweep/utils';
import { useTooltipContext } from './TooltipContext';

export interface TooltipTriggerProps extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
}

function TooltipTrigger(
  { children, ...props }: TooltipTriggerProps,
  ref: ForwardedRef<HTMLElement>
) {
  const { open, disabled, refs, getReferenceProps } = useTooltipContext();
  const childrenRef = (children as any).ref;
  const innerRef = useRefs([refs.setReference, ref, childrenRef]);

  if (!isValidElement(children)) {
    return null;
  }

  if (disabled) {
    return children;
  }

  return cloneElement(
    children,
    getReferenceProps?.({
      ref: innerRef,
      ...props,
      ...children.props,
      'data-state': open ? 'open' : 'closed',
    })
  );
}

export default forwardRef(TooltipTrigger);
