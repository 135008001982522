import Button from 'components/buttons/Button';
import { useOMSStore } from 'hooks/useOMSStore';
import { IconCircleDeleteSmall, IconCodeFile } from 'icons/index';
import NewModal from 'modals/NewModal';
import { CreatePartnerDTO, Matching, Partner } from 'models/Partner';
import { ReactNode, useContext, useState } from 'react';
import { findEstimatedColumnMapping } from 'services/column-mapping/findEstimatedColumnMapping';
import { If } from '@sweep/utils';
import { ColumnMapping } from 'src/models/ColumnMapping';
import { flattenMultiHeader } from 'src/services/column-mapping/flattenMultiHeader';
import { formatExcelData } from 'src/services/column-mapping/formatExcelData';
import { readExcelMigrationMultiSheet } from 'src/services/file/excel/readExcel-migration-multisheet';
import PartnerColumnMatchingInput from './common/PartnerColumnMatchingInput';
import PartnerInfoInput from './common/PartnerInfoInput';
import { PartnerFormModalContext } from './PartnerFormModalContext';

type PartnerFormType = 'register' | 'update';

interface PartnerFormModalProps {
  open: boolean;
  defaultPartner?: Partial<Partner>;
  onSubmit: (partner: Omit<Partner, '_id'> | null) => void;
  type: PartnerFormType;
  children?: ReactNode;
}

export function PartnerFormModal({
  open,
  onSubmit,
  defaultPartner,
  type,
  children,
}: PartnerFormModalProps) {
  const [partner, setPartner] = useState<CreatePartnerDTO>({
    name: '',
    ...defaultPartner,
  });

  const handleChange = (partner: Partial<Partner>) => {
    setPartner((prev) => ({ ...prev, ...partner }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const partnerName = partner.name?.trim();
    if (partnerName == null || partnerName === '') {
      alert('회사명을 입력해주세요.');
      return;
    }

    if (partnerName.includes('/')) {
      alert(`회사명에 / 는 포함될 수 없습니다.`);
      return;
    }

    onSubmit({
      ...partner,
      name: partnerName,
    });
  };

  const handleClose = () => {
    const message = getCloseConfirmMessage(type);
    const isConfirmed = window.confirm(message);
    if (!isConfirmed) {
      return;
    }

    onSubmit(null);
  };

  if (!open) {
    return null;
  }

  const modalTitle = getTitle(type);
  const submitButtonName = getSubmitButtonName(type);

  return (
    <PartnerFormModalContext.Provider
      value={{ partner, onChange: handleChange }}
    >
      <NewModal
        header={modalTitle}
        onClose={handleClose}
        backdropClick={false}
        tailwindWidth="w-10/12"
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-6 px-10">{children}</div>

          <Button
            type="submit"
            name={submitButtonName}
            className="float-end mr-8"
          />
        </form>
      </NewModal>
    </PartnerFormModalContext.Provider>
  );
}

PartnerFormModal.InfoInput = PartnerFormModalInfoInput;
PartnerFormModal.FileUpload = PartnerFormModalFileUpload;
PartnerFormModal.ColumnMatchingInput = PartnerFormModalColumnMatchingInput;

function PartnerFormModalInfoInput() {
  const { partner, onChange } = useContext(PartnerFormModalContext);

  return <PartnerInfoInput partner={partner} onChange={onChange} />;
}

function PartnerFormModalFileUpload() {
  const oms = useOMSStore();
  const { partner, onChange } = useContext(PartnerFormModalContext);

  const baseUrl = window.location.origin;
  const IS_ADMIN =
    baseUrl.includes('localhost:3000') ||
    baseUrl.includes('127.0.0.1:3000') ||
    oms.user.admin;

  const [matchingId, setMatchingId] = useState<string | null>(null);
  const [filename, setFilename] = useState<string | null>(null);

  // (@yechan): custom logic
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const [validSheetIndex, setValidSheetIndex] = useState<number>(1);
  const [headerStartIndex, setHeaderStartIndex] = useState<number>(1);
  const [headerEndIndex, setHeaderEndIndex] = useState<number>(1);
  const [orderStartIndex, setOrderStartIndex] = useState<number>(2);
  const [isTruncateEnabled, setIsTruncateEnabled] = useState<boolean>(false);

  const handleFile = async (files: FileList | null) => {
    const filesArray = Array.from(files ?? []);
    if (filesArray.length === 0) {
      return;
    }

    const file = filesArray[0];

    const excelFile = await oms.loading.batch(() =>
      readExcelMigrationMultiSheet(file, {
        includeRawData: true,
      })
    );

    if (excelFile == null) {
      return;
    }

    const options = getOptionsWithZeroBasedIndex();
    const { validSheetIndex, headerStartIndex, headerEndIndex } = options;

    const excelData = excelFile.data.at(validSheetIndex);
    if (excelData == null) {
      return;
    }
    const header = flattenMultiHeader(
      excelData.slice(headerStartIndex, headerEndIndex + 1)
    );
    const formattedExcelData = formatExcelData(excelData, options);

    const filename = excelFile.name;
    const flattenedHeader = formattedExcelData.at(0);
    const exampleContents = formattedExcelData.slice(1, 7);

    if (flattenedHeader == null) {
      return;
    }

    const estimatedColumnMapping = findEstimatedColumnMapping(flattenedHeader);

    const templateFile = excelFile.templateFile;

    const matching: Matching = {
      id: self.crypto.randomUUID(),
      header,
      columnMapping: estimatedColumnMapping,
      exampleContents,
      ...options,
      ...(templateFile != null && {
        xlsxTemplateSetting: {
          enabled: true,
          templateFile,
        },
      }),
    };

    onChange({
      matchings: [...(partner.matchings ?? []), matching],
    });
    setFilename(filename);
    setMatchingId(matching.id);
  };

  const handleRemoveFile = () => {
    setFilename(null);
    setMatchingId(null);
    const matchings = partner.matchings?.filter(
      (matching) => matching.id !== matchingId
    );
    setOpenSetting(false);
    resetSettings();

    onChange({ matchings });
  };

  const resetSettings = () => {
    setValidSheetIndex(1);
    setHeaderStartIndex(1);
    setHeaderEndIndex(1);
    setOrderStartIndex(2);
    setIsTruncateEnabled(false);
  };

  const handleOpenSettingClick = () => {
    setOpenSetting((prev) => !prev);
    resetSettings();
  };

  const handleValidSheetIndex = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValidSheetIndex(parseInt(event.target.value));
  };

  const handleHeaderStartIndex = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const intValue = parseInt(event.target.value);
    setHeaderStartIndex(Math.max(1, intValue));
    if (isNaN(intValue)) {
      return;
    }
    setHeaderEndIndex(Math.max(intValue, headerEndIndex));
    setOrderStartIndex(Math.max(intValue + 1, orderStartIndex));
  };

  const handleHeaderEndIndex = (event: React.ChangeEvent<HTMLInputElement>) => {
    const intValue = parseInt(event.target.value);
    if (isNaN(intValue)) {
      return;
    }
    setHeaderEndIndex(Math.max(headerStartIndex, intValue));
    setOrderStartIndex(Math.max(intValue + 1, orderStartIndex));
  };

  const handleOrderStartIndex = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderStartIndex(
      Math.max(headerEndIndex + 1, parseInt(event.target.value))
    );
  };

  const handleIsTruncateEnabled = () => {
    setIsTruncateEnabled((prev) => !prev);
  };

  const getOptionsWithZeroBasedIndex = () => {
    const oneBasedValidSheetIndex = getValidNumber(validSheetIndex, 1);
    const zeroBasedValidSheetIndex =
      oneBasedValidSheetIndex > 0
        ? oneBasedValidSheetIndex - 1
        : oneBasedValidSheetIndex;
    return {
      validSheetIndex: zeroBasedValidSheetIndex,
      headerStartIndex: getValidNumber(headerStartIndex, 1) - 1,
      headerEndIndex: getValidNumber(headerEndIndex, 1) - 1,
      orderStartIndex: getValidNumber(orderStartIndex, 2) - 1,
      isTruncateEnabled,
    };
  };

  return (
    <div className="flex justify-between">
      <div>
        <label
          htmlFor="excelFile"
          className="font-pretendard block self-stretch text-base font-medium not-italic leading-[21px_] text-[color:var(--Gray-600,#343D4B)]"
        >
          판매처의 엑셀 양식을 올려주세요
        </label>
        <div className="mb-4 mt-[16px] flex items-center space-x-4 rounded-md border-gray-300 bg-white ">
          <If is={filename == null}>
            <div className="gap-10px flex">
              <div className="relative flex h-10 w-32 items-center justify-center rounded-lg bg-blue-500">
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={(event) => handleFile(event.target.files)}
                  className="absolute inset-0 size-full cursor-pointer opacity-0"
                />
                <IconCodeFile color="white" />
                <span className="ml-[4px] text-sm font-bold not-italic leading-[19px_] text-[color:var(--Gray-100,#F2F6FA)]">
                  파일 업로드
                </span>
              </div>
              <If is={IS_ADMIN}>
                <div className="gap-4px flex items-center">
                  <input
                    type="checkbox"
                    className="size-16px"
                    checked={openSetting}
                    onChange={handleOpenSettingClick}
                  />
                  <p>엑셀 설정</p>
                </div>
                <If is={openSetting}>
                  <div className="gap-10px flex">
                    <label>시트 순서</label>
                    <input
                      type="number"
                      className="w-48px border"
                      value={validSheetIndex}
                      onChange={handleValidSheetIndex}
                    />
                    <label>헤더 시작</label>
                    <input
                      type="number"
                      className="w-48px border"
                      value={headerStartIndex}
                      onChange={handleHeaderStartIndex}
                    />
                    <label>헤더 끝</label>
                    <input
                      type="number"
                      className="w-48px border"
                      value={headerEndIndex}
                      onChange={handleHeaderEndIndex}
                    />
                    <label>주문 시작</label>
                    <input
                      type="number"
                      className="w-48px border"
                      value={orderStartIndex}
                      onChange={handleOrderStartIndex}
                    />
                    <label>빈 행 이후 자르기</label>
                    <input
                      type="checkbox"
                      checked={isTruncateEnabled}
                      onChange={handleIsTruncateEnabled}
                    />
                  </div>
                </If>
              </If>
            </div>
          </If>

          <If is={filename != null}>
            <div className="flex h-[45px] items-center justify-center gap-[8px] rounded-lg bg-[#F2F6FA] px-5 py-0">
              <div className="flex ">
                <IconCodeFile color="#9FB1C1" />
                <span className="ml-[4px] text-sm font-bold not-italic leading-[19px_] text-[#343D4B]">
                  업로드된 파일
                </span>
              </div>
              <span className="text-sm font-bold not-italic leading-[19px_] text-blue-500">
                {filename}
              </span>
              <div
                className="inset-y-0 right-[24px] flex cursor-pointer items-center"
                onClick={handleRemoveFile}
              >
                <IconCircleDeleteSmall />
              </div>
            </div>
          </If>
        </div>
      </div>
    </div>
  );
}

function PartnerFormModalColumnMatchingInput() {
  const { partner, onChange } = useContext(PartnerFormModalContext);
  const matching = partner.matchings?.at(-1);

  const handleColumnMappingChange = (columnMapping: ColumnMapping) => {
    if (matching == null) {
      return;
    }

    onChange({
      matchings: partner.matchings?.map((m) =>
        m.id === matching.id ? { ...m, columnMapping } : m
      ),
    });
  };

  if (
    matching == null ||
    matching.header == null ||
    matching.header.length === 0
  ) {
    return null;
  }

  const header = matching.header;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-2">
        <p className="block text-xl font-bold text-black">매칭 세부설정</p>
        <p className="text-md">
          엑셀 파일 정보에 가장 부합하는 주요 명칭을 선택해주세요.
        </p>
      </div>
      <PartnerColumnMatchingInput
        header={header}
        columnMapping={matching.columnMapping}
        onChange={handleColumnMappingChange}
        exampleContents={matching.exampleContents}
      />
    </div>
  );
}

function getTitle(type: PartnerFormType) {
  switch (type) {
    case 'register':
      return '판매처 추가';
    case 'update':
      return '판매처 수정';
  }
}

function getCloseConfirmMessage(type: PartnerFormType) {
  switch (type) {
    case 'register':
      return '판매처를 추가하지 않고 화면을 닫으시겠습니까?';
    case 'update':
      return '판매처 수정을 취소하시겠습니까?';
  }
}

function getSubmitButtonName(type: PartnerFormType) {
  switch (type) {
    case 'register':
      return '추가';
    case 'update':
      return '수정';
  }
}

function getValidNumber(value: number, defaultValue: number) {
  return isNaN(value) ? defaultValue : value;
}
