import { NormalizedOrder } from '@sweep/contract';

export function divideBySupplier(orders: NormalizedOrder[]) {
  const ordersBySupplier: Record<string, NormalizedOrder[]> = {};
  const unseparatedOrders: NormalizedOrder[] = [];

  orders.forEach((order) => {
    if (order.supplierId == null) {
      unseparatedOrders.push(order);
      return;
    }

    const prevOrders = ordersBySupplier[order.supplierId] ?? [];
    ordersBySupplier[order.supplierId] = [...prevOrders, order];
  });

  return { ordersBySupplier, unseparatedOrders };
}
