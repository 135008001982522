import { AxiosResponse } from 'axios';
import type {
  ParseSpreadsheetRequest,
  ParseSpreadsheetResponse,
} from '@sweep/contract';
import type { FormulaeSpreadsheetTypes } from '@sweep/spreadsheet/types';
import api from './api';

const API_SPREADSHEET_PARSE_URL = '/spreadsheet/parse';

export async function requestParseSpreadsheet(
  params: ParseSpreadsheetRequest
): Promise<
  AxiosResponse<
    ParseSpreadsheetResponse<FormulaeSpreadsheetTypes.IntermediateData>
  >
> {
  const { file, password, includeRawData } = params;

  const filename = file.name.normalize('NFC');

  const formData = new FormData();
  formData.append('spreadsheet', file);
  formData.append('filename', filename);
  if (password != null) {
    formData.append('password', password);
  }
  formData.append('includeRawData', (includeRawData === true).toString());

  const response = await api.post<
    ParseSpreadsheetResponse<FormulaeSpreadsheetTypes.IntermediateData>
  >(API_SPREADSHEET_PARSE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
}
