import { readExcelOnServer } from 'network/file';
import { getFilename } from 'services/file/getFilename';
import { toExcelJson } from './toExcelJson';

export const PASSWORD_PROMPT_MESSAGE = {
  FIRST_ATTEMPT: (filename: string) =>
    `${filename}의 암호를 입력해주세요. 모른다면 취소를 눌러주세요.`,
  RETRY: (filename: string) =>
    `${filename}의 암호가 틀렸습니다. 다시 입력해주세요. 모른다면 취소를 눌러주세요.`,
};

export function promptForPassword(params: {
  filename: string;
  messageType: 'FIRST_ATTEMPT' | 'RETRY';
}): string | null {
  const { filename, messageType } = params;

  const message = PASSWORD_PROMPT_MESSAGE[messageType](filename);

  return window.prompt(message);
}

export async function readProtectedExcel(file: File) {
  const filename = getFilename(file);
  let isFirstAttempt = true;

  while (true) {
    const messageType = isFirstAttempt ? 'FIRST_ATTEMPT' : 'RETRY';
    const password = promptForPassword({
      filename,
      messageType,
    });
    isFirstAttempt = false;

    if (password == null) {
      return null;
    }

    const response = await readExcelOnServer(file, password);
    if (response.success) {
      return toExcelJson(response.data);
    }
  }
}
