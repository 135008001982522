import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';

export class NormalizeAddressPlugin extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    const validAddressByTightMap = new Map<string, string>();
    const invalidAddressByTightMap = new Map<string, string>();

    orders.forEach((order) => {
      const address = order.address;
      if (address == null) {
        return;
      }
      const tightAddress = this.getTightAddress(address);

      if (order.isAddressValid === 'okay') {
        const updatedValidAddress = validAddressByTightMap.get(tightAddress);
        const isBetterAddress =
          updatedValidAddress == null ||
          address.length > updatedValidAddress.length;
        if (isBetterAddress) {
          validAddressByTightMap.set(tightAddress, address);
        }
        return;
      }

      const updatedInvalidAddress = invalidAddressByTightMap.get(tightAddress);
      const isBetterAddress =
        updatedInvalidAddress == null ||
        address.length > updatedInvalidAddress.length;
      if (isBetterAddress) {
        invalidAddressByTightMap.set(tightAddress, address);
      }
    });

    const updatedOrders: Order[] = orders.map((order) =>
      order.address == null
        ? order
        : {
            ...order,
            address:
              validAddressByTightMap.get(this.getTightAddress(order.address)) ??
              invalidAddressByTightMap.get(
                this.getTightAddress(order.address)
              ) ??
              order.address,
          }
    );

    return Promise.resolve(updatedOrders);
  };

  getTightAddress(address: string) {
    return address.replace(/\s+/g, '');
  }
}
