import { useEffect, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';

const ColumnMappingHandle = ({
  columnMapping,
  setColumnMapping,
  supplierKoreanHeader,
  exampleContents,
}) => {
  const oms = useOMSStore();
  const findKeyByValue = (object, value) =>
    Object.keys(object).find((key) => object[key] === value);

  const [koreanUserHeader, setKoreanUserHeader] = useState(
    oms.user.excelHeaderKeys.map((key) => oms.user.excelColumnMapping[key])
  );

  const [userColumnMapping, setUserColumnMapping] = useState(
    oms.user.excelColumnMapping
  );

  const handleMappingChange = (headerIndex, supplierHeaderIndex) => {
    let englishValue =
      Object.entries(userColumnMapping).find(
        ([key, value]) => value === koreanUserHeader[headerIndex]
      )?.[0] || koreanUserHeader[headerIndex];

    if (headerIndex === -2) {
      englishValue = 'productName';
    }

    let updatedMapping = { ...columnMapping };

    let koreanValue = supplierKoreanHeader[supplierHeaderIndex];
    let existingKey = Object.keys(columnMapping).find(
      (key) => columnMapping[key] === koreanValue
    );

    if (existingKey) {
      // updateMapping에 이미 존재하는 key가 있으면 해당 value에 해당하는 key를 업데이트
      updatedMapping[koreanValue] = koreanValue;
      delete updatedMapping[existingKey];
    }
    if (englishValue) {
      updatedMapping[englishValue] = koreanValue;
    }

    setColumnMapping(updatedMapping);
  };

  const completeRequiredFields = (array, mapping) => {
    const requiredFields = [
      'option',
      'productName',
      'quantity',
      'name',
      'contactNumber',
      'address',
      'shippingCompany',
      'shippingNumber',
      'orderNumber',
      'postCode',
    ];
    const matching = {
      productName: '상품명',
      name: '고객명',
      contactNumber: '연락처',
      postCode: '우편번호',
      address: '주소',
      deliveryMessage: '배송메시지',
      shippingCompany: '택배사',
      shippingNumber: '운송장번호',
      option: '상품옵션',
      quantity: '개수',
      note: '비고',
      orderNumber: '주문번호',
    };

    let updatedArray = [...array];
    let updatedMapping = { ...mapping };
    // array 내 null 값 제거
    updatedArray = updatedArray.filter((field) => field);

    // array에 필수 필드가 없으면 필수 필드를 추가
    requiredFields.forEach((field) => {
      if (!updatedArray.includes(field)) {
        updatedArray.push(field);
        updatedMapping[field] = matching[field];
      }
    });

    return { updatedArray, updatedMapping };
  };

  useEffect(() => {
    let updatedMapping = { ...oms.user.excelColumnMapping };
    let updatedOrder = [...oms.user.excelHeaderKeys];
    ({ updatedArray: updatedOrder, updatedMapping } = completeRequiredFields(
      updatedOrder,
      updatedMapping
    ));

    setKoreanUserHeader(updatedOrder.map((key) => updatedMapping[key]));
    setUserColumnMapping(updatedMapping);
  }, [oms.user.excelHeaderKeys, oms.user.excelColumnMapping]);

  const aggregationSelect = (key, index) => {
    return (
      <select
        value={koreanUserHeader.findIndex((korUser) => {
          return (
            findKeyByValue(userColumnMapping, korUser) ===
            findKeyByValue(columnMapping, key)
          );
        })}
        onChange={(e) => {
          return handleMappingChange(e.target.value, index);
        }}
        className={`h-[40px] w-full rounded-lg border border-gray-300 px-[16px] ${
          koreanUserHeader.findIndex((korUser) => {
            return (
              findKeyByValue(userColumnMapping, korUser) ===
              findKeyByValue(columnMapping, key)
            );
          }) === -1
            ? 'text-gray-500'
            : 'text-gray-900'
        }`}
      >
        <option value={-1}>선택되지 않음</option>

        {koreanUserHeader.map((column, index) => (
          <option key={index} value={index}>
            {column}
          </option>
        ))}
      </select>
    );
  };

  return (
    <>
      {supplierKoreanHeader?.map((key, index) => (
        <div key={index} className="flex  flex-col whitespace-nowrap">
          <div
            className={`mb-[32px] flex min-w-[200px] items-center justify-between`}
          >
            <div className="flex w-full flex-col">
              {aggregationSelect(key, index)}
            </div>
          </div>
          <div
            className={`mb-[8px] flex-1 truncate whitespace-nowrap bg-[#F5F7FA] px-[16px] py-[3px] text-left text-[14px]`}
          >
            {key}
          </div>

          <div>
            {exampleContents?.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className={`relative mb-[8px] flex-1 truncate whitespace-nowrap px-[12px] text-left text-[14px] leading-[21px] text-[#6B7683]`}
              >
                <span className="absolute">•</span>
                <span className="pl-[16px]">{row?.[index] ?? ''}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default ColumnMappingHandle;
