import { requestDispatchOrders } from 'src/network/order/dispatchOrder';

export async function getDispatchOrdersRequestId(
  startTime: number,
  endTime: number,
  shoppingMallName: string
) {
  const response = await requestDispatchOrders(
    startTime,
    endTime,
    shoppingMallName
  );

  if (response == null || response.message === 'Failed') {
    return null;
  }

  return response.result.requestId;
}
