import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { NormalizedOrder } from '@sweep/contract';
import { formatDate, If } from '@sweep/utils';
import { Button } from 'src/design-system/components/Button';
import SearchInput from 'src/design-system/components/SearchInput/SearchInput';
import { SelectableTable } from 'src/design-system/components/Table/selectable/SelectableTable';
import useFileHandling from 'src/hooks/useFileHandling';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { IconControlDownload } from 'src/icons';
import { createOrderExcel } from 'src/services/file/excel/create';
import TableCount from '../components/TableCount';
import TableSelectedCount from '../components/TableSelectedCount';
import { useTableFilterColumns } from '../hooks/useTableFilterColumns';
import { OrderShippingSectionStore } from '../stores/OrderShippingSectionStore';

function OrderShippingCompleteSection() {
  const oms = useOMSStore();
  const store = oms.getStore(OrderShippingSectionStore);
  const shippingCompleteOrders = store.shippingCompleteOrders;

  const { splitOrders } = useFileHandling();

  const [search, setSearch, filterSearch] = useSearchState({
    keys: oms.user.excelHeaderKeys,
  });
  const searchFilteredOrders = useMemo(
    () => filterSearch(shippingCompleteOrders),
    [filterSearch, shippingCompleteOrders]
  );

  const { columns, filteredOrders } =
    useTableFilterColumns(searchFilteredOrders);

  const [selectedOrders, setSelectedOrders] = useState<NormalizedOrder[]>([]);
  const currentOrders =
    selectedOrders.length > 0 ? selectedOrders : filteredOrders;

  const download = () =>
    createOrderExcel(
      oms,
      currentOrders,
      formatDate(new Date(), 'yy.MM.dd 통합엑셀'),
      oms.user.excelHeaders,
      oms.user.excelColumnMapping
    );

  const splitPurchaseOrder = () => splitOrders(currentOrders);

  return (
    <div className="shadow-line p-20px rounded-8px gap-16px flex flex-col bg-white">
      <div className="flex justify-between">
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder="검색어를 입력하세요."
          className="max-w-400px"
        />
        <Button
          rightAccessory={<IconControlDownload />}
          onClick={splitPurchaseOrder}
        >
          주문서 분리
        </Button>
      </div>
      <hr />
      <div>
        <TableCount count={filteredOrders.length} />
        <div className="flex items-end justify-between">
          <TableSelectedCount count={selectedOrders.length} />
          <Button
            color="gray"
            rightAccessory={<IconControlDownload />}
            onClick={download}
          >
            엑셀 파일
          </Button>
        </div>
      </div>
      <If is={shippingCompleteOrders.length > 0}>
        <SelectableTable
          items={filteredOrders}
          columns={columns}
          selectedItems={selectedOrders}
          onSelect={setSelectedOrders}
          getKey={(order) => order.uniqueCodeAfterCustomization}
          className="max-h-[calc(100vh-300px)]"
        />
      </If>
    </div>
  );
}

export default observer(OrderShippingCompleteSection);
