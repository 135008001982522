import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button } from 'src/design-system/components/Button';
import { useOMSStore } from 'src/hooks/useOMSStore';
import MockSetting from 'src/mocks/MockSetting';
import { updateUserSetting } from 'src/network/user';
import DeploySetting from './components/DeploySetting';
import UserSettingInput from './components/UserSettingInput';

function QaSettingScreen() {
  const oms = useOMSStore();
  const [deploy, setDeploy] = useState(oms.user.deploy ?? {});
  const [setting, setSetting] = useState(oms.user.setting);

  useEffect(() => {
    setDeploy(oms.user.deploy ?? {});
  }, [oms.user.deploy]);

  useEffect(() => {
    setSetting(oms.user.setting);
  }, [oms.user.setting]);

  const save = async () => {
    await oms.loading.batch(async () => {
      await oms.user.update({ deploy });
      if (setting != null) {
        await updateUserSetting(setting);
      }
    });
    window.location.reload();
  };

  return (
    <div className="gap-20px py-32px px-20px flex flex-col">
      <div className="flex items-center gap-2">
        <strong>설정을 변경하면 새로고침을 해주세요 ⚠️</strong>
        <Button onClick={save}>저장 후 새로고침</Button>
      </div>
      <MockSetting />
      <DeploySetting deploy={deploy} onDeployChange={setDeploy} />
      {setting && <UserSettingInput value={setting} onChange={setSetting} />}
    </div>
  );
}

export default observer(QaSettingScreen);
