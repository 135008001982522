import copy from 'fast-copy';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../../components/buttons/Button';
import LoadingIndicator from '../../components/LoadingIndicator';
import OMSContainer from '../../components/OMSContainer';
import LoadingStore from '../../stores/LoadingStore';
import UserStore from '../../stores/UserStore';
import backendApi from '../../utils/backendApi';

function MyFormComponent({ path, setSettingsByPath }) {
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault(); // 폼 제출 시 페이지 새로고침 방지
    setSettingsByPath(path, key, value);
    // 설정 후 필드를 초기화할 수 있음
    setKey('');
    setValue('');
  };

  return (
    <form className="flex items-center" onSubmit={handleSubmit}>
      <input
        type="text"
        className="ml-2 rounded-md border border-blue-500 p-1"
        name="key"
        value={key}
        onChange={(e) => setKey(e.target.value)}
        placeholder="키 입력"
      />
      <input
        type="text"
        className="ml-2 rounded-md border border-blue-500 p-1"
        name="value"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="값 입력"
      />
      <button type="submit" className="ml-2">
        추가
      </button>
    </form>
  );
}

const AdminUserSettingScreen = observer(() => {
  const oms = useOMSStore();

  const structure = {
    type: 'object',
    description: '사용자 설정',
    properties: {
      mailForRetailer: {
        type: 'object',
        description:
          '메일 기능. column order랑 columntranslation은 백엔드에서 해석한 기준으로 실패파일 아둔로드',
        properties: {
          enabled: {
            type: 'boolean',
          },
        },
      },
      addFixedColumnValue: {
        type: 'object',
        description: '모든 주문에 통합시 고정값 추가',
        properties: {
          enabled: {
            type: 'boolean',
          },
          settings: {
            type: 'objectArray', //object 형식이지만 array처럼 사용
            description: 'key : 변수명, value : 고정값',
            // valueProperties: {},
          },
        },
      },
      addConditionalColumnValues: {
        type: 'object',
        description: '조건부로 고정값 추가',
        properties: {
          enabled: {
            type: 'boolean',
          },
          rules: {
            type: 'array',
            description: 'key : 변수명, value : 고정값',
            items: {
              type: 'object',
              properties: {
                matchCondition: {
                  type: 'objectArray',
                  description: '조건식',
                },
                result: {
                  type: 'objectArray',
                  description: 'key : 변수명, value : 고정값',
                  // valueProperties: {},
                },
              },
            },
          },
        },
      },
      combinationCustomSetting: {
        type: 'object',
        description: '합배송 설정',
        properties: {
          enabled: {
            type: 'boolean',
          },
          additionalKeyColumns: {
            enabled: {
              type: 'boolean',
            },
            keyColumns: {
              type: 'array',
              description: '합배송 설정시 추가로 비교할 컬럼',
              items: {
                type: 'string',
              },
            },
          },
          setDefaultToCombine: {
            type: 'object',
            description: '기본적으로 모든 같은 상품,구성을 하나로 합침',
            properties: {
              enabled: {
                type: 'boolean',
              },
              combineAllProducts: {
                type: 'boolean',
                description:
                  '같은 주소면(+additionalKeyColumn) 합배송 설정이 안되있는 모든 상품을 하나로 합침',
              },
            },
          },
          separateCombineProcess: {
            type: 'object',
            description: '합배송 과정을 분리해서 진행함',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
          calcPrice: {
            type: 'object',
            description: '가격계산',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
          justSeparate: {
            type: 'object',
            description:
              '모든 주문을 분리함(합배송 설정 무시) 2a+3b -> 2a / 3b',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
        },
      },
      // companyType: {
      //   type: 'string',
      //   description: '회사 타입',
      // },
      formatOrdersSettings: {
        type: 'object',
        description: '주문서 포맷 설정',
        properties: {
          enabled: {
            type: 'boolean',
          },
          setDataQuantity1: {
            type: 'boolean',
            description:
              'data의 1로 고정해서 나옴 (해석한 수량을 order.quantity에 넣음) -한 주문에 1개 상품만 있을때 사용 가능',
          },
          setOrderQuantity1: {
            type: 'boolean',
            description:
              'order.quantity를 1로 설정 (data.quantity 에다가 곱함)',
          },
          setQuantityWithOrig: {
            type: 'boolean',
            description: '원본수량을 quantity에 넣음',
          },
          //getOptionFromData
          deleteUnits: {
            type: 'boolean',
            description: '발주명에서 단위를 삭제함',
          },
          replaceAddToComma: {
            type: 'boolean',
            description: 'a1k, b2k x2, ...',
          },
          dontUseMultiplier: {
            type: 'boolean',
            description: ' a*2+b -> a+a+b',
          },
          showQuantityAlways: {
            type: 'boolean',
            description: '상품수량을 항상 보여줌(1개인 경우에도)',
          },
          customFormat: {
            type: 'string',
            description:
              '주문서 포맷을 직접 설정 ex)"{productName} {unit} x{quantity}"',
          },
          customSeparator: {
            type: 'string',
            description: '주문서 포맷에서 사용할 구분자 ex) " + "<< 기본',
          },
          //customQuantityFormat = 'x{quantity}',
          customQuantityFormat: {
            type: 'string',
            description:
              '주문서 포맷에서 사용할 수량 포맷 ex) "x{quantity}" << 기본',
          },
          applyQuantityToUnit: {
            type: 'boolean',
            description: '상품수량을 단위에 반영해서 나옴',
          },
          useProductName: {
            type: 'boolean',
            description: '상품명을 사용함',
          },
        },
      },
      interpretOrderSettings: {
        type: 'object',
        description: '주문서 해석 설정',
        properties: {
          setUnmatchedProductNameToEmpty: {
            type: 'boolean',
            description: '매칭되지 않은 상품명을 빈칸으로 설정',
          },
          useOptionCodeAsRawProductName: {
            type: 'boolean',
            description: '옵션코드를 상품명으로 사용',
          },
          useOptionCodeMatchingModal: {
            type: 'boolean',
            description: '옵션코드 매칭하는 모달',
          },
          notUseCompositionMatchingModal: {
            type: 'boolean',
            description: '구성품 매칭하는 모달 사용 안함',
          },
          conditionalOptionCodeMatching: {
            type: 'object',
            description: '옵션코드 조건부 매칭',
            properties: {
              enabled: {
                type: 'boolean',
              },
              settings: {
                type: 'recursiveArray',
                items: {
                  type: 'object',
                  properties: {
                    enabled: {
                      type: 'boolean',
                    },
                    type: {
                      type: 'string',
                      description: 'array << recursive',
                    },
                    field: {
                      type: 'string',
                    },
                    searchType: {
                      type: 'string',
                    },
                    value: {
                      type: 'string',
                    },
                    D_recursiveCondition: ['type', 'array'],
                    D_recursiveField: 'value',
                  },
                },
              },
            },
          },
        },
      },
      platformSettings: {
        type: 'object',
        description: '?',
        properties: {
          enabled: {
            type: 'boolean',
          },
          defaultForm: {
            type: 'string',
          },
          matchingSet: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                fileKeyword: {
                  type: 'string',
                },
                platformName: {
                  type: 'string',
                },
              },
            },
          },
        },
      },
      preprocessSettings: {
        type: 'object',
        description: '주문서 전처리 설정',
        properties: {
          fixInvalidAddress: {
            type: 'object',
            description: '주소 오류 수정',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
          separateAddress: {
            type: 'object',
            description: '메인주소 상세주소 분리',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
          add0TocontactNumber: {
            type: 'object',
            description: '',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },

          addDateFromNameOption: {
            type: 'object',
            description: '',
            properties: {
              enabled: {
                type: 'boolean',
              },
              additionalCondition: {
                type: 'object',
                description:
                  '추가적인 조건 ex) marketName = "11번가" 일때만 적용',
                properties: {
                  enabled: {
                    type: 'boolean',
                  },
                  conditions: {
                    type: 'objectArray',
                    description: '조건식',
                  },
                },
              },
            },
          },

          deleteDateinNameOption: {
            type: 'object',
            description: '',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },

          deleteWonInNameOption: {
            type: 'object',
            description: '',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
          deleteQuantityInOption: {
            type: 'object',
            description: '',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
          removeInvalidOrders: {
            type: 'object',
            description: '',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
        },
      },
      sortOrders: {
        type: 'object',
        properties: {
          enabled: {
            type: 'boolean',
          },
          sortingCriteria: {
            type: 'string',
          },
        },
      },
      dispatchOrderSettings: {
        type: 'object',
        properties: {
          enabled: {
            type: 'boolean',
          },
          setOrderDateToKoreanTime: {
            type: 'object',
            properties: {
              enabled: {
                type: 'boolean',
              },
            },
          },
        },
      },
    },
  };
  const [settings, setSettings] = useState(oms.user.setting || {});
  const [schema, setSchema] = useState('');
  const setSettingsByPath = (pathArray, key, value) => {
    let current = copy(settings);
    let currentT = current;
    for (let i = 0; i < pathArray.length; i++) {
      if (!current[pathArray[i]]) {
        current[pathArray[i]] = {};
      }
      if (i === pathArray.length - 1) {
        current[pathArray[i]][key] = value;
        if (value === undefined) {
          delete current[pathArray[i]][key];
        }
      } else {
        current = current[pathArray[i]];
      }
    }
    if (pathArray.length === 0) {
      currentT[key] = value;
      if (value === undefined) {
        delete currentT[key];
      }
    }
    setSettings(currentT);
  };

  useEffect(() => {
    setSchema(makeElementBySchema(structure, settings));
  }, [settings]);

  // const makeElementBySchema(structure, UserStore?.customExcelSettings)

  const makeElementBySchema = (schema, settings, path = []) => {
    const { type, properties = {} } = schema;
    if (type === 'object') {
      return (
        <div className="space-y-2">
          {Object.keys(properties)
            .filter((key) => !key.startsWith('D_'))
            .map((key) => {
              if (properties[key].type === 'boolean') {
                return (
                  <span className="flex items-center" key={key}>
                    <span className="text-lg font-medium">{key}</span>
                    <input
                      type="checkbox"
                      className="ml-2"
                      checked={settings?.[key]}
                      onChange={(e) => {
                        setSettingsByPath(path, key, e.target.checked);
                        // settings[key] = e.target.checked;
                      }}
                    />
                    <span className="ml-2 text-gray-500">
                      {properties[key].description}
                    </span>
                  </span>
                );
              } else if (properties[key].type === 'string') {
                return (
                  <span className="items center flex" key={key}>
                    <span className="text-lg font-medium">{key}</span>
                    <input
                      type="text"
                      className="ml-2 w-96 rounded-md border border-gray-500 p-1"
                      value={settings?.[key] || ''}
                      onChange={(e) => {
                        setSettingsByPath(path, key, e.target.value);
                      }}
                    />
                    <span className="ml-2 text-gray-500">
                      {properties[key].description}
                    </span>
                  </span>
                );
              } else if (properties[key].type === 'array') {
                return (
                  <details key={key} className="group">
                    <summary className="cursor-pointer select-none">
                      <span className="text-lg font-medium">{key}</span>
                      <span className="ml-2 text-gray-500">
                        {properties[key]?.description}
                      </span>
                    </summary>
                    {(settings?.[key] || []).map((item, index) => {
                      return (
                        <div
                          className="items center ml-4 mt-2 flex justify-between rounded-md border border-gray-500 p-2"
                          key={index}
                        >
                          {makeElementBySchema(
                            properties[key].items,
                            item,
                            path.concat(key, index)
                          )}
                          <button
                            className="ml-2 mr-8"
                            onClick={() => {
                              setSettingsByPath(
                                path,
                                key,
                                settings?.[key].filter((_, i) => i !== index)
                              );
                            }}
                          >
                            삭제
                          </button>
                        </div>
                      );
                    })}
                    <button
                      className="ml-6 mt-2 rounded-md bg-gray-200 p-1"
                      onClick={(e) => {
                        e.preventDefault();

                        setSettingsByPath(path, key, [
                          ...(settings?.[key] || []),
                          {},
                        ]);
                      }}
                    >
                      추가
                    </button>
                  </details>
                );
              } else if (properties[key].type === 'objectArray') {
                return (
                  <details key={key} className="group">
                    <summary className="cursor-pointer select-none">
                      <span className="text-lg font-medium">{key}</span>
                      <span className="ml-2 text-gray-500">
                        {properties[key].description}
                      </span>
                    </summary>
                    {Object.keys(settings?.[key] || {}).map((item, index) => {
                      return (
                        <span className="items center mb-4 flex" key={index}>
                          <span className="ml-2 rounded-md border border-gray-500 p-1 text-lg font-medium">
                            {item}
                          </span>
                          <input
                            type="text"
                            className="ml-2 rounded-md border border-gray-500 p-1"
                            value={settings?.[key][item] || ''}
                            onChange={(e) => {
                              setSettingsByPath(
                                path.concat(key),
                                item,
                                e.target.value
                              );
                            }}
                          />
                          <button
                            className="ml-2"
                            onClick={() => {
                              setSettingsByPath(
                                path.concat(key),
                                item,
                                undefined
                              );
                            }}
                          >
                            삭제
                          </button>
                        </span>
                      );
                    })}
                    <MyFormComponent
                      path={path.concat(key)}
                      setSettingsByPath={setSettingsByPath}
                    />
                  </details>
                );
              } else if (properties[key].type === 'recursiveArray') {
                return (
                  <details key={key} className="group">
                    <summary className="cursor-pointer select-none">
                      <span className="text-lg font-medium">{key}</span>
                      <span className="ml-2 text-gray-500">
                        {properties[key]?.description}
                      </span>
                    </summary>
                    {(settings?.[key] || []).map((item, index) => {
                      return (
                        <div
                          className="items center ml-4 mt-2 flex justify-between rounded-md border border-gray-500 p-2"
                          key={index}
                        >
                          {item[
                            properties[key].items.properties
                              .D_recursiveCondition[0]
                          ] ===
                          properties[key].items.properties
                            .D_recursiveCondition[1]
                            ? makeElementBySchema(
                                {
                                  ...copy(properties[key].items),
                                  properties: {
                                    ...properties[key].items.properties,

                                    [properties[key].items.properties
                                      .D_recursiveField]: copy(properties[key]),
                                  },
                                },
                                item,
                                path.concat(key, index)
                              )
                            : makeElementBySchema(
                                properties[key].items,
                                item,
                                path.concat(key, index)
                              )}
                          <button
                            className="ml-2 mr-8"
                            onClick={() => {
                              setSettingsByPath(
                                path,
                                key,
                                settings?.[key].filter((_, i) => i !== index)
                              );
                            }}
                          >
                            삭제
                          </button>
                        </div>
                      );
                    })}

                    <button
                      className="ml-6 mt-2 rounded-md bg-gray-200 p-1"
                      onClick={(e) => {
                        e.preventDefault();

                        setSettingsByPath(path, key, [
                          ...(settings?.[key] || []),
                          {},
                        ]);
                      }}
                    >
                      추가
                    </button>
                  </details>
                );
              } else {
                return (
                  <details key={key} className="group">
                    <summary className="cursor-pointer select-none">
                      <span className="text-lg font-medium">{key}</span>
                      <span className="ml-2 text-gray-500">
                        {properties?.[key]?.description}
                      </span>
                    </summary>
                    <div className="ml-4 mt-2">
                      {makeElementBySchema(
                        properties?.[key],
                        settings?.[key],
                        path.concat(key)
                      )}
                    </div>
                  </details>
                );
              }
            })}
        </div>
      );
    } else {
      return (
        <span>
          {type} {JSON.stringify(settings) || ''}
        </span>
      );
    }
  };

  const saveSettings = async () => {
    LoadingStore.setIsLoading(true);
    delete settings._id;
    await backendApi.updateCustomSetting(settings);

    UserStore?.setCustomExcelSettings(settings);
    LoadingStore.setIsLoading(false);
  };

  return (
    <OMSContainer>
      <div className="mb-8 flex items-center">
        <h1 className="font-pretendard text-[24px] font-bold leading-[30px] text-[color:var(--Gray-600,#343D4B)]">
          user_excel_customization 설정
        </h1>

        <Button
          className="ml-4"
          onClick={saveSettings}
          name="저장"
          color="blue"
        />
      </div>
      <div>{schema}</div>
      {LoadingStore?.isLoading && <LoadingIndicator />}
    </OMSContainer>
  );
});

export default AdminUserSettingScreen;
