import { overlay } from 'overlay-kit';
import { Dialog } from 'src/design-system/components/Dialog';

export function openConfirmRemoveOdersDialog(count: number) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close }) => (
      <Dialog open={isOpen} onClose={close}>
        <Dialog.Title className="mb-16px">주문 파일을 삭제할까요?</Dialog.Title>
        <Dialog.Description className="mb-28px">
          발주 나간 건을 포함한 주문{' '}
          <span className="text-blue-500">{count}</span>건이 모두 삭제됩니다.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>삭제</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
