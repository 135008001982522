import { Order } from '@sweep/contract';
import { KeyOf } from '@sweep/utils';
import { MappingGuide } from './interface';

export type ColumnMappingGuide = MappingGuide<KeyOf<Order>>;

const COLUMN_MAPPING_GUIDE_FIRST: ColumnMappingGuide = {
  productName: ['상품명', '상품정보', '품명', '품목명', '제품명', '주문상품'],
  option: ['상품옵션', '옵션', '필수선택정보', '옵션명'],
  quantity: ['개수', '수량', '주문품목 수량', '판매수량'],
  name: [
    '고객명',
    '수령인',
    '수령인명',
    '수령자',
    '수취인',
    '수취인명',
    '받는분성명',
    '수취인이름',
    '받는사람',
  ],
  contactNumber: [
    '연락처',
    '휴대폰번호',
    '핸드폰번호',
    '핸드폰',
    '수령인전화',
    '수령인연락처',
    '수령인핸드폰',
    '전화번호',
    '휴대전화',
    '받는분전화번호',
    '받는분기타연락처',
  ],
  postCode: ['우편번호'],
  address: [
    '주소',
    '수령인주소',
    '주소지',
    '배송지주소',
    '받는분주소(전체,분할)',
  ],
  deliveryMessage: ['배송메시지', '배송메세지', '배송시문구'],
  shippingCompany: ['택배사', '택배회사'],
  shippingNumber: ['운송장번호', '송장번호'],
  note: ['기타', '비고', '특이사항'],
  uniqueCode: ['고유번호', '스윕고유번호'],
  orderNumber: ['주문번호', '고유코드', '고유번호', '주문아이디'],
  productCode: ['상품번호', '마스터상품코드', '상품코드'],
  optionNumber: ['옵션주문번호'],
  platformCode: ['사방넷번호', '사방넷주문번호'],
  price: ['가격', '주문금액', '판매가'],
};

const COLUMN_MAPPING_GUIDE_SECOND: ColumnMappingGuide = {
  productName: ['상품정보', '품명'],
  option: ['옵션'],
  quantity: ['개수', '수량'],
  contactNumber: ['연락처', '핸드폰', '휴대폰', '휴대폰번호'],
  telephoneNumber: ['전화'],
  postCode: ['우편번호'],
  address: ['주소'],
  deliveryMessage: ['메시지', '메세지', '문구', '메모', '요청사항'],
  shippingNumber: ['송장번호'],
  note: ['기타', '비고', '특이사항', '특기사항'],
  productCode: ['상품번호', '상품코드'],
  platformCode: ['사방넷', '플레이오토', '플랫폼'],
  price: ['주문금액'],
};

const COLUMN_MAPPING_GUIDE_THIRD: ColumnMappingGuide = {
  productName: ['상품정보', '품명', '단품명'],
  name: [
    '고객명',
    '수령인',
    '수령자',
    '수취인',
    '주문자',
    '받는분',
    '성명',
    '인명',
    '이름',
    '구매자',
  ],
  quantity: ['주문갯수'],
  contactNumber: ['주문자전화', '주문자핸드폰', '주문자휴대폰'],
  address: ['배송지'],
  shippingCompany: ['택배사', '배송사명'],
  orderNumber: ['주문번호', '주문코드', '주문아이디'],
  price: ['가격', '단가'],
};

export const COLUMN_MAPPING_GUIDES = [
  COLUMN_MAPPING_GUIDE_FIRST,
  COLUMN_MAPPING_GUIDE_SECOND,
  COLUMN_MAPPING_GUIDE_THIRD,
];
