import copy from 'fast-copy';
import UserStore from '../../../stores/UserStore';
import { gcd } from '../../../utils/math';
import { isValid } from '../../../utils/utils';
import { useOMSStore } from '../../useOMSStore';

function useCombineOrder() {
  const oms = useOMSStore();

  const getCombineSettings = () => {
    const settings = copy(UserStore?.combinationSettings) || [];
    const customSettings = oms.user.setting?.combinationCustomSetting;
    if (customSettings?.setDefaultToCombine?.enabled) {
      if (customSettings?.setDefaultToCombine?.combineAllProducts) {
        let notSettingProductIds = [];
        oms.product.products.forEach((productInfo) => {
          let id = productInfo._id;
          //id가 settings에 없으면 default setting 추가
          if (settings.some((setting) => setting.productIds.includes(id))) {
            return;
          }
          notSettingProductIds.push(id);
        });
        if (notSettingProductIds.length > 0) {
          settings.push({
            productIds: notSettingProductIds,
            combinationType: 'maxUnits',
            condition: '999999999g',
          });
        }
      } else {
        //setting에 없는 product들에 대해 default setting을 추가해준다.
        oms.product.products.forEach((productInfo) => {
          let id = productInfo._id;
          //id가 settings에 없으면 default setting 추가
          if (settings.some((setting) => setting.productIds.includes(id))) {
            return;
          }

          //id에 해당하는 유닛들
          let condition = {};
          productInfo?.units.forEach((unit) => {
            condition[unit.unit] = 99999999;
          });
          if (!isValid(condition)) {
            condition[''] = 99999999;
          }
          settings.push({
            productIds: [id],
            combinationType: 'maxCounts',
            condition: condition,
          });
        });
      }
    }
    let combineSettings = isValid(settings) ? copy(settings) : [];

    let cnt = 0;
    combineSettings.forEach((setting) => {
      setting.productGroup = cnt.toString();
      cnt++;

      if (setting.condition['구성 없음']) {
        setting.condition[''] = setting.condition['구성 없음'];
        delete setting.condition['구성 없음'];
      }
      if (setting?.unitQuantity) {
        setting.unitQuantity[''] = setting.unitQuantity['구성 없음'];
        delete setting.unitQuantity['구성 없음'];
      }
    });
    return combineSettings;
  };

  function extractUnitQuantityBasedOnUnitName(
    unit,
    unitName,
    replacableUnits = []
  ) {
    if (isValid(replacableUnits)) {
      for (let i = 0; i < replacableUnits.length; i++) {
        const unitName = replacableUnits[i];
        // Create a regex to find a number followed by the exact unit within unit
        const regex = new RegExp('(\\d+(\\.\\d+)?)' + unitName, 'g');
        const match = regex.exec(unit);

        if (match && match[1]) {
          return Number(match[1]); // Convert the found number to a Number type and return
        }
      }
    } else {
      // Create a regex to find a number followed by the exact unit within parentheses in unit
      const regex = new RegExp('(\\d+(\\.\\d+)?)' + unitName, 'g');
      const match = regex.exec(unit);

      if (match && match[1]) {
        return Number(match[1]); // Convert the found number to a Number type and return
      }
    }

    return Number(unit?.replace(/[^0-9]/g, '')) || 1; // If no matching number is found, return null
  }

  function extractUnitQuantityAndName(unit) {
    // const match = unit.match(/(\d+(?:\.\d+)?)([가-힣a-zA-Z]+)/);
    const match = unit.match(/(\d+(?:\.\d+)?)/);

    if (match) {
      const unitQuantity = match[0]; // 숫자 부분
      const unitName = unit.replace(/(\d+(?:\.\d+)?)/, ''); // 단위명

      return { unitQuantity, unitName };
    }
    return null;
  }

  const getUnitQuantity = (order, setting, limitUnitName) => {
    //orderData.units 에서 숫자만 빼내기 Ex> 20구 -> 20

    let ret = 0;
    order.data.forEach((orderData) => {
      let isUnitKilo = orderData.unit?.includes('k') ?? false;
      // let orderUnit = Number(orderData.unit.replace(/[^0-9]/g, ''));

      let replacableUnits = setting?.replacableUnits || [];
      let orderUnitQuantity = 0;
      if (setting?.unitQuantity) {
        orderUnitQuantity = setting.unitQuantity[orderData.unit];
      }
      if (!orderUnitQuantity) {
        orderUnitQuantity = extractUnitQuantityBasedOnUnitName(
          orderData.unit,
          limitUnitName,
          replacableUnits
        );

        if (isUnitKilo) {
          orderUnitQuantity *= 1000;
        }
      }
      ret += orderUnitQuantity * orderData.quantity;
    });
    return ret;
  };
  // product 찾기 과정이 완료되어 있다고 가정
  // 한 행에는 하나의 product만이 들어있다고 가정
  // 각 order.data 마다 unit, quantity, condition properties 가 존재, 값이 여기에 담겨있다고 가정
  // return 값에는 [order, [order],[order,order,...,order]] 식으로 돌아옴 (어레이로 묶인것 -> 합배송 해야할 것들)
  const combineOrder3 = (
    orders,
    userCustomOptionSettings = {
      calcPrice: {
        enabled: false,
      },
    }
  ) => {
    const newOrders = [];
    const combineSettings = getCombineSettings();

    orders.forEach((order) => {
      order.data.forEach((data) => {
        data.uniqueCode = order.uniqueCode;
      });
    });

    const addressMap = new Map();
    // groupOrdersByAddress
    let keyColumns = [];
    if (
      oms.user.setting?.combinationCustomSetting?.additionalKeyColumns?.enabled
    ) {
      keyColumns =
        oms.user.setting?.combinationCustomSetting?.additionalKeyColumns
          ?.keyColumns;
    }

    for (const order of orders) {
      let key = order.address + (order.originFile || '');
      if (keyColumns.length > 0) {
        keyColumns.forEach((column) => {
          key += order[column];
        });
      }
      if (!addressMap.has(key)) {
        addressMap.set(key, []);
      }
      addressMap.get(key).push(order);
    }
    for (const [address, ordersList] of addressMap.entries()) {
      let newOrderList = ordersList;
      let productGroups = [];

      for (const order of newOrderList) {
        // 알맞은 production group에 배치
        const setting = findAppropriateSettingByProductId(
          order.data[0]?.productId,
          combineSettings
        );

        if (
          !isValid(setting) ||
          !order.data.every(
            (data) =>
              findAppropriateSettingByProductId(
                data.productId,
                combineSettings
              ) === setting
          )
        ) {
          // 알맞은 세팅이 없을 경우
          productGroups.push({
            productGroup: undefined,
            orders: [order],
          });
          continue;
        }

        const targetProductGroup = productGroups.find(
          (group) => group.productGroup === setting.productGroup
        );
        if (isValid(targetProductGroup)) {
          targetProductGroup.orders.push(order);
        } else {
          productGroups.push({
            productGroup: setting.productGroup,
            orders: [order],
          });
        }
      }

      //productGroups에는 한 address에서 productiongroup 별로 묶인 order들이 담겨있음
      for (const group of productGroups) {
        if (group.productGroup === undefined) {
          if (!group.orders.length === 1) {
            console.log(
              'group.orders.length not 1 in combine order productGroups',
              group.orders.length
            );
          }
          newOrders.push(group.orders[0]);
          continue;
        }

        const setting = combineSettings.find(
          (setting) => setting.productGroup === group.productGroup
        );

        group.orders = separateOrdersForValidGroupOrders(
          group.orders,
          userCustomOptionSettings
        );

        if (setting.combinationType === 'maxCounts') {
          const remainOrders = {}; // 나머지들 모아놓기
          const remainOrdersData = {}; // 데이터 임시로 저장해놓기

          for (const [key, value] of Object.entries(setting.condition)) {
            remainOrders[key] = 0;
            remainOrdersData[key] = [];
          }

          group.orders.forEach((order) => {
            // 차피 order에 데이터는 1개
            let orderData = { ...order.data[0] };
            let origUnit = orderData.unit;

            let tv = order.quantity * orderData.quantity;

            let limit = setting.condition[orderData.unit];

            if (!isValid(limit)) {
              orderData.unit = Object.keys(setting.condition).find(
                (key) => orderData.unit?.includes(key) ?? false
              );

              if (isValid(orderData.unit)) {
                limit = setting.condition[orderData.unit];
              }
            }

            if (limit === undefined) {
              console.warn(
                "setting's condition does not match with order's units in useFileHandling combineOrder3",
                'option:',
                order.option,
                copy(orderData),
                copy(setting)
              );

              newOrders.push({ ...order });
              return;
            }

            while (tv > 0) {
              if (tv + remainOrders[orderData.unit] <= limit) {
                remainOrders[orderData.unit] += tv;

                remainOrdersData[orderData.unit].push({
                  ...orderData,
                  unit: origUnit,
                  quantity: tv,
                });
                tv = 0;
              } else {
                remainOrdersData[orderData.unit].push({
                  ...orderData,
                  unit: origUnit,
                  quantity: limit - remainOrders[orderData.unit],
                });

                tv -= limit - remainOrders[orderData.unit];
                remainOrders[orderData.unit] = limit;
              }

              if (remainOrders[orderData.unit] === limit) {
                const newOrder = {
                  ...order,
                  data: remainOrdersData[orderData.unit],
                };
                newOrders.push(newOrder);
                remainOrders[orderData.unit] = 0;
                remainOrdersData[orderData.unit] = [];
              }
            }
          });

          for (const [key, value] of Object.entries(remainOrders)) {
            if (remainOrders[key] !== 0) {
              // value로 변경 X
              const newOrder = {
                ...group.orders[0],
                quantity: 1,
                data: remainOrdersData[key],
              };
              remainOrders[key] = 0; // 연결된 경우 처리위함. 삭제하지 말것

              newOrders.push(newOrder);
            }
          }
        } else if (setting.combinationType === 'maxUnits') {
          let nowHold = 0;
          let limit = setting.condition;
          let { unitQuantity: limitUnitQuantity, unitName: limitUnitName } =
            extractUnitQuantityAndName(limit);
          if (isValid(limitUnitQuantity)) {
            limitUnitQuantity = Number(limit.replace(/[^0-9]/g, ''));
          }
          if (!setting?.unitQuantity) {
            let isLimitKilo = limit.includes('k');

            if (isLimitKilo) {
              limitUnitQuantity *= 1000;
            }
          }
          let oneHapOrder = {
            ...group.orders[0],
            data: [],
          };
          oneHapOrder.price = 0;

          group.orders.forEach((order) => {
            let orderUnitQuantity = getUnitQuantity(
              order,
              setting,
              limitUnitName
            );

            if (orderUnitQuantity > limitUnitQuantity) {
              newOrders.push(copy(order));
              return;
            }
            if (nowHold + 1 * orderUnitQuantity <= limitUnitQuantity) {
              oneHapOrder.data = oneHapOrder.data.concat(order.data);
              oneHapOrder.price += parseInt(order.price);
              nowHold += 1 * orderUnitQuantity;
              return;
            } else {
              let tempQuantity = 1;
              let cnt = 1;
              while (tempQuantity > 0) {
                cnt += 1;
                if (cnt > 50) {
                  console.warn('infinity loop');
                  break;
                }
                if (nowHold + orderUnitQuantity > limitUnitQuantity) {
                  newOrders.push(copy(oneHapOrder));
                  nowHold = 0;

                  oneHapOrder = {
                    ...order,
                    data: [],
                  };

                  continue;
                }
                let temp = Math.floor(
                  Math.min(
                    limitUnitQuantity - nowHold,
                    tempQuantity * orderUnitQuantity
                  ) / orderUnitQuantity
                );

                oneHapOrder.data = oneHapOrder.data.concat(
                  order.data.map((data) => ({
                    ...data,
                    quantity: data.quantity * temp,
                  }))
                );
                tempQuantity -= temp;
                nowHold += temp * orderUnitQuantity;
              }
            }
          });
          if (nowHold !== 0) {
            newOrders.push(oneHapOrder);
          }
        }
      }
    }

    //newOrders의 각원소에 combineSameOrderData를 적용
    newOrders.forEach((order, index) => {
      newOrders[index] = combineSameOrderData(order);
    });

    return newOrders;
  };

  const findAppropriateSettingByProductId = (productId, combineSettings) => {
    const appropriateSetting = combineSettings.find((setting) => {
      return setting.productIds.includes(productId);
    });
    return appropriateSetting;
  };

  // data 가 두개 이상일 경우 order를 두개로 분리
  // quantity를 데이터 내부로 옮김
  const separateOrders = (orders, settings = {}) => {
    const newOrders = [];
    orders.forEach((order) => {
      if (order.data.length > 1) {
        order.data.forEach((data) => {
          newOrders.push({
            ...order,
            data: [{ ...data }],
          });
        });
      } else {
        newOrders.push(order);
      }
    });

    newOrders.forEach((order) => {
      if (order?.data?.length === 0) {
        return;
      }

      order.data[0].quantity *= order.quantity;
      order.quantity = 1;
    });

    return newOrders;
  };

  const separateOrdersForValidGroupOrders = (orders, settings = {}) => {
    let newOrders = [];

    newOrders = orders.flatMap((order) => {
      if (order.price) {
        let newPrice = parseInt(order.price) / order.quantity;

        return Array(order.quantity).fill(
          copy({
            ...order,
            price: newPrice,
            quantity: 1,
          })
        );
      }

      return order;
    });

    let tempNewOrders = [];
    if (settings?.calcPrice?.enabled) {
      newOrders.forEach((order) => {
        if (parseInt(order.price) !== parseInt(order.price)) {
          tempNewOrders.push(order);
        }
        let divideCnt = gcd(
          order.data.map((data) => data.quantity).concat(parseInt(order.price))
        );
        if (divideCnt === 1) {
          tempNewOrders.push(order);
        } else {
          Array(divideCnt)
            .fill(0)
            .forEach((_, index) => {
              tempNewOrders.push({
                ...order,
                data: order.data.map((data) => ({
                  ...data,
                  quantity: data.quantity / divideCnt,
                })),
                price: parseInt(order.price) / divideCnt,
              });
            });
        }
      });
    } else {
      newOrders.forEach((order) => {
        if (order.data.length > 1) {
          order.data.forEach((data) => {
            tempNewOrders.push({
              ...order,
              price: 0,
              data: [{ ...data }],
            });
          });
        } else {
          tempNewOrders.push(order);
        }
      });
    }

    return tempNewOrders;
  };

  /**한 Order의 데이터 내에서 같은 product, 같은unit 인 데이터를 합친다. */
  const combineSameOrderData = (oldOrder) => {
    let order = copy(oldOrder);
    const dataMap = new Map();
    for (const data of order.data) {
      const key = `${data.productId}${data.unit}`;
      if (!dataMap.has(key)) {
        dataMap.set(key, { ...data });
      } else {
        const targetData = dataMap.get(key);
        targetData.quantity += data.quantity;
        targetData.uniqueCode += `,${data.uniqueCode}`;
      }
    }

    const newData = [];
    //중복되지 않게 uniquecode모으기
    let uniqueCodeSet = new Set();
    for (const [key, value] of dataMap.entries()) {
      newData.push(value);
      value.uniqueCode = value.uniqueCode.split(',').forEach((code) => {
        uniqueCodeSet.add(code);
      });
    }
    order.uniqueCode = Array.from(uniqueCodeSet).join(',');

    return { ...order, data: newData };
  };

  /** address가 같은것이 여러개거나 uniquecode가 두개이상인 것의 isRearragedOrder를 true로 */
  const hightlightCombinedOrders = (orders) => {
    const addressMap = new Map();
    for (const order of orders) {
      if (!addressMap.has(order.address)) {
        addressMap.set(order.address, []);
      }
      addressMap.get(order.address).push(order);
    }
    for (const [address, ordersList] of addressMap.entries()) {
      if (ordersList.length > 1) {
        ordersList.forEach((order) => {
          order.isMultipleAddress = true;
        });
      }
    }

    orders.forEach((order) => {
      if (order.uniqueCode.split(',').length > 1) {
        order.isCombinedOrder = true;
      }
    });

    orders.forEach((order) => {
      if (
        oms.user.setting?.excelVisualSettings
          ?.isIncludedSpecialCharacterInName &&
        /[^\uAC00-\uD7A3a-zA-Z\s]/.test(order.name)
      ) {
        // 특수문자를 포함할 경우
        order.isIncludedSpecialCharacterInName = true;

        // 특수문자 제거
        order.name = order.name.replace(/[^\uAC00-\uD7A3a-zA-Z\s]/g, '');

        if (order.name && order.name.length < 2) {
          order.name = '고객님'; // '고객님'을 사용
        }
      }
    });

    orders.forEach((order) => {
      if (order.postCode && order.postCode.toString().length > 5) {
        order.isPostCodeOutdated = true;
      }
    });

    if (isValid(oms.user.setting?.excelVisualSettings?.isQuantityMoreThanOne)) {
      orders.forEach((order) => {
        if (order.quantity > 1) {
          order.isQuantityMoreThanOne = true;
        }
      });
    }

    return orders;
  };

  return { combineOrder3, hightlightCombinedOrders, separateOrders };
}

export default useCombineOrder;
