import { SalesChannelSupplierId } from '@sweep/contract';
import { SalesChannel, SalesChannelsSupplierMatching } from '../interface';

export function getSalesChannelSupplierMatchings(
  salesChannelSupplierIds: SalesChannelSupplierId[]
): SalesChannelsSupplierMatching[] {
  const salesChannelsBySupplierId: Record<string, SalesChannel[] | undefined> =
    {};
  const emptySupplierSalesChannels: SalesChannel[] = [];

  salesChannelSupplierIds?.forEach((salesChannelSupplierId) => {
    const supplierId = salesChannelSupplierId.supplierId;
    const salesChannel: SalesChannel =
      'partnerId' in salesChannelSupplierId
        ? { type: 'partner', id: salesChannelSupplierId.partnerId }
        : {
            type: 'shoppingMall',
            id: salesChannelSupplierId.shoppingMallId,
          };

    if (supplierId == null) {
      emptySupplierSalesChannels.push(salesChannel);
      return;
    }

    const salesChannels = salesChannelsBySupplierId[supplierId] ?? [];
    salesChannelsBySupplierId[supplierId] = [...salesChannels, salesChannel];
  });

  const matchings: SalesChannelsSupplierMatching[] = Object.entries(
    salesChannelsBySupplierId
  ).map(([supplierId, salesChannels]) => ({
    supplierId,
    salesChannels: salesChannels ?? [],
  }));

  if (emptySupplierSalesChannels.length > 0) {
    matchings.push({
      salesChannels: emptySupplierSalesChannels,
      supplierId: null,
    });
  }

  return matchings;
}
