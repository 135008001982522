import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { toast } from 'sonner';
import { RegisterProductDTO } from '@sweep/contract';
import { Button } from 'src/design-system/components/Button';
import { Modal } from 'src/design-system/components/Modal';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { ProductFormStore } from '../../ProductFormStore';
import { getNormalizedProduct } from '../../services/getNormalizedProduct';
import { isDuplicatedOption } from '../../services/isDuplicatedOption';
import { isExistEmptySalesChannel } from '../../services/isExistEmptySalesChannel';
import OptionInputSection from '../common/OptionInputSection';
import ProductNameInput from '../common/ProductNameInput';
import ProductSettingInput from '../common/ProductSettingInput';
import SupplierInputSection from '../common/SupplierInputSection';
import { openProductCreateFormCloseDialog } from './openProductCreateFormCloseDialog';

interface ProductCreateFormModalProps {
  open: boolean;
  onSubmit: (product: RegisterProductDTO | null) => void;
  productName?: string;
}

function ProductCreateFormModal({
  open,
  onSubmit: givenOnSubmit,
  productName,
}: ProductCreateFormModalProps) {
  const oms = useOMSStore();
  const initialProduct: RegisterProductDTO = {
    productName: productName ?? '',
    units: [{ unit: '' }],
  };
  const [store] = useState(() => new ProductFormStore(oms, initialProduct));

  const onClose = async () => {
    const isClose = await openProductCreateFormCloseDialog();
    if (!isClose) {
      return;
    }
    givenOnSubmit(null);
  };

  const onSubmit = () => {
    if (isExistEmptySalesChannel(store.salesChannelSupplierMatchings)) {
      store.setShowSupplierError(true);
      toast.error('판매처를 선택해주세요.');
      return;
    }

    const normalizedProduct = getNormalizedProduct(
      store.product,
      store.salesChannelSupplierMatchings
    );

    if (normalizedProduct.productName === '') {
      toast.error('상품명을 입력해주세요.');
      return;
    }

    if (
      store.isDuplicatedProductName ||
      isDuplicatedOption(normalizedProduct)
    ) {
      toast.error('중복된 상품명 혹은 옵션이 있습니다.');
      return;
    }

    givenOnSubmit(normalizedProduct);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: false, escapeKey: false }}
      className="max-h-720px"
    >
      <Modal.Title>상품 등록하기</Modal.Title>
      <ProductSettingInput store={store} className="mb-24px" />
      <div className="gap-8px pb-52px flex flex-col overflow-y-auto">
        <ProductNameInput store={store} />
        <OptionInputSection store={store} />
        {store.useSupplierByOption !== true && (
          <SupplierInputSection store={store} className="mt-24px" />
        )}
      </div>
      <div className="gap-12px flex-center flex">
        <Button className="w-200px" onClick={onSubmit}>
          등록하기
        </Button>
      </div>
    </Modal>
  );
}

export default observer(ProductCreateFormModal);
