import axios from 'axios';
import { BACKEND_URL } from 'src/env';
import LoadingStore from '../stores/LoadingStore';
import axiosInstance from './axiosInstance';
import { isValid } from './utils';

class BackendApi {
  async loginUser(loginData) {
    try {
      const url = `${BACKEND_URL}/user/login`;

      const {
        data: { data },
      } = await axios.post(url, { loginData });

      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  }

  async initializeUserData() {
    try {
      const url = '/user/initialize';

      const {
        data: { data },
      } = await axiosInstance().get(url);

      return data;
    } catch (err) {
      console.error('Error initializing user data:', err);
      throw err;
    }
  }

  async fetchServiceVersion() {
    try {
      const url = '/version';

      const {
        data: { version },
      } = await axiosInstance().get(url);

      if (isValid(version)) {
        return version;
      } else {
        return null;
      }
    } catch (err) {
      console.error('Error fetching service version:', err);
      throw err;
    }
  }

  async saveOrderData(orderData, customizedData = []) {
    try {
      const url = '/order/save-order-data';
      const res = await axiosInstance().post(url, {
        orderData,
        customizedData,
      });

      return res;
    } catch (err) {
      console.error('Error saving order metadata:', err);
      throw err;
    }
  }

  async updateCustomizedOrders(ordersWithCustomUniqueCode) {
    try {
      const url = '/order/custom-orders-update';
      const res = await axiosInstance().post(url, {
        orders: ordersWithCustomUniqueCode,
      });

      return res;
    } catch (err) {
      console.error('Error updating stored order:', err);
      throw err;
    }
  }

  async updateCustomSetting(customSetting) {
    try {
      const url = '/user/custom-excel/custom-setting';
      const res = await axiosInstance().put(url, {
        customSetting,
      });

      return res;
    } catch (err) {
      console.error('Error updating combination setting:', err);
      throw err;
    }
  }

  async getDailySettlementData() {
    try {
      const url = '/settlement/get/daily-settlement';
      const res = await axiosInstance().get(url);

      return res.data;
    } catch (err) {
      console.error('Error fetching daily settlement data:', err);
      throw err;
    }
  }

  async getPriceInfo() {
    try {
      const url = '/product/price-list';
      const res = await axiosInstance().get(url);

      return res.data;
    } catch (err) {
      console.error('Error fetching price list:', err);
      throw err;
    }
  }

  async updatePriceInfo(priceList) {
    try {
      const url = '/product/price/update';
      const res = await axiosInstance().put(url, {
        priceList,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating price list:', err);
      throw err;
    }
  }

  async saveDailySettlementSummaryData(data) {
    try {
      const url = '/settlement/save/daily-sales';
      const res = await axiosInstance().post(url, {
        data,
      });

      return res.data;
    } catch (err) {
      console.error('Error saving daily sales summary data:', err);
      throw err;
    }
  }

  async saveSettlementData(data) {
    try {
      const url = '/settlement/save';
      const res = await axiosInstance().post(url, {
        data,
      });

      return res.data;
    } catch (err) {
      console.error('Error saving daily sales summary data:', err);
      throw err;
    }
  }

  async registerPartner(partnerInfo) {
    try {
      const url = '/partner/register';
      const res = await axiosInstance().post(url, {
        partnerInfo,
      });

      return res;
    } catch (err) {
      console.error('Error registering partner:', err);
      throw err;
    }
  }

  async registerSupplier(info) {
    try {
      const url = '/supplier/register';
      const res = await axiosInstance().post(url, {
        info,
      });

      return res.data;
    } catch (err) {
      console.error('Error registering supplier:', err);
      throw err;
    }
  }

  async registerCombinationSetting(combinationSetting) {
    try {
      const url = '/user/setting/order-combination/register';
      const res = await axiosInstance().post(url, {
        combinationSetting,
      });

      return res.data;
    } catch (err) {
      console.error('Error registering order combination setting:', err);
      throw err;
    }
  }

  async updatePartner(partnerId, partnerInfo) {
    try {
      const url = '/partner/update';
      const res = await axiosInstance().put(url, {
        partnerId,
        partnerInfo,
      });

      return res;
    } catch (err) {
      console.error('Error updating partner info:', err);
      throw err;
    }
  }

  // savepoint
  async updateSupplier(id, info) {
    try {
      const url = '/supplier/update';
      const res = await axiosInstance().put(url, {
        id,
        info,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating supplier info:', err);
      throw err;
    }
  }

  async updateCombinationSetting(settingId, combinationSetting) {
    try {
      const url = '/user/setting/order-combination/update';
      const res = await axiosInstance().put(url, {
        settingId,
        combinationSetting,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating order combination setting:', err);
      throw err;
    }
  }

  async deletePartner(partnerId) {
    try {
      const url = '/partner/delete';
      const res = await axiosInstance().put(url, {
        partnerId,
      });

      return res;
    } catch (err) {
      console.error('Error deleting partner info:', err);
      throw err;
    }
  }

  async deleteSupplier(id) {
    try {
      const url = '/supplier/delete';
      const res = await axiosInstance().put(url, {
        id,
      });

      return res.data;
    } catch (err) {
      console.error('Error deleting supplier info:', err);
      throw err;
    }
  }

  async deleteCompositionMatching(compositionId) {
    try {
      const url = '/composition-matching/delete';
      const res = await axiosInstance().put(url, {
        compositionId,
      });

      return res.data;
    } catch (err) {
      console.error('Error deleting composition:', err);
      throw err;
    }
  }

  async updateCompositionMatching(composition) {
    try {
      const url = '/composition-matching/update';
      const res = await axiosInstance().put(url, {
        composition,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating composition:', err);
      throw err;
    }
  }

  async deleteCombinationSetting(settingId) {
    try {
      const url = '/user/setting/order-combination/delete';
      const res = await axiosInstance().put(url, {
        settingId,
      });

      return res.data;
    } catch (err) {
      console.error('Error deleting order combination setting:', err);
      throw err;
    }
  }

  async uploadShippingInfo(shippingInfos) {
    try {
      const url = '/order/upload-shipping-infos';
      const {
        data: { data },
      } = await axiosInstance().post(url, {
        shippingInfos,
      });

      return data;
    } catch (err) {
      console.error('Error uploading shipping info:', err);
      throw err;
    }
  }

  async retailerUploadShippingInfo(files) {
    try {
      const url = '/order/retailer/upload-shipping-info';
      let formData = new FormData();

      // files 배열의 각 파일을 formData에 추가
      Array.from(files).forEach((file) => {
        formData.append(`files`, file);
      });

      const {
        data: { data },
      } = await axiosInstance().post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    } catch (err) {
      console.error('Error uploading shipping info:', err);
      throw err;
    }
  }

  async supplierUploadShippingInfo(files) {
    try {
      const url = '/order/supplier/upload-shipping-info';
      let formData = new FormData();

      // files 배열의 각 파일을 formData에 추가
      Array.from(files).forEach((file) => {
        formData.append(`files`, file);
      });

      const {
        data: { data },
      } = await axiosInstance().post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    } catch (err) {
      console.error('Error uploading shipping info for supplier:', err);
      throw err;
    }
  }

  async _downloadFile(filePath) {
    try {
      const fileName = filePath.split('/').pop();
      const url = `order/download/${fileName}`;
      const res = await axiosInstance().get(url, { responseType: 'blob' });

      const blob = res.data;

      const urlObject = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = urlObject;
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }

  async splitOrders(uniqueCodes) {
    try {
      const url = '/order/split';
      const res = await axiosInstance().post(url, {
        uniqueCodes,
      });

      // 해당 API 호출의 결과로, BE 서버에, zip 파일이 만들어짐
      const zipFilePath = res.data.zipFilePath;
      const dispatchedFromSweep = res.data.dispatchedFromSweep;

      // 그 zip 파일 path를 가지고, BE에 접근해서 다운로드하는 로직을 실행
      await this._downloadFile(zipFilePath);
      return dispatchedFromSweep;
    } catch (err) {
      console.error('Error splitting orders:', err);
      throw err;
    }
  }

  async updateCustomExcelSettings(columnOrder, columnTranslation) {
    try {
      const url = '/user/custom-excel';
      const res = await axiosInstance().put(url, {
        columnOrder,
        columnTranslation,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating custom excel format:', err);
      throw err;
    }
  }

  async getBillingResult(customerKey, authKey, planType) {
    try {
      const url = '/billing/register-billing-key';
      const res = await axiosInstance().get(url, {
        params: { customerKey, authKey, planType },
      });

      return res;
    } catch (err) {
      console.error('Error fetching shopping mall orders:', err);
      return false;
    }
  }

  async modifyBillingInfo(planType) {
    try {
      const url = '/billing/modify-billing-info';
      const res = await axiosInstance().post(url, {
        planType,
      });

      return res;
    } catch (err) {
      console.error('Error fetching shopping mall orders:', err);
      return false;
    }
  }

  async updateShippingInfoToMall(infoArray) {
    try {
      const url = '/fulfill-order/fulfill-orders';
      const res = await axiosInstance().post(url, {
        infoArray,
      });

      return res.data;
    } catch (err) {
      window.alert(
        '배송정보 업데이트에 실패했습니다. 관리자에게 문의해주세요!'
      );
      console.error('Error fulfill order:', err);
      throw err;
    }
  }

  async autoUpdateShippingInfoToMall(infoArray) {
    try {
      const url = '/fulfill-order/auto-fulfill-orders';
      const res = await axiosInstance().post(url, {
        infoArray,
      });

      return res.data;
    } catch (err) {
      window.alert(
        '배송정보 업데이트에 실패했습니다. 관리자에게 문의해주세요!'
      );
      console.error('Error auto fulfill order:', err);
      throw err;
    }
  }

  async unprotectXlsx(file, password) {
    try {
      const url = '/fastapi/unprotect/xlsx';
      const formData = new FormData();
      formData.append('xlsx_file', file);
      formData.append('password', password);
      formData.append('filename', file.name.normalize('NFC'));
      formData.append('isProtected', true);
      const response = await axiosInstance().post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log(
          `${file.name.normalize('NFC')} 파일 업로드 성공 및 암호 해제 완료`
        );
        return response.data;
      }
      throw new Error('파일 업로드에 실패했습니다.');
    } catch (err) {
      console.error('Error unprotecting xlsx files:', err);
      throw err;
    }
  }

  async readXlsx(file) {
    try {
      const url = '/fastapi/unprotect/xlsx';
      const formData = new FormData();
      formData.append('xlsx_file', file);
      formData.append('filename', file.name.normalize('NFC'));
      formData.append('isProtected', false);

      const response = await axiosInstance().post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(`${file.name.normalize('NFC')} 파일 업로드 성공`);
      return response.data;
    } catch (err) {
      console.error('Error reading xlsx files:', err);
      throw err;
    }
  }

  async uploadAddressExcel(phoneNumber, email, file) {
    try {
      const url = '/fastapi/correct_excel';
      const formData = new FormData();
      formData.append('xlsx_file', file);
      formData.append('filename', file.name.normalize('NFC'));
      formData.append('phoneNumber', phoneNumber);
      formData.append('email', email);
      LoadingStore.setIsLoading(true);
      const response = await axiosInstance().post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'arraybuffer',
      });
      LoadingStore.setIsLoading(false);

      if (response.status === 200) {
        console.log(`${file.name.normalize('NFC')} 파일 업로드 성공`);
        return response.data;
      }
      throw new Error('파일 업로드에 실패했습니다.');
    } catch (err) {
      console.error('Error uploading address excel:', err);
      throw err;
    }
  }

  async uploadOrders(orders) {
    try {
      const url = '/fastapi/correct_orders';
      const response = await axiosInstance().post(url, orders);

      if (response.status === 200) {
        console.log(`${orders.length}개의 orders 업로드 성공`);
        return response.data;
      }
      throw new Error('orders 업로드에 실패했습니다.');
    } catch (err) {
      console.error('Error uploading orders files:', err);
      throw err;
    }
  }

  async customerOpinionRegister(opinion, email) {
    try {
      const url = '/customer-opinion/register';
      const res = await axiosInstance().post(url, {
        data: opinion,
        email: email,
      });
      return res.data;
    } catch (err) {
      console.error('Error registering customer opinion:', err);
      throw err;
    }
  }
}

export default new BackendApi();
