import { Workbook } from 'exceljs';
import { Order } from '@sweep/contract';
import { ColumnMapping } from 'src/models/ColumnMapping';
import { findHeaderKeys } from 'src/services/column-mapping/findHeaderKeys';
import { OMSStore } from 'src/stores/OMSStore';
import { addWorkSheet } from './addWorkSheet';
import { createOrderExcelFromTemplate } from './createOrderExcelFromTemplate';

export async function createOrderExcelBuffer(
  oms: OMSStore,
  orders: Order[],
  fileName: string,
  headers: string[],
  columnMapping: ColumnMapping,
  customizedSettings: any = {}
): Promise<ArrayBuffer | null> {
  if (orders.length === 0) {
    return null;
  }

  const headerKeys =
    headers.length === 0
      ? oms.user.excelHeaderKeys
      : (findHeaderKeys(headers, columnMapping) as string[]);

  if (headers.length === 0) {
    columnMapping = oms.user.excelColumnMapping;
  }

  if (customizedSettings?.xlsxTemplateSetting?.enabled === true) {
    return createOrderExcelFromTemplate(
      headers,
      columnMapping,
      orders,
      customizedSettings?.xlsxTemplateSetting
    );
  }

  const workbook = addWorkSheet(oms, new Workbook(), orders, fileName, {
    headerKeys,
    columnMapping,
  });

  return workbook.xlsx.writeBuffer();
}
