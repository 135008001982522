import { observer } from 'mobx-react-lite';
import { Fragment, useRef, useState } from 'react';
import { Order } from '@sweep/contract';
import { cva } from '@sweep/tailwind';
import { If, useIsOverflow } from '@sweep/utils';
import { Button } from 'src/design-system/components/Button';
import SearchInput from 'src/design-system/components/SearchInput/SearchInput';
import { amplitude } from 'src/third-parties/amplitude';
import { useFilterField } from '../hooks/useFilterField';
import { useSort } from '../hooks/useSort';
import { useTableFilterDialog } from '../hooks/useTableFilterDialog';
import { TableFilterStore } from '../TableFilterStore';
import TableFilterDropdownItem from './TableFilterDropdownItem';
import FilterTriggerButton from './TableFilterTrigger';

const CHECK_ALL_TEXT = '모두 선택';
const CHECK_SEARCH_ALL_TEXT = '모든 검색 결과 선택';
const BLANK_TEXT = '(공백)';

interface TableFilterDropdownProps {
  store: TableFilterStore;
  orders: Order[];
  headerKey: string;
}

function TableFilterDropdown({
  store,
  orders,
  headerKey,
}: TableFilterDropdownProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(scrollRef, 'y');

  const [open, setOpen] = useState(false);
  const { floatingStyles, getFloatingProps, refs } = useTableFilterDialog({
    open,
    onOpenChange: setOpen,
    reference: ref,
  });

  const { isASC, isDESC, onASCClick, onDESCClick } = useSort(store, headerKey);

  const [search, setSearch] = useState('');
  const handleSearch = (newSearch: string) => {
    amplitude.track('Search Filter', {
      Term: newSearch,
    });
    setSearch(newSearch);
  };

  const {
    fields,
    isFiltered,
    isNotChecked,
    onFieldClick,
    isNotAllChecked,
    onAllFieldClick,
  } = useFilterField({ store, orders, headerKey, search });

  const ALL_TEXT = search === '' ? CHECK_ALL_TEXT : CHECK_SEARCH_ALL_TEXT;
  const fieldText = (field: string) => (field === '' ? BLANK_TEXT : field);

  const trackFilterEvent = (item: string) => {
    amplitude.track('Apply Filter', {
      Item: item,
    });
  };

  const onAllFieldClickWithEvent = () => {
    onAllFieldClick();
    trackFilterEvent(ALL_TEXT);
  };

  const onFieldClickWithEvent = (field: string) => {
    onFieldClick(field);
    trackFilterEvent(field);
  };

  return (
    <>
      <FilterTriggerButton
        ref={ref}
        onClick={() => setOpen(!open)}
        active={isFiltered}
      />
      <If is={open}>
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className={container()}
        >
          <div className="gap-8px flex">
            <Button
              color={isASC ? 'blue' : 'lightGray'}
              variant="line"
              className="flex-1"
              onClick={onASCClick}
            >
              오름차순
            </Button>
            <Button
              color={isDESC ? 'blue' : 'lightGray'}
              variant="line"
              className="flex-1"
              onClick={onDESCClick}
            >
              내림차순
            </Button>
          </div>
          <hr />
          <div className="gap-8px flex flex-col">
            <p className="text-medium-s text-gray-500">필터</p>
            <SearchInput
              value={search}
              onChange={handleSearch}
              placeholder="검색"
            />
            <div ref={scrollRef} className={filterContainer()}>
              <TableFilterDropdownItem
                checked={!isNotAllChecked}
                onClick={onAllFieldClickWithEvent}
                className="text-gray-400"
              >
                {ALL_TEXT}
              </TableFilterDropdownItem>
              <hr />
              {fields.map((field, index) => (
                <Fragment key={field}>
                  <TableFilterDropdownItem
                    checked={!isNotChecked(field)}
                    onClick={() => onFieldClickWithEvent(field)}
                  >
                    {fieldText(field)}
                  </TableFilterDropdownItem>
                  {(!isOverflow || index !== fields.length - 1) && <hr />}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </If>
    </>
  );
}

const container = cva(
  'p-16px w-280px rounded-8px gap-12px flex flex-col overflow-auto border border-gray-300 bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.12)]'
);

const filterContainer = cva(
  'rounded-8px h-242px flex flex-col overflow-auto border border-gray-300'
);

export default observer(TableFilterDropdown);
