import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { DispatchOrderStore } from 'stores/order/DispatchOrderStore';
import { DispatchedOrder } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { getDispatchOrdersRequestId } from '../services/getDispatchedOrdersRequestId';
import { pollingDispatchedOrders } from '../services/pollingDispatchedOrders';

export type LoadingState = 'loading' | 'success' | 'fail';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const DAY = MINUTE * 60 * 24;
const MONTH = DAY * 30;

export class DispatchModalStore {
  currentLoadingShoppingMallNames: string[] | null = null;
  loadings: Record<string, LoadingState | undefined> = {};
  isDispatchLoading = false;
  isTransformLoading = false;

  get isSuccessful() {
    return this.shoppingMallNames.every(
      (name) => this.loadings[name] === 'success'
    );
  }

  get failedShoppingMallNames() {
    return Object.entries(this.loadings)
      .filter(([_, status]) => status === 'fail')
      .map(([name]) => name);
  }

  get successedShoppingMallNames() {
    return Object.entries(this.loadings)
      .filter(([_, status]) => status === 'success')
      .map(([name]) => name);
  }

  constructor(
    private dispatch: DispatchOrderStore,
    private shoppingMallNames: string[]
  ) {
    makeObservable(this, {
      currentLoadingShoppingMallNames: observable,
      loadings: observable,
      isDispatchLoading: observable,
      isTransformLoading: observable,

      isSuccessful: computed,
      failedShoppingMallNames: computed,
      successedShoppingMallNames: computed,

      getDispatchedOrders: action.bound,
      getDispatchedOrdersByShoppingMallName: action.bound,
      _updatePolling: action.bound,
    });

    this.currentLoadingShoppingMallNames = shoppingMallNames;
  }

  getPollingState(shoppingMallName: string) {
    return this.loadings[shoppingMallName];
  }

  async getDispatchedOrders(
    shoppingMallNames: string[]
  ): Promise<DispatchedOrder[]> {
    this.currentLoadingShoppingMallNames = shoppingMallNames;
    this.isDispatchLoading = true;
    this.dispatch.removeRejectedShoppingMallName(shoppingMallNames);
    this.loadings = {
      ...this.loadings,
      ...Object.fromEntries(shoppingMallNames.map((name) => [name, 'loading'])),
    };

    const now = new Date().getTime();
    const orders = await Promise.all(
      shoppingMallNames.map((name) =>
        this.getDispatchedOrdersByShoppingMallName(name, now)
      )
    );

    const updatedAt = new Date().getTime();
    shoppingMallNames.forEach((name) => {
      this.dispatch.updateLastDispatchedAt(name, updatedAt);
    });

    orders.forEach((order, index) => {
      const shoppingMallName = shoppingMallNames[index];
      if (shoppingMallName == null) {
        return null;
      }
      if (order == null) {
        this.dispatch.pushRejectedShoppingMallName(shoppingMallName);
        return null;
      }

      this.dispatch.updateLastDispatchedAt(shoppingMallName, now);
    });

    const dispatchedOrders = orders.filter(isNotNil).flat();

    this.isTransformLoading = true;
    const transformedOrders =
      await this.dispatch.transformOrders(dispatchedOrders);
    this.isTransformLoading = false;

    runInAction(() => {
      this.isDispatchLoading = false;
    });

    return transformedOrders;
  }

  async getDispatchedOrdersByShoppingMallName(
    name: string,
    endTime: number
  ): Promise<DispatchedOrder[] | null> {
    const startTime = endTime - MONTH;

    this._updatePolling(name, 'loading');

    const requestId = await getDispatchOrdersRequestId(
      startTime,
      endTime,
      name
    );

    if (requestId == null) {
      this._updatePolling(name, 'fail');
      return null;
    }

    const orders = await pollingDispatchedOrders(requestId, {
      timeout: MINUTE * 5,
      interval: SECOND * 5,
    });

    if (orders == null) {
      this._updatePolling(name, 'fail');
      return null;
    }

    this._updatePolling(name, 'success');

    return orders;
  }

  _updatePolling(shoppingMallName: string, status: LoadingState) {
    this.loadings = {
      ...this.loadings,
      [shoppingMallName]: status,
    };
  }
}
