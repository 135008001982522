import { observer } from 'mobx-react-lite';
import { ForwardedRef, forwardRef } from 'react';
import { useComposition } from '@sweep/utils';
import TextInput from 'src/design-system/components/TextInput/TextInput';
import { Unit } from 'src/models/Product';
import { isNotEmptyString } from 'src/utils/string';
import DeleteButton from './DeleteButton';
import SupplierInput from './SupplierInput';

interface OptionInputProps {
  value: Unit;
  onChange: (value: Unit | null) => void;
  onAddOption: () => void;
  isDuplicated: boolean;
  useSupplierByOption: boolean;
}

function OptionInput(
  {
    value,
    onChange,
    onAddOption,
    isDuplicated,
    useSupplierByOption,
  }: OptionInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { isComposing } = useComposition();
  const handleUnitChange = (unit: string) => {
    onChange({ ...value, unit });
  };

  const handleSupplierChange = (supplierId: string) => {
    onChange({
      ...value,
      supplierId: isNotEmptyString(supplierId) ? supplierId : null,
    });
  };

  const handleDeleteClick = () => {
    onChange(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // NOTE(@이지원): 한글을 입력 중일때, 두 번 입력되는 이슈를 해결하기 위한 방어코드
    if (isComposing) {
      return;
    }

    if (event.key === 'Enter') {
      onAddOption();
    }
  };

  return (
    <div className="gap-8px flex items-center">
      <TextInput
        ref={ref}
        className="flex-1"
        placeholder="예) 30구, 1.8L"
        status={isDuplicated ? 'error' : 'default'}
        value={value.unit}
        onChange={handleUnitChange}
        onKeyDown={handleKeyDown}
        saveOnBlur
      />
      {useSupplierByOption && (
        <SupplierInput
          value={value.supplierId}
          onChange={handleSupplierChange}
        />
      )}
      <DeleteButton onClick={handleDeleteClick} />
    </div>
  );
}

export default observer(forwardRef(OptionInput));
