import { useMemo } from 'react';
import { NormalizedOrder } from '@sweep/contract';
import {
  TableFilterHeaderCell,
  useTableFilter,
} from 'src/components/TableFilter';
import { ColumnDef } from 'src/design-system/components/Table/core/interface';
import { useOMSStore } from 'src/hooks/useOMSStore';

export function useTableFilterColumns(orders: NormalizedOrder[]) {
  const oms = useOMSStore();
  const { store, filterOrders } = useTableFilter();
  const headerKeys = oms.user.excelHeaderKeys;
  const columnMapping = oms.user.excelColumnMapping;

  const columns = useMemo(
    () =>
      headerKeys.map<ColumnDef<NormalizedOrder>>((key) => ({
        header: (context) => (
          <TableFilterHeaderCell
            store={store}
            context={context}
            orders={orders}
            headerKey={key}
          >
            {columnMapping[key]}
          </TableFilterHeaderCell>
        ),
        accessorFn: (row) => row[key]?.toString() ?? '',
      })),
    [columnMapping, headerKeys, orders, store]
  );

  const filteredOrders = useMemo(
    () => filterOrders(orders),
    [filterOrders, orders]
  );

  return { columns, filteredOrders, store };
}
