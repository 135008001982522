import { overlay } from 'overlay-kit';
import { Partner } from 'src/models/Partner';
import { PartnerFormModal } from '../../PartnerFormModal';

export function openRegisterPartnerFormModal(defaultPartner: Partial<Partner>) {
  return new Promise<Omit<Partner, '_id'> | null>((resolve) =>
    overlay.open(({ isOpen, close }) => (
      <PartnerFormModal
        open={isOpen}
        type="register"
        onSubmit={async (partner) => {
          resolve(partner);
          alert('판매처 등록이 완료되었어요.');
          close();
        }}
        defaultPartner={defaultPartner}
      >
        <PartnerFormModal.InfoInput />
        <hr />
        <PartnerFormModal.ColumnMatchingInput />
      </PartnerFormModal>
    ))
  );
}
