import { action, computed, makeObservable, observable } from 'mobx';
import { NormalizedOrder } from '@sweep/contract';
import { formatDate } from '@sweep/utils';
import { TableFilterStore } from 'src/components/TableFilter';
import { replaceAllOrders } from 'src/network/order/order';
import { createOrderExcel } from 'src/services/file/excel/create';
import { OMSStore } from 'src/stores/OMSStore';
import { amplitude } from 'src/third-parties/amplitude';
import { toast } from 'src/third-parties/toast';
import { openConfirmRemoveAllOdersDialog } from '../components/dialogs/openConfirmRemoveAllOrdersDialog';
import { openConfirmRemoveOdersDialog } from '../components/dialogs/openConfirmRemoveOrdersDialog';
import { isPendingOrder } from '../services/isPendingOrder';

export class OrderPendingSectionStore {
  selectedOrders: NormalizedOrder[] = [];

  get pendingOrders() {
    return this.oms.order.normalizedOrders.filter(isPendingOrder);
  }

  constructor(
    private oms: OMSStore,
    private tableFilterStore: TableFilterStore
  ) {
    makeObservable(this, {
      selectedOrders: observable,
      pendingOrders: computed,

      setSelectedOrders: action.bound,
      removeSelectedOrders: action.bound,
    });
  }

  setSelectedOrders = (orders: NormalizedOrder[]) => {
    this.selectedOrders = orders;
  };

  download = (orders: NormalizedOrder[]) => {
    amplitude.track('Download Merged-excel');
    const filename = this.oms.user.isRetailer
      ? 'yy.MM.dd 통합엑셀'
      : 'yy.MM.dd 발주통합 (HHmm)';

    createOrderExcel(
      this.oms,
      orders,
      formatDate(new Date(), filename),
      this.oms.user.excelHeaders,
      this.oms.user.excelColumnMapping
    );
  };

  removeByFileIndex = async (index: number) => {
    const filename = this.oms.order.uploadedFileNames[index];
    const result = await this.removeByFilename(filename);
    if (result === false) {
      return;
    }

    this.setSelectedOrders([]);
    this.tableFilterStore.reset();
    toast.success('주문 파일이 삭제되었습니다.');
  };

  removeByFilename = async (filename: string): Promise<boolean> => {
    const removedNormalizedOrders = this.oms.order.normalizedOrders.filter(
      (order) => order.originFile === filename
    );
    if (removedNormalizedOrders.some((order) => order.process === 'shipping')) {
      const isConfirmed = await openConfirmRemoveOdersDialog(
        removedNormalizedOrders.length
      );
      if (!isConfirmed) {
        return false;
      }
    }

    this.oms.order.removeOrderByFilename(filename);
    // TODO(@이지원): orderStatusDivide 배포 후, removeOrderByFilename으로 이동
    await replaceAllOrders(
      this.oms.order.mergedOrders,
      this.oms.order.normalizedOrders
    );
    return true;
  };

  removeAllOrders = async () => {
    const isConfirmed = await openConfirmRemoveAllOdersDialog(
      this.oms.order.uploadedFileNames.length
    );
    if (!isConfirmed) {
      return;
    }

    this.oms.order.setMergedOrders([]);
    this.oms.order.setNormalizedOrders([]);
    await replaceAllOrders([], []);

    this.setSelectedOrders([]);
    this.tableFilterStore.reset();
    toast.success('주문 파일이 삭제되었습니다.');
  };

  removeSelectedOrders = async () => {
    const selectedOrders = this.selectedOrders;
    this.setSelectedOrders([]);
    await this.oms.loading.batch(() =>
      this.oms.order.removeOrders(selectedOrders)
    );
    this.tableFilterStore.reset();
    toast.success('주문이 삭제되었습니다.');
  };
}
