export const ORDER_SEARCH_HEADERS = [
  '쇼핑몰',
  '주문시간',
  '결제시간',
  '주문상태',
  '배송업체',
  '운송장번호',
  '상품명',
  '상품옵션',
  '상품관리코드',
  '수량',
  '주문자',
  '주문자휴대폰번호',
  '수령인',
  '수령인휴대폰번호',
  '수령인전화번호',
  '주소',
  '우편번호',
  '배송요청사항',
  '주문번호',
  '상세주문번호',
  '정산예정금액',
  '배송비',
  '상품코드(쇼핑몰)',
  '주문자id',
  '스윕고유번호',
];

export const ORDER_SEARCH_COLUMN_MAPPING = {
  shoppingMall: '쇼핑몰',
  orderDate: '주문시간',
  paymentDate: '결제시간',
  orderStatus: '주문상태',
  sweepShippingCompany: '배송업체',
  shippingNumber: '운송장번호',
  productName: '상품명',
  option: '상품옵션',
  optionCode: '상품관리코드',
  quantity: '수량',
  buyerName: '주문자',
  buyerContactNumber: '주문자휴대폰번호',
  name: '수령인',
  contactNumber: '수령인휴대폰번호',
  telephoneNumber: '수령인전화번호',
  address: '주소',
  postCode: '우편번호',
  deliveryMessage: '배송요청사항',
  orderNumber: '주문번호',
  childOrderNumber: '상세주문번호',
  price: '정산예정금액',
  shippingPrice: '배송비',
  productCode: '상품코드(쇼핑몰)',
  buyerId: '주문자id',
  uniqueCode: '스윕고유번호',
};
