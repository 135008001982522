import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { cva } from '@sweep/tailwind';
import { IconFilter, IconFilterArrow } from 'src/icons';

interface FilterTriggerButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

function TableFilterTrigger(
  { active = false, className, ...rest }: FilterTriggerButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <button ref={ref} className={container({ active, className })} {...rest}>
      {active ? (
        <IconFilter className="text-white" />
      ) : (
        <IconFilterArrow className="text-gray-500" />
      )}
    </button>
  );
}

const container = cva('rounded-4px size-18px flex-center flex', {
  variants: {
    active: {
      true: 'bg-blue-500',
      false: 'bg-gray-300',
    },
  },
});

export default observer(forwardRef(TableFilterTrigger));
