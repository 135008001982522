import { z } from 'zod';

// GET /draft
export const GET_DRAFTS_URL = '/draft';

export type GetDragtsResponse = string[];

// POST /draft
export const REGISTER_DRAFTS_URL = '/draft';

export const ZRegisterDraftsBody = z.object({
  filenames: z.string().array(),
});
export type RegisterDraftsBody = z.infer<typeof ZRegisterDraftsBody>;

// PUT /draft
export const PROCESS_DRAFTS_URL = '/draft';

export const ZProcessDraftsBody = z.object({
  filenames: z.string().array(),
});
export type ProcessDraftsBody = z.infer<typeof ZProcessDraftsBody>;
