import { Workbook } from 'exceljs';
import copy from 'fast-copy';
import { saveAs } from 'file-saver';
import readXlsxFile from 'read-excel-file';
import { NormalizedOrder, Order } from '@sweep/contract';
import { formatDate, getUnusedFilename, isNotNil, uniq } from '@sweep/utils';
import {
  replaceNormalizedOrders,
  upsertNormalizedOrders,
} from 'src/network/order/order';
import {
  CANCEL_REQUESTED_ORDER_COLUMN_MAPPING,
  CANCEL_REQUESTED_ORDER_HEADERS,
} from 'src/screens/dispatch/constants';
import { openCMRequriedDialog } from 'src/screens/order-process/components/dialogs/openCMRequiredDialog';
import { openConfirmMoveToShippingDialog } from 'src/screens/order-process/components/dialogs/openConfirmMoveToShippingDialog';
import { openUploadShippingToShoppingMall } from 'src/screens/order-process/components/dialogs/openUploadShippingToShoppingMall';
import { filterCMOrders } from 'src/screens/order-process/services/filterCMOrders';
import { getRemainedOrdersByUniqueCodeAfterCustomization } from 'src/screens/order-process/services/getRemainedOrdersByUniqueCodeAfterCustomization';
import { createOrderExcel } from 'src/services/file/excel/create';
import { COLUMN_WIDTHS } from 'src/services/file/excel/create/columnWidth';
import { StoreOriginValuePlugin } from 'src/stores/plugin/default/store-origin-value';
import { AddSupplierPlugin } from 'src/stores/plugin/features/add-supplier';
import { AddSupplierNamePlugin } from 'src/stores/plugin/features/add-supplier-name/AddSupplierNamePlugin';
import {
  CompositionMatchingPlugin,
  CompositionMatchingStage,
} from 'src/stores/plugin/features/composition-matching';
import { FormatProductNamePlugin } from 'src/stores/plugin/features/format-product-name';
import { toast } from 'src/third-parties/toast';
import { isNotEmptyString } from 'src/utils/string';
import { OrderXX, RawOrderXX } from '../models/OrderXX';
import LoadingStore from '../stores/LoadingStore';
import backendApi from '../utils/backendApi';
import { headerTranslationMap } from '../utils/mappingArrays';
import { createUniqeCode, isValid } from '../utils/utils';
import useCombineOrder from './fileHandling/interpretOrder/useCombineOrder';
import usePreprocessOrder from './fileHandling/interpretOrder/usePreprocessOrder';
import { useApplyCustomSettings } from './fileHandling/useApplyCustomSettings';
import useMallOrderManagement from './useMallOrderManagement';
import { useOMSStore } from './useOMSStore';

const EXCEL_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const useFileHandling = () => {
  const oms = useOMSStore();

  const {
    addPlatformName,
    applyJSLogic,
    applyPrefixToOption,
    applyFieldValueToAnother,
    applyMergedCellValues,
    sortOrders,
  } = useApplyCustomSettings();
  const { preprocessOrders } = usePreprocessOrder();
  const { combineOrder3, hightlightCombinedOrders, separateOrders } =
    useCombineOrder();

  const { autoUpdateShippingInfoToMall } = useMallOrderManagement();

  const normalizeOrders = async (orders: OrderXX[]) => {
    if (orders.length === 0) {
      return [];
    }

    const updatedOrders = await oms.loading.batch(async () => {
      const newOrders = await processNormalizeOrders(orders);
      const sortedOrders = sortOrders([
        ...newOrders,
        ...oms.order.normalizedOrders,
      ] as OrderXX[]);

      oms.order.setNormalizedOrders(sortedOrders as NormalizedOrder[]);
      await upsertNormalizedOrders(sortedOrders as NormalizedOrder[]);

      return sortedOrders;
    });

    return updatedOrders;
  };

  const createRetailerPurchaseOrder = async (
    orders: NormalizedOrder[]
  ): Promise<NormalizedOrder[] | null> => {
    const isConfirmed = await openConfirmMoveToShippingDialog(orders.length);
    if (!isConfirmed) {
      return null;
    }

    const remainedOrders = getRemainedOrdersByUniqueCodeAfterCustomization(
      oms.order.normalizedOrders,
      orders.map((order) => order.uniqueCodeAfterCustomization)
    );

    const { unmatchedOrders, matchedOrders } = filterCMOrders(orders);
    if (unmatchedOrders.length > 0) {
      const isMatchingConfirmed = await openCMRequriedDialog();
      if (!isMatchingConfirmed) {
        return null;
      }
    }

    const unmatchedUniqueCodes = unmatchedOrders.map(
      (order) => order.uniqueCode
    );
    const unmatchedUniqueCodeSet = new Set(unmatchedUniqueCodes);
    const unamtchedMergedOrders = oms.order.mergedOrders.filter((order) =>
      unmatchedUniqueCodeSet.has(order.uniqueCode)
    );
    oms.loading.start();
    const unmatchedNormalizedOrders = await normalizeOrdersBeforePreDivide(
      unamtchedMergedOrders
    );
    oms.loading.end();

    if (unmatchedNormalizedOrders == null) {
      return null;
    }

    const filename = formatDate(new Date(), 'yy.MM.dd 발주서 (HHmm)');
    const usedFilenames = oms.order.normalizedOrders
      .map((order) => order.purchaseOrderFile)
      .filter(isNotNil);
    const unusedFilename = getUnusedFilename(filename, usedFilenames);

    const shippingOrders = [
      ...unmatchedNormalizedOrders,
      ...matchedOrders,
    ].map<NormalizedOrder>((order) => ({
      ...order,
      purchaseOrderFile: unusedFilename,
      process: 'shipping',
    }));

    const dividedOrders = await oms.loading.batch(() =>
      oms.order.divide(shippingOrders, remainedOrders, preDivideOrders)
    );
    await oms.order.file.downloadRetailerPurchaseOrder(
      dividedOrders,
      unusedFilename
    );

    return dividedOrders;
  };

  const createSupplierPurchaseOrder = async (
    orders: NormalizedOrder[]
  ): Promise<NormalizedOrder[] | null> => {
    const remainedOrders = getRemainedOrdersByUniqueCodeAfterCustomization(
      oms.order.normalizedOrders,
      orders.map((order) => order.uniqueCodeAfterCustomization)
    );

    const preDividedOrders = await preDivideOrders(orders);

    const usedFilenames = oms.order.normalizedOrders
      .map((order) => order.purchaseOrderFile)
      .filter(isNotNil);

    const filename = formatDate(new Date(), 'yy.MM.dd 발주통합 (HHmm)');
    const unusedFilename = getUnusedFilename(filename, usedFilenames);
    const shippingOrders = preDividedOrders.map<NormalizedOrder>((order) => ({
      ...order,
      purchaseOrderFile: unusedFilename,
      process: 'shipping',
    }));
    const updatedOrders = [...shippingOrders, ...remainedOrders];

    oms.order.setNormalizedOrders(updatedOrders);
    await oms.loading.batch(() => replaceNormalizedOrders(updatedOrders));
    oms.order.file.downloadSupplierPurchaseOrder(
      preDividedOrders,
      unusedFilename
    );

    return shippingOrders;
  };

  const normalizeOrdersBeforePreDivide = async (orders: Order[]) => {
    if (orders.length === 0) {
      return [];
    }

    const newOrders = (await processNormalizeOrders(
      orders as OrderXX[],
      'pre-divide'
    )) as NormalizedOrder[];

    if (newOrders.length === 0) {
      return null;
    }

    return newOrders;
  };

  // TODO(@이지원): 합배송을 플러그인화 한 뒤에 DivideOrderStore로 이동
  const preDivideOrders = async (
    orders: NormalizedOrder[]
  ): Promise<NormalizedOrder[]> => {
    if (orders.length === 0) {
      return [];
    }
    let newOrders = copy(orders);

    const combinationCustomSetting = oms.user.setting?.combinationCustomSetting;
    const isCombine = combinationCustomSetting?.enabled;
    if (
      isCombine &&
      !combinationCustomSetting?.separateCombineProcess?.enabled
    ) {
      newOrders = separateOrders(newOrders);
      newOrders = copy(newOrders);
      if (!combinationCustomSetting?.justSeparate) {
        newOrders = combineOrder3(newOrders);
      }
    }

    newOrders = (await new AddSupplierNamePlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as NormalizedOrder[];
    newOrders = (await new FormatProductNamePlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as NormalizedOrder[];

    newOrders = newOrders.map((order) => ({
      ...order,
      uniqueCodeAfterCustomization: createUniqeCode(),
    }));

    return newOrders;
  };

  const processNormalizeOrders = async (
    orders: OrderXX[],
    stage: CompositionMatchingStage = 'normalize'
  ) => {
    if (orders.length === 0) {
      return [];
    }

    let newOrders = copy(orders);

    // TODO(@이지원): 커스텀 로직이 플러그인화 되면, default plugin으로 추가
    const storeOriginValuePlugin = new StoreOriginValuePlugin(oms, undefined);
    newOrders = (await storeOriginValuePlugin.transform(
      newOrders as Order[]
    )) as OrderXX[];

    newOrders = await preprocessOrders(newOrders);

    newOrders = (await new CompositionMatchingPlugin(oms, { stage }).transform(
      newOrders as Order[]
    )) as OrderXX[];

    // TODO(@이지원): 커스텀 로직이 플러그인화 되면, default plugin으로 추가
    newOrders = (await new AddSupplierPlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];
    newOrders = (await new AddSupplierNamePlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];

    newOrders = hightlightCombinedOrders(newOrders);
    newOrders = addPlatformName(newOrders);
    newOrders = (await new FormatProductNamePlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];
    newOrders = applyMergedCellValues(newOrders);
    newOrders = applyFieldValueToAnother(newOrders);
    newOrders = applyPrefixToOption(newOrders);
    newOrders = applyJSLogic(newOrders);

    newOrders = sortOrders(newOrders);

    newOrders = (await oms.order._normalize.normalizeOrders(
      newOrders as unknown as Order[]
    )) as unknown as OrderXX[];

    if (
      oms.user.setting?.interpreteOrderSettings?.useOptionCodeAsRawProductName
    ) {
      newOrders = newOrders.map((order) => {
        if (!order?.needToInterpret) {
          order.option = order.optionCode || order.option;
        }
        return order;
      });
    }

    newOrders = (newOrders as Order[]).map<NormalizedOrder>((order) => ({
      ...order,
      uniqueCodeAfterCustomization:
        order.uniqueCodeAfterCustomization ?? createUniqeCode(),
      process: order.process === 'shipping' ? 'shipping' : 'pending',
    })) as OrderXX[];

    return newOrders;
  };

  const splitOrders = async (orders: NormalizedOrder[]) => {
    const uniqueCodes = uniq(
      orders
        .map((order) => order.uniqueCode.split(','))
        .flat()
        .map((code) => code.trim())
        .filter(isNotEmptyString)
    );

    const dispatchedFromSweep = await oms.loading.batch(() =>
      backendApi.splitOrders(uniqueCodes)
    );
    toast.success('운송장이 입력된 주문서가 생성되었습니다.');
    if (!isValid(dispatchedFromSweep)) {
      return;
    }

    const isConfirmed = await openUploadShippingToShoppingMall();
    if (!isConfirmed) {
      return;
    }

    await oms.loading.batch(() => autoUpdateShippingInfo(dispatchedFromSweep));
  };

  interface orderUpdateResponse {
    success: boolean;
    [key: string]: any;
  }

  const autoUpdateShippingInfo = async (shippingInfos: RawOrderXX[]) => {
    const { failedOrders } = (await autoUpdateShippingInfoToMall(
      shippingInfos
    )) as {
      successOrders: orderUpdateResponse[];
      failedOrders: orderUpdateResponse[];
    };

    if (failedOrders.length === 0) {
      toast.success('쇼핑몰에 운송장이 등록되었습니다.');
      return;
    }

    toast.error(
      '운송장 등록에 실패한 주문이 있습니다. 실패 사유는 엑셀 파일을 확인해주세요.'
    );

    await createOrderExcel(
      oms,
      failedOrders as unknown as Order[],
      formatDate(new Date(), 'yy.MM.dd 운송장 등록 실패 건'),
      ['실패 사유', ...CANCEL_REQUESTED_ORDER_HEADERS],
      { failReason: '실패 사유', ...CANCEL_REQUESTED_ORDER_COLUMN_MAPPING }
    );
  };

  const uploadShippingInfo = async (file: any) => {
    try {
      // 추가해야 하는 로직 - Header에 additonalShippingFee가 있으면 해당 정보까지 같이 업데이트치는 로직
      LoadingStore?.setIsLoading(true);
      await readXlsxFile(file).then(async (rows) => {
        const headers = rows[0];
        const shippingHeaders = [
          'shippingCompany',
          'shippingNumber',
          'uniqueCode',
        ];

        const customHeaderTranslationMap: any =
          oms.user.setting?.columnTranslation || headerTranslationMap;

        const englishHeaders = headers.map((header) => {
          const matchingKey = Object.keys(customHeaderTranslationMap).find(
            (key) => customHeaderTranslationMap[key] === header
          );
          return matchingKey || header;
        });

        const hasShippingHeaders = shippingHeaders.every((header) =>
          englishHeaders.includes(header)
        );

        if (!hasShippingHeaders) {
          alert(
            `업로드하시는 엑셀의 제목행에 택배사, 운송장번호, 스윕고유번호가 있는지 확인해 주세요.\n스윕고유번호가 없는 경우, 통합 엑셀을 다시 발주 양식으로 분리할 수 없어요.\n스윕고유번호를 엑셀 양식에 등록하고 싶으신 경우 관리자에게 문의해 주세요.`
          );

          return false;
        }

        const shippingInfoArray = [];
        const failedRows = [];

        const shippingInfoHeaders = shippingHeaders;

        if (englishHeaders.includes('additionalShippingFee')) {
          shippingInfoHeaders.push('additionalShippingFee');
        }

        for (let i = 1; i < rows.length; i++) {
          let row: {
            [key: string]: any;
          } = rows[i];
          const shippingObj: {
            [key: string]: any;
          } = {};

          let isRowValid = true;

          shippingInfoHeaders.forEach((header) => {
            const index = englishHeaders.indexOf(header);

            if (
              ((header === 'shippingNumber' || header === 'uniqueCode') &&
                row[index] == null) ||
              row[index] === ''
            ) {
              isRowValid = false;
              console.warn(`Row ${i + 1} has missing values for ${header}.`);
              row = {
                ...row,
                failReason: `${customHeaderTranslationMap[header]}의 내용이 없어요.`,
              };
            } else {
              shippingObj[header] = row[index];
            }
          });

          if (isRowValid) {
            shippingInfoArray.push(shippingObj);
          }
          if (!isRowValid) {
            failedRows.push(row);
          }
        }

        if (shippingInfoArray.length > 0) {
          const result = await backendApi.uploadShippingInfo(shippingInfoArray);

          const uploadFailedUniqueCodes = result.failedRows.map(
            (row: any) => row.uniqueCode
          );

          if (isValid(uploadFailedUniqueCodes)) {
            // 실패한 행만 필터링

            const uniqueCodeIndex = englishHeaders.indexOf('uniqueCode');
            let uploadFailedRows = rows.filter((row) =>
              uploadFailedUniqueCodes.includes(row[uniqueCodeIndex])
            );

            // 실패 이유를 추가
            uploadFailedRows = uploadFailedRows.map((row) => {
              const maxKey = Math.max(...Object.keys(row).map(Number));
              return {
                ...row,
                [maxKey + 1]: '발주 통합 시 수합된 주문이 아니에요.',
              };
            });

            failedRows.push(...uploadFailedRows);
          }

          if (failedRows.length > 0) {
            alert(
              `운송장 입력에 실패한 주문건이 있습니다. 자세한 내용은 생성된 엑셀 파일 및 파일의 '실패사유' 열을 확인해주세요.`
            );
            createExcelForFailedRows(failedRows);
          }
        } else {
          alert('올바른 데이터가 없습니다.');
        }
      });
      LoadingStore?.setIsLoading(false);
      return true;
    } catch (err) {
      console.error('Error -ing ... :', err);
      LoadingStore?.setIsLoading(false);
      throw err;
    }
  };

  const createExcelForFailedRows = async (failedRows: any[]) => {
    // 현재 날짜를 YYYY.MM.DD 형식으로 가져오기
    const date = new Date();
    const year = String(date.getFullYear()).slice(-2);
    const formattedDate = `${year}.${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}.${String(date.getDate()).padStart(2, '0')}`;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      `${formattedDate} 운송장등록실패주문`
    );

    const koreanHeaderNames = [...oms.user.excelHeaderKeys, '실패사유'].map(
      (col) => oms.user.excelColumnMapping[col] || col
    );

    const headerRow = worksheet.addRow(koreanHeaderNames);

    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
      };
    });

    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    worksheet.autoFilter = {
      from: 'A1',
      to: `${String.fromCharCode(64 + oms.user.excelHeaderKeys.length)}1`,
    };

    worksheet.columns = oms.user.excelHeaderKeys.map((col, index) => {
      const columnObject = {
        key: col,
        width: COLUMN_WIDTHS[col] || 20,
        hidden: false,
      };

      const hiddenColumns = ['uniqueCode'];

      const cutOffIndex = 5;

      if (index >= cutOffIndex && hiddenColumns.includes(col)) {
        columnObject.hidden = true;
      }

      return columnObject;
    });

    failedRows.forEach((row) => {
      const rowArray = Object.values(row);
      const newRow = worksheet.addRow(rowArray);

      const textColumns = [
        '주문번호',
        '상품코드',
        '연락처',
        '우편번호',
        '옵션',
        '개수',
        '운송장번호',
      ];
      textColumns.forEach((textCol) => {
        const colNum =
          oms.user.excelHeaderKeys.indexOf(
            oms.user.excelColumnMapping[textCol]
          ) + 1;
        if (colNum > 0) {
          newRow.getCell(colNum).numFmt = '@'; // '@' indicates text format
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: EXCEL_MIME_TYPE,
    });

    saveAs(blob, `${formattedDate}_운송장등록실패주문.xlsx`);
  };

  return {
    createRetailerPurchaseOrder,
    createSupplierPurchaseOrder,
    uploadShippingInfo,
    splitOrders,
    normalizeOrders,
  };
};

export default useFileHandling;
