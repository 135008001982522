import { z } from 'zod';

const ZUserDeploy = z.object({
  formatProductNamePlugin: z.boolean().optional(),
  orderStatusDivide: z.boolean().optional(),
  /** @deprecated This field is deprecated and will be removed in a future version */
  useReadExcelV2: z.boolean().optional(),
});

export type UserDeploy = z.infer<typeof ZUserDeploy>;

const ZUserCompanyType = z.enum(['retailer', 'supplier']);

export type UserCompanyType = z.infer<typeof ZUserCompanyType>;

export const ZUser = z.object({
  _id: z.string(),
  email: z.string(),
  password: z.string(),
  name: z.string(),
  companyType: ZUserCompanyType,

  dispatchPlugins: z.array(z.string()).optional(),
  mergeDispatchPlugins: z.array(z.string()).optional(),
  plugins: z.array(z.string()).optional(),

  trialEndsAt: z.string().optional(),

  deploy: ZUserDeploy.optional(),
  admin: z.boolean().optional(),
});

export type User = z.infer<typeof ZUser>;

export const ZUpdateUserDTO = ZUser.omit({ _id: true }).partial();

export type UpdateUserDTO = z.infer<typeof ZUpdateUserDTO>;
