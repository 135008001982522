import {
  GET_DRAFTS_URL,
  GetDragtsResponse,
  PROCESS_DRAFTS_URL,
  ProcessDraftsBody,
  REGISTER_DRAFTS_URL,
  RegisterDraftsBody,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import api, { APIResponse } from './api';

export async function getDrafts() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetDragtsResponse>>(GET_DRAFTS_URL)
  );

  return response?.data;
}

export async function registerDrafts(filenames: string[]) {
  const body: RegisterDraftsBody = { filenames };
  const response = await tryCatch(() => api.post(REGISTER_DRAFTS_URL, body));

  return response?.data;
}

export async function processDrafts(filenames: string[]) {
  const body: ProcessDraftsBody = { filenames };
  const response = await tryCatch(() => api.put(PROCESS_DRAFTS_URL, body));

  return response?.data;
}
