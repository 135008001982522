import { Order, UserSetting } from '@sweep/contract';

export function applyFields(
  orders: Order[],
  fields: NonNullable<UserSetting['excel']>['fields']
): Order[] {
  return orders.map((order) => {
    fields?.forEach((field) => {
      const { target, type } = field;
      if (type === 'number') {
        const stringValue = order[target]?.toString();
        if (stringValue == null) {
          return;
        }

        const numberValue = parseFloat(stringValue);
        if (isNaN(numberValue)) {
          return;
        }

        order[target] = numberValue;
        order.excelModel = {
          ...order.excelModel,
          formats: {
            ...order.excelModel?.formats,
            [target]: { type },
          },
        };
      }
    });

    return order;
  });
}
