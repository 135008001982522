import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { Product } from '@sweep/contract';
import { ExpandedCheckbox } from 'src/design-system/components/Checkbox';
import { IconEdit } from 'src/icons';
import Divider from './Divider';
import OptionSupplierCells from './OptionSupplierCells';

interface ProductTableRowProps {
  product: Product;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  onUpdateClick: () => void;
}

function ProductTableRow({
  product,
  checked,
  onCheckedChange,
  onUpdateClick,
}: ProductTableRowProps) {
  return (
    <div className="min-h-44px flex h-full">
      <div className="flex items-center">
        <ExpandedCheckbox checked={checked} onCheckedChange={onCheckedChange} />
      </div>
      <Divider type="vertical" />
      <div className="w-1/2">
        <ProductNameCell>{product.productName}</ProductNameCell>
      </div>
      <Divider type="vertical" />
      <div className="w-1/2">
        <OptionSupplierCells product={product} />
      </div>
      <Divider type="vertical" />
      <div className="flex-center w-60px flex shrink-0">
        <button
          className="flex-center h-28px w-28px flex rounded-[6px] bg-gray-100"
          onClick={onUpdateClick}
        >
          <IconEdit className="text-gray-500" />
        </button>
      </div>
    </div>
  );
}

function ProductNameCell({ children }: PropsWithChildren) {
  return (
    <div className="flex size-full items-center px-[16px]">
      <p className="text-medium-s text-gray-700">{children}</p>
    </div>
  );
}

export default observer(ProductTableRow);
