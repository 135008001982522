import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

export class LoadingStore {
  stack = 0;

  get isLoading() {
    return this.stack > 0;
  }

  constructor() {
    makeObservable(this, {
      stack: observable,

      isLoading: computed,

      start: action.bound,
      end: action.bound,
      batch: action.bound,
      reverseBatch: action.bound,

      forceStop: action.bound,
      restoreForceStop: action.bound,
    });
  }

  /**
   * @deprecated batch를 사용해주세요.
   */
  start() {
    this.stack++;
  }

  /**
   * @deprecated batch를 사용해주세요.
   */
  end() {
    this.stack--;
  }

  async batch<T>(fn: () => Promise<T>): Promise<T> {
    this.start();

    const result = await fn();

    this.end();
    return result;
  }

  async reverseBatch<T>(fn: () => Promise<T>): Promise<T> {
    const stack = this.stack;
    this.stack = 0;

    const result = await fn();

    runInAction(() => {
      this.stack = stack;
    });

    return result;
  }

  /**
   * NOTE(@이지원)
   * @deprecated reverseBatch를 사용해주세요.
   */
  forceStop() {
    const stack = this.stack;
    this.stack = 0;

    return stack;
  }

  /**
   * NOTE(@이지원)
   * @deprecated reverseBatch를 사용해주세요.
   */
  restoreForceStop(stack: number) {
    this.stack += stack;
  }
}
