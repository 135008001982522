import { CreatePartnerDTO } from 'models/Partner';
import { createContext, useContext } from 'react';

export interface PartnerFormModalContext {
  partner: CreatePartnerDTO;
  onChange: (partner: Partial<CreatePartnerDTO>) => void;
}

const DEFAULT_REGISTER_PARTNER: CreatePartnerDTO = {
  name: '',
  matchings: [],
};

export const PartnerFormModalContext = createContext<PartnerFormModalContext>({
  partner: DEFAULT_REGISTER_PARTNER,
  onChange: () => void 0,
});

export function usePartnerFormModalContext() {
  return useContext(PartnerFormModalContext);
}
