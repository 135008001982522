import { To, useLocation, useNavigate } from 'react-router-dom';
import { cva } from '@sweep/tailwind';

interface NavigationButtonProps {
  to: To;
  children: React.ReactNode;
}

const NavigationButton = ({ to, children }: NavigationButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const active = location.pathname === `/${to}`;

  return (
    <button onClick={() => navigate(to)} className={navigateButton({ active })}>
      {children}
    </button>
  );
};

const navigateButton = cva(
  'flex w-full items-center rounded-lg px-[20px] py-[8px] text-left font-semibold transition duration-300 ease-in-out hover:bg-[#F4F8FE]',
  {
    variants: {
      active: {
        true: 'bg-[#F4F8FE] font-semibold text-[#057FF0] shadow-sm',
      },
    },
  }
);

export default NavigationButton;
