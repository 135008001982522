import { CompositionMatching } from 'models/CompositionMatching';
import { OMSStore } from 'stores/OMSStore';
import { Order, OrderProduct } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { MatchedOrder } from '../interface';
import { stringifyProductNameOption } from './stringifyProductNameOption';

export function matchByProductNameOption(
  orders: Order[],
  oms: OMSStore
): MatchedOrder[] {
  const compositionMatchings = oms.cm.compositionMatchings;
  const matchingMap = new Map(
    compositionMatchings.map(
      (cm) => [stringifyProductNameOption(cm), cm] as const
    )
  );

  return orders.map((order) => {
    const result = _matchByProductNameOption(order, matchingMap, oms);
    return result;
  });
}

function _matchByProductNameOption(
  order: Order,
  matchingMap: Map<string, CompositionMatching>,
  oms: OMSStore
): MatchedOrder {
  const key = stringifyProductNameOption(order);
  const matching = matchingMap.get(key);
  if (matching == null) {
    return order;
  }

  return {
    ...order,
    plugin: {
      cm: {
        compositionMatchingId: matching._id,
      },
    },
    data: matching.data
      .map<OrderProduct | null>((cmProduct) => {
        const product = oms.product.getProduct(cmProduct.productId);
        if (product == null) {
          return null;
        }

        return {
          productId: cmProduct.productId,
          productName: product.productName,
          unit: cmProduct.unit,
          quantity: cmProduct.quantity,
        };
      })
      .filter(isNotNil),
  };
}
