import { IconArrowRightLarge } from 'icons/index';
import { cva } from '@sweep/tailwind';

function CMHeader() {
  return (
    <div className={container()}>
      <CMHeaderItem className="gap-[6px]">
        <span className="text-bold-m text-gray-600">상품명</span>
        <span className="text-medium-m text-gray-300">/</span>
        <span className='text-medium-m" text-gray-500'>옵션</span>
        <span className="text-medium-m rounded-[4px] border border-gray-300 px-[6px] text-gray-500">
          옵션코드
        </span>
      </CMHeaderItem>
      <IconArrowRightLarge className="text-gray-500" />
      <CMHeaderItem className="flex-1">
        <p className="text-bold-m text-gray-600">상품구성</p>
      </CMHeaderItem>
    </div>
  );
}

const container = cva([
  'z-[2] flex bg-white',
  'sticky top-[20px]',
  'before:absolute before:top-[-20px] before:h-[20px] before:w-full before:bg-white',
]);

interface CMHeaderItemProps {
  className?: string;
  children?: React.ReactNode;
}

function CMHeaderItem({ children, className }: CMHeaderItemProps) {
  return <div className={item({ className })}>{children}</div>;
}

const item = cva(
  'flex h-[48px] w-[320px] items-center border-b border-b-gray-200 bg-gray-100 px-5'
);

export default CMHeader;
