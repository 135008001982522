import { createContext, useContext } from 'react';
import { noop } from '@sweep/utils';

export interface DialogContextValue {
  open: boolean;
  onClose: () => void;
}

const dialogContext = createContext<DialogContextValue>({
  open: false,
  onClose: noop,
});

export const DialogContextProvider = dialogContext.Provider;

export const useDialogContext = () => useContext(dialogContext);
