import { DispatchedOrder, isHiddenStatus, OrderStatus } from '@sweep/contract';
import { OrderStatusType } from '../interface';

export function filterOrderStatus(
  orders: DispatchedOrder[],
  orderStatus: OrderStatusType
) {
  const visibleOrders = orders.filter(
    (order) => isHiddenStatus(order.orderStatus) === false
  );

  if (orderStatus === 'Total') {
    return visibleOrders;
  }

  switch (orderStatus) {
    case 'PaymentComplete':
      return visibleOrders.filter((order) =>
        isPaymentComplete(order.orderStatus)
      );
    case 'ProductPreparing':
      return visibleOrders.filter((order) =>
        isProductPreparing(order.orderStatus)
      );
    case 'InDelivery':
      return visibleOrders.filter((order) => isInDelivery(order.orderStatus));
    case 'CancelRequest':
      return visibleOrders.filter((order) =>
        isCancelRequest(order.orderStatus)
      );
    case 'Etc':
      return visibleOrders.filter((order) => isEtc(order.orderStatus));
  }
}

export function isPaymentComplete(status: OrderStatus | undefined | null) {
  if (status == null) {
    return false;
  }

  const paymentCompleteStatusList: OrderStatus[] = [
    OrderStatus.paymentComplete,
    OrderStatus.paymentCompletePartialDelivery,
  ];

  return paymentCompleteStatusList.includes(status);
}

export function isProductPreparing(status: OrderStatus) {
  const productPreparingStatusList: OrderStatus[] = [
    OrderStatus.productPreparing,
    OrderStatus.productPreparingPartialDelivery,
    OrderStatus.partialDelivery,
  ];

  return productPreparingStatusList.includes(status);
}

export function isInDelivery(status: OrderStatus) {
  return status === OrderStatus.inDelivery;
}

export function isCancelRequest(status: OrderStatus) {
  const cancelRequestStatusList: OrderStatus[] = [
    OrderStatus.cancelRequest,
    OrderStatus.cancelRequestPaymentComplete,
  ];

  return cancelRequestStatusList.includes(status);
}

export function isEtc(status: OrderStatus) {
  const etcStatusList: OrderStatus[] = [
    OrderStatus.exchange,
    OrderStatus.exchangeRequest,
    OrderStatus.exchangeInDelivery,
    OrderStatus.exchangeRedelivery,
    OrderStatus.exchangeHold,
    OrderStatus.refundRequest,
    OrderStatus.refundExpected,
    OrderStatus.refundRequestWaiting,
    OrderStatus.returnRequest,
    OrderStatus.returnInDelivery,
    OrderStatus.riskControl,
  ];

  return etcStatusList.includes(status);
}
