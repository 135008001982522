import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { NormalizedOrder } from '@sweep/contract';
import { formatDate, If } from '@sweep/utils';
import { Button } from 'src/design-system/components/Button';
import SearchInput from 'src/design-system/components/SearchInput/SearchInput';
import { SelectableTable } from 'src/design-system/components/Table/selectable/SelectableTable';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { IconControlDownload } from 'src/icons';
import { createOrderExcel } from 'src/services/file/excel/create';
import TableCount from '../components/TableCount';
import TableSelectedCount from '../components/TableSelectedCount';
import { useTableFilterColumns } from '../hooks/useTableFilterColumns';

function OrderTotalSection() {
  const oms = useOMSStore();

  const normalizedOrders = oms.order.normalizedOrders;
  const [search, setSearch, filterSearch] = useSearchState({
    keys: oms.user.excelHeaderKeys,
  });

  const searchFilteredOrders = useMemo(
    () => filterSearch(normalizedOrders),
    [filterSearch, normalizedOrders]
  );

  const { columns, filteredOrders } =
    useTableFilterColumns(searchFilteredOrders);

  const [selectedOrders, setSelectedOrders] = useState<NormalizedOrder[]>([]);
  const currentOrders =
    selectedOrders.length > 0 ? selectedOrders : filteredOrders;

  const download = () =>
    createOrderExcel(
      oms,
      currentOrders,
      formatDate(new Date(), 'yy.MM.dd 통합엑셀'),
      oms.user.excelHeaders,
      oms.user.excelColumnMapping
    );

  return (
    <div className="p-20px rounded-8px shadow-line gap-16px flex flex-col bg-white">
      <SearchInput
        value={search}
        onChange={setSearch}
        placeholder="검색어를 입력해주세요."
        className="max-w-400px"
      />
      <hr />
      <div className="flex flex-col">
        <TableCount count={filteredOrders.length} />
        <div className="flex items-end justify-between">
          <TableSelectedCount count={selectedOrders.length} />
          <Button
            color="gray"
            rightAccessory={<IconControlDownload />}
            onClick={download}
          >
            엑셀 파일
          </Button>
        </div>
      </div>
      <If is={normalizedOrders.length > 0}>
        <SelectableTable
          items={filteredOrders}
          columns={columns}
          selectedItems={selectedOrders}
          onSelect={setSelectedOrders}
          getKey={(order) => order.uniqueCodeAfterCustomization}
          className="max-h-[calc(100vh-300px)]"
        />
      </If>
    </div>
  );
}

export default observer(OrderTotalSection);
