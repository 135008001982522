import { Column, Workbook } from 'exceljs';
import { Order } from '@sweep/contract';
import { ColumnMapping } from 'src/models/ColumnMapping';
import { OMSStore } from 'src/stores/OMSStore';
import { colorDict } from 'src/utils/colorDict';
import { isValid } from 'src/utils/utils';
import { applyExcelModel } from './applyExcelModel';
import { applyFields } from './applyFields';
import { COLUMN_WIDTHS } from './columnWidth';

const STRING_HEADERS = [
  '주문번호',
  '상품코드',
  '연락처',
  '우편번호',
  '옵션',
  '개수',
  '운송장번호',
];

const STRING_FORMAT = '@';

export function addWorkSheet(
  oms: OMSStore,
  workbook: Workbook,
  orders: Order[],
  tsheetName: string,
  settings: {
    headerKeys: string[];
    columnMapping: ColumnMapping;
  }
): Workbook {
  const userExcelSetting = oms.user.setting?.excel;
  const sheetName = tsheetName.replace(/[*?:\\/\[\]]/g, ' ');

  orders = orders.map((order) => {
    Object.keys(order).forEach((key) => {
      const value = order[key];
      if (typeof value === 'number' && value.toString().length > 9) {
        order[key] = value.toString();
      }
    });

    return order;
  });

  const { columnMapping, headerKeys } = settings;

  const headers = headerKeys.map((col: string) => columnMapping[col]);
  if (oms.user.setting?.preprocessSettings?.separateAddress?.enabled === true) {
    headerKeys.push('mainAddress', 'detailAddress');
    headers.push('메인주소', '상세주소');
  }

  const worksheet = workbook.addWorksheet(`${sheetName}`);
  const headerRow = worksheet.addRow(headers);

  orders = applyFields(orders, userExcelSetting?.fields);

  const addressIndex = headerKeys.findIndex((col) => col === 'address') + 1;
  //INDEX -> AA 식으로
  if (addressIndex !== 0) {
    let addressIndexString = String.fromCharCode(64 + addressIndex);

    if (addressIndex > 26) {
      addressIndexString =
        String.fromCharCode(64 + Math.floor(addressIndex / 26)) +
        String.fromCharCode(64 + (addressIndex % 26));
    }

    if (
      oms.user.setting?.disableMultipleAddressHighlighting?.enabled !== false
    ) {
      worksheet.addConditionalFormatting({
        ref: `${addressIndexString}2:${addressIndexString}${
          orders.length + 50
        }`,
        rules: [
          {
            priority: 1,
            type: 'expression',
            formulae: [
              `COUNTIF($${addressIndexString}$2:$${addressIndexString}$${
                orders.length + 50
              },$${addressIndexString}2)>1`,
            ],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: 'FFFF00' },
              },
            },
          },
        ],
      });
    }
  }

  orders.forEach((order) => {
    const row = worksheet.addRow(
      headerKeys.map((col: string) => {
        let value = order[col];

        // name의 길이가 1이면, value에 '님'을 추가
        if (
          col === 'name' &&
          order[col] != null &&
          (order[col] as string).length === 1
        ) {
          value += '님';
        }

        if (col === 'contactNumber' && !isValid(order[col])) {
          value = order['telephoneNumber'];
        }

        if (col === 'price' || col === 'quantity' || col === 'shippingPrice') {
          value = Number(value) !== Number(value) ? value : Number(value);
        } //NaN 이면 그대로, 아니면 숫자로 변환

        return typeof value === 'string' ? value.normalize('NFC') : value;
      })
    );

    if (order.isCombinedOrder === true) {
      const addressIndex =
        headerKeys.findIndex((col: string) => col === 'address') + 1;

      row.getCell(addressIndex).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC7CD' }, // 붉은색
      };
    }

    if (order.isIncludedSpecialCharacterInName === true) {
      const nameIndex =
        headerKeys.findIndex((col: string) => col === 'name') + 1;

      if (nameIndex !== 0) {
        row.getCell(nameIndex).font = {
          color: { argb: 'FF0000' },
        };
      }
    }

    if (isValid(order.isPostCodeOutdated)) {
      const postCodeIndex =
        headerKeys.findIndex((col: string) => col === 'postCode') + 1;
      if (postCodeIndex !== 0) {
        row.getCell(postCodeIndex).font = {
          color: { argb: 'FF0000' }, // 빨간색
        };
      }
    }

    if (isValid(order.isQuantityMoreThanOne)) {
      const quantityIndex =
        headerKeys.findIndex((col: string) => col === 'quantity') + 1;
      if (quantityIndex !== 0) {
        row.getCell(quantityIndex).font = {
          color: { argb: 'FF0000' }, // 빨간색
          bold: true,
        };
      }
    }

    const contactNumberIndex = headerKeys.findIndex(
      (col: string) => col === 'contactNumber'
    );
    if (contactNumberIndex !== -1) {
      let contactNumber = row.getCell(contactNumberIndex + 1).value;
      if (typeof contactNumber === 'string') {
        contactNumber = contactNumber.replace(/-/g, '');
        // 0503, 0502 등의 안심번호
        const isSafeNumber =
          contactNumber.startsWith('050') && contactNumber.length === 12;

        if (
          !isSafeNumber &&
          (contactNumber.slice(0, 3) !== '010' ||
            (contactNumber.length !== 11 && contactNumber.length !== 10))
        ) {
          row.getCell(contactNumberIndex + 1).font = {
            color: { argb: 'FF0000' },
          };
        }
      }
    }

    const addressIndex = headerKeys.findIndex(
      (col: string) => col === 'address'
    );
    if (addressIndex !== -1) {
      const isAddressValid = order.isAddressValid;
      if (isAddressValid !== 'okay' && isAddressValid != null) {
        if (colorDict[isAddressValid] != null) {
          row.getCell(addressIndex + 1).font = {
            bold: true,
            color: { argb: colorDict[isAddressValid][0] }, // 연한 초록색, fixed 된것들
          };
        }
      }
      if (order.memo) {
        row.getCell(addressIndex + 1).note = {
          texts: order.memo,
        };
      }
    }

    STRING_HEADERS.forEach((textCol) => {
      const colNum = headers.indexOf(textCol) + 1;
      if (colNum > 0) {
        row.getCell(colNum).numFmt = STRING_FORMAT;
      }
    });

    const excelModel = order.excelModel;
    if (excelModel != null) {
      applyExcelModel(row, excelModel, headerKeys);
    }
  });

  // 엑셀 beautify
  headerRow.eachCell((cell: any) => {
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
    cell.font = { bold: true };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D9D9D9' },
    };
  });

  worksheet.autoFilter = {
    from: 'A1',
    to: `${String.fromCharCode(64 + headerKeys.length)}1`,
  };

  worksheet.columns = headerKeys.map<Partial<Column>>((headerKey) => ({
    key: headerKey,
    width: COLUMN_WIDTHS[headerKey] ?? 20,
  }));

  return workbook;
}
