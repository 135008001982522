declare global {
  interface ImportMeta {
    env: {
      VITE_NODE_ENV?: 'production' | 'development' | 'local';
      VITE_BACKEND_URL: string;
      VITE_ENABLE_API_MOCK: 'true' | 'false';
      VITE_AMPLITUDE_API_KEY: string;
      VITE_SENTRY_DSN_FOR_FRONTEND: string;
    };
  }
}

export const NODE_ENV = import.meta.env.VITE_NODE_ENV ?? 'local';
export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY;
