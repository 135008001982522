import { DispatchedOrder } from '@sweep/contract';
import { isNotEmptyString } from 'src/utils/string';

export function getBundleOrdersByCancelCode(orders: DispatchedOrder[]) {
  const bundleOrders: (DispatchedOrder | DispatchedOrder[])[] = [];
  const ordersByCancelCode = new Map<string, DispatchedOrder[]>();

  orders.forEach((order) => {
    const cancelCode = order.cancelCode?.toString();
    if (
      isNotEmptyString(cancelCode) &&
      // TODO(@이지원): 쿠팡 주문인지 판단하는 코드 변경 필요
      order.shoppingMall?.startsWith('쿠팡') === true
    ) {
      const cancelCodeOrders = ordersByCancelCode.get(cancelCode);
      cancelCodeOrders == null
        ? ordersByCancelCode.set(cancelCode, [order])
        : cancelCodeOrders.push(order);
      return;
    }

    bundleOrders.push(order);
  });

  ordersByCancelCode.keys().forEach((cancelCode) => {
    const cancelCodeOrders = ordersByCancelCode.get(cancelCode);
    if (cancelCodeOrders == null) {
      return;
    }
    bundleOrders.push(cancelCodeOrders);
  });

  return bundleOrders;
}
