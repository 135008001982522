import { cva } from '@sweep/tailwind';
import { IconCheck } from 'src/icons';

interface CheckIconProps {
  checked: boolean;
}

export function CheckIcon({ checked }: CheckIconProps) {
  return (
    <div className={container({ checked })}>
      <IconCheck />
    </div>
  );
}

const container = cva('size-18px pl-2px pt-2px shrink-0', {
  variants: {
    checked: {
      true: 'text-blue-500',
      false: 'text-gray-300',
    },
  },
});
