import { isNotNil } from '@sweep/utils';
import Button from 'components/buttons/Button';
import { useOMSStore } from 'hooks/useOMSStore';
import { IconArrowModal } from 'icons/index';
import { observer } from 'mobx-react-lite';
import { Partner } from 'models/Partner';
import { useRef, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { findEstimatedColumnMapping } from 'services/column-mapping/findEstimatedColumnMapping';
import { ExcelFile } from 'services/file/interface';
import { FileMatching } from '../interface';
import { openRegisterPartnerFormModal } from './openRegisterPartnerFormModal';
import WarningBanner from './WarningBanner';

interface PartnerNotMatchingFormProps {
  files: ExcelFile[];
  onSubmit: (
    fileMatchings: FileMatching[],
    partnersToUpdateHeader?: Partner[]
  ) => void;
}

const NEW_PARTNER = 'NEW';

function PartnerNotMatchingForm({
  files,
  onSubmit,
}: PartnerNotMatchingFormProps) {
  const oms = useOMSStore();

  const [matchingRecord, setMatchingRecord] = useState<
    Record<string, Partner | undefined>
  >({});
  const partnersToUpdateHeaderRef = useRef<Partner[]>([]);

  const isMatchingComplete =
    Object.values(matchingRecord).filter(isNotNil).length === files.length;

  const getRegisteredPartner = async (filename: string) => {
    const file = files.find((file) => file.name === filename);
    const headerRow = file?.data.at(0);

    if (file == null || headerRow == null) {
      return Promise.resolve(null);
    }

    const header = headerRow.map((cell) => cell?.toString() ?? '');
    const estimatedColumnMapping = findEstimatedColumnMapping(headerRow);

    const defaultPartner: Partial<Partner> = {
      name: filename,
      matchings: [
        {
          id: self.crypto.randomUUID(),
          header,
          columnMapping: estimatedColumnMapping,
          exampleContents: file.data.slice(1, 7),
        },
      ],
    };

    const partner = await openRegisterPartnerFormModal(defaultPartner);
    if (partner == null) {
      return null;
    }

    const registeredPartner = await oms.partner.register(partner);
    return registeredPartner;
  };

  const handlePartner = async (filename: string, partnerName: string) => {
    const isNewParnter = partnerName === NEW_PARTNER;
    const partner = isNewParnter
      ? await getRegisteredPartner(filename)
      : oms.partner.partners.find((partner) => partner.name === partnerName);

    if (partner == null) {
      return;
    }

    const prevPartners = partnersToUpdateHeaderRef.current;
    partnersToUpdateHeaderRef.current = isNewParnter
      ? prevPartners.filter((prev) => prev._id !== partner._id)
      : [...prevPartners, partner];

    setMatchingRecord({
      ...matchingRecord,
      [filename]: partner,
    });
  };

  const handleSubmit = () => {
    const matchings = files.map((file) => ({
      file,
      partner: matchingRecord[file.name]!,
    }));

    onSubmit(matchings, partnersToUpdateHeaderRef.current);
  };

  return (
    <div className="px-[40px]">
      <WarningBanner>
        파트너사를 인식할 수 없는 엑셀파일들이 있어요.
        <br />
        새로이 설정하고 싶은 파트너사를 만들거나 선택해주세요.
      </WarningBanner>

      <div className="mt-[36px] flex text-[14px]">
        <div className="flex w-full items-center bg-[#F5F7FA] px-[20px] py-[10px]">
          <p className="text-[14px] font-bold">파일명</p>
        </div>
        <div className="flex w-[60px] items-center justify-center">
          <IconArrowModal />
        </div>
        <div className="flex w-full items-center bg-[#F5F7FA] px-[20px] py-[10px]">
          <p className="text-[14px] font-bold">파트너사</p>
        </div>
      </div>

      <div className="mt-[20px]">
        {files.map((file) => (
          <Fragment key={file.name}>
            <div className="flex">
              <div className="flex w-full items-center">
                <div className="px-[20px] py-[12px]">
                  <span className="text-[14px] font-medium">
                    {file.name ?? ''}
                  </span>
                </div>
              </div>
              <div className="w-[40px]" />
              <div className="flex w-full">
                <div className="flex w-full rounded-[8px] border border-[#CED7E0] px-[16px] py-[10px]">
                  <select
                    value={matchingRecord[file.name]?.name ?? ''}
                    onChange={async (event) =>
                      await handlePartner(file.name, event.target.value)
                    }
                    className={'w-full border-white leading-none'}
                  >
                    <option value="" hidden disabled>
                      파트너사를 선택하세요
                    </option>
                    <option value={NEW_PARTNER}>새로운 파트너사 만들기</option>
                    {oms.partner.partners.map((partner) => (
                      <option key={partner._id} value={partner.name}>
                        {partner.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <hr className="my-[12px] border-[#EBF0F5]" />
          </Fragment>
        ))}
      </div>

      <div className="flex justify-end px-6">
        <Button
          name="저장"
          disabled={!isMatchingComplete}
          onClick={handleSubmit}
          className="my-4"
        />
      </div>
    </div>
  );
}

export default observer(PartnerNotMatchingForm);
