import { useId } from 'react';
import { mergeProps, useHover } from 'react-aria';
import { cva } from '@sweep/tailwind';
import { IconCheckSmall } from 'src/icons';

type DefaultProps = Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'>;

export interface CheckboxProps extends DefaultProps {
  checked: boolean;
  onCheckedChange?: (checked: boolean) => void;
  isHovered?: boolean;
}

export function Checkbox({
  checked,
  onCheckedChange,
  id: givenId,
  className,
  isHovered: givenIsHovered,
  ...rest
}: CheckboxProps) {
  const randomId = useId();
  const id = givenId ?? randomId;

  const { isHovered, hoverProps } = useHover({});
  const hover = givenIsHovered ?? isHovered;

  return (
    <label htmlFor={id} className={label({ checked, hover, className })}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        className={input()}
        onChange={(event) => onCheckedChange?.(event.target.checked)}
        {...mergeProps(rest, hoverProps)}
      />
      {checked && <IconCheckSmall className="text-white" />}
    </label>
  );
}

const label = cva(
  'relative flex size-[16px] items-center justify-center rounded-[4px]',
  {
    variants: {
      checked: {
        true: 'bg-blue-500',
        false: 'border-inner-2px-gray-300',
      },
      hover: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        checked: false,
        hover: false,
        className: 'bg-white',
      },
      {
        checked: false,
        hover: true,
        className: 'bg-gray-100',
      },
    ],
  }
);

const input = cva('absolute inset-0 cursor-pointer opacity-0');
