/**
 * @param filename 만들고자 하는 파일 이름
 * @param filenames 현재의 파일이름들
 * @returns 중복되지 않는 파일 이름
 *
 * @example
 * getUnusedFilename('name', ['name', 'name(1)', 'name(2)']) // 'name(3)'
 */
export function getUnusedFilename(filename: string, filenames: string[]) {
  let count = 0;

  const fileNameSet = new Set(filenames);

  while (fileNameSet.has(`${filename}${count ? `(${count})` : ''}`)) {
    count++;
  }

  return `${filename}${count ? `(${count})` : ''}`;
}
