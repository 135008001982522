import { overlay } from 'overlay-kit';
import DispatchSelectModal from '../DispatchSelectModal/DispatchSelectModal';

export function openDispatchSelectModal(
  selectedShoppingMalls: string[],
  onSelect: (shoppingMalls: string[]) => void
) {
  overlay.open(({ isOpen, close }) => (
    <DispatchSelectModal
      open={isOpen}
      onClose={close}
      onSubmit={onSelect}
      selectedShoppingMalls={selectedShoppingMalls}
    />
  ));
}
