import copy from 'fast-copy';
import { useEffect, useRef, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../components/buttons/Button';
import SupplierDetailHeaderMatchingComponent from './supplierForm/SupplierDetailHeaderMatchingComponent';
import SupplierExcelUpload from './supplierForm/SupplierExcelUpload';
import SupplierHeaderComponents from './supplierForm/SupplierHeaderComponents';

const SupplierForm = ({
  type = 'register',
  supplier = undefined,
  onFormSubmit,
  initialRegisterFile = { target: { files: [] } },
}) => {
  const oms = useOMSStore();
  const supplierInfoRef = useRef(null);

  const [newSupplier, setNewSupplier] = useState({ ...copy(supplier) } || {});

  useEffect(() => {
    setNewSupplier({
      file: null,
      name: '',
      email: '',
      phone: '',
      productIds: [],
      header: [],
      headerForShipping: [],
      columnMapping: {},
      exampleContents: [],
      customizedSettings: {},
      indexes: {},
      ...copy(supplier),
    });
  }, [supplier]);

  const handleSupplierInfoChange = (e) => {
    const { name, value } = e.target;
    setNewSupplier((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!initialRegisterFile) {
      return;
    }
    if (initialRegisterFile?.target?.files?.length > 0) {
      supplierInfoRef.current?.handleExcelFileUpload(initialRegisterFile);
    } else if (typeof initialRegisterFile?.[0]?.[0] === 'string') {
      supplierInfoRef.current?.handleExcelFileUploadRows(initialRegisterFile);
    }
  }, [initialRegisterFile, supplierInfoRef]);

  // isChecked 상태가 변경될 때마다 실행되는 useEffect

  const validateSupplierName = (name) => {
    const suppliers = oms.supplier.suppliers;

    if (!name) {
      alert('공급사 이름을 입력해주세요.');
      return false;
    }

    const hasNameAlreadyExisted = suppliers.some((supplier) => {
      if (type === 'update') {
        return newSupplier._id !== supplier._id && supplier.name === name;
      } else {
        return supplier.name === name;
      }
    });
    if (hasNameAlreadyExisted) {
      alert('이미 등록된 공급사에요.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 방어로직 시작 - 필수 입력값이 없을 경우

    const isValidSupplierName = validateSupplierName(newSupplier?.name);

    if (!isValidSupplierName) {
      return;
    }

    const formData = {
      ...newSupplier,
    };

    if (newSupplier.file) {
      let templateFile = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const arrayBuffer = e.target.result;
          resolve(new Uint8Array(arrayBuffer));
        };
        reader.onerror = function (e) {
          reject(e);
        };
        reader.readAsArrayBuffer(newSupplier.file);
      });
      templateFile = await templateFile;
      templateFile = JSON.stringify(Array.from(templateFile));
      // let templateFile = reader.readAsArrayBuffer(file);
      //
      formData.customizedSettings.xlsxTemplateSetting = {
        enabled: newSupplier?.file?.name?.endsWith('.xlsx'),
        name: newSupplier.file.name,
        headerRowIndex: newSupplier.indexes.headerRowIndex,
        minRowIndex: newSupplier.indexes.minRowIndex,
        templateFile: templateFile,
      };
    }

    delete formData._id;
    delete formData.file;
    delete formData.indexes;

    onFormSubmit(formData);
  };

  const Divider = (margin) => (
    <div
      className={`my-[ 
      h-px bg-[#DDE4EB]${margin || 40}px] self-stretch`}
    ></div>
  );

  return (
    <form onSubmit={handleSubmit} className="mb-8 w-full px-[40px]">
      <SupplierHeaderComponents
        supplier={newSupplier}
        handleSupplierInfoChange={handleSupplierInfoChange}
      />
      {Divider()}

      <SupplierExcelUpload
        supplier={newSupplier}
        handleSupplierInfoChange={handleSupplierInfoChange}
        ref={supplierInfoRef}
      />
      {Divider()}

      <SupplierDetailHeaderMatchingComponent
        supplier={newSupplier}
        handleSupplierInfoChange={handleSupplierInfoChange}
      />
      <div className="flex justify-end">
        <Button
          name={type === 'update' ? '수정하기' : '등록하기'}
          type="submit"
        />
      </div>
    </form>
  );
};

export default SupplierForm;
