import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { User, UserDeploy, UserSetting } from '@sweep/contract';
import { LocalStorageNullableStringValue } from '@sweep/utils/mobx';
import { CreatePluginDTO } from 'src/network/plugin';
import {
  getCombinedExcelSetting,
  getUser,
  login,
  updateUser,
} from 'src/network/user';
import { initializeAmplitudeUser } from 'src/third-parties/amplitude';
import { OMSStore } from './OMSStore';
import { Plugin } from './plugin/interface';

// TODO(@이지원): UserStore를 삭제 후, 이름 바꾸기
export class UserStore {
  userId: LocalStorageNullableStringValue;
  authToken: LocalStorageNullableStringValue;

  setting: UserSetting | null = null;
  pluginIds: string[] = [];
  dispatchPlugins: string[] = [];
  mergeDispatchPlugin: string[] = [];

  companyType: User['companyType'] | '' = '';
  name: string = '';
  email: string = '';
  deploy: UserDeploy | null = null;
  admin: boolean = false;
  trialEndsAt: string | null = null;

  get excelHeaders() {
    const setting = this.setting;
    if (setting == null) {
      return [];
    }
    return setting.columnOrder.map(
      (column) => setting.columnTranslation[column]
    );
  }

  get excelHeaderKeys() {
    return this.setting?.columnOrder ?? [];
  }

  get excelColumnMapping() {
    return this.setting?.columnTranslation ?? {};
  }

  get isLoggedIn() {
    return this.authToken.value != null;
  }

  get plugins(): Plugin[] {
    return this.oms.plugin.getPlugins(this.pluginIds);
  }

  get isRetailer() {
    return this.companyType === 'retailer';
  }

  get isSupplier() {
    return this.companyType === 'supplier';
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      setting: observable,
      companyType: observable,
      name: observable,
      email: observable,
      deploy: observable,
      pluginIds: observable,
      dispatchPlugins: observable,
      mergeDispatchPlugin: observable,
      admin: observable,
      trialEndsAt: observable,

      excelHeaders: computed,
      excelHeaderKeys: computed,
      excelColumnMapping: computed,
      isLoggedIn: computed,
      plugins: computed,
      isRetailer: computed,
      isSupplier: computed,

      setCombinedExcelSetting: action.bound,

      init: action.bound,
      login: action.bound,
      loadUser: action.bound,
      loadCombinedExcelSetting: action.bound,
      update: action.bound,
      createPlugin: action.bound,
      deletePlugin: action.bound,
      createDispatchPlugin: action.bound,
      deleteDispatchPlugin: action.bound,
      createMergeDispatchPlugin: action.bound,
      deleteMergeDispatchPlugin: action.bound,
    });

    // @TODO(@이지원): UserStore 삭제할 때 같이 삭제
    const legacyUserId = localStorage.getItem('@userId');
    const legacyAuthToken = localStorage.getItem('@authToken');

    this.userId = new LocalStorageNullableStringValue({
      key: 'User#userID',
      defaultValue: legacyUserId,
    });
    this.authToken = new LocalStorageNullableStringValue({
      key: 'User#authToken',
      defaultValue: legacyAuthToken,
    });
  }

  /**
   * @deprecated
   */
  setCombinedExcelSetting(combinedExcelSetting: UserSetting) {
    this.setting = combinedExcelSetting;
  }

  async init() {
    await Promise.all([this.loadUser(), this.loadCombinedExcelSetting()]);
  }

  async login(email: string, password: string): Promise<boolean> {
    const response = await login(email, password);
    if (response.data) {
      this.authToken.setValue(response.data.token);
      this.userId.setValue(response.data.userId);

      return true;
    }

    return false;
  }

  async logout() {
    this.authToken.setValue(null);
    this.userId.setValue(null);
  }

  async loadUser() {
    const response = await getUser();
    const user = response.data;
    if (user == null) {
      return;
    }

    initializeAmplitudeUser({
      id: user._id,
      email: user.email,
      name: user.name,
    });

    runInAction(() => {
      this.userId.setValue(user._id);
      this.companyType = user.companyType;
      this.name = user.name;
      this.email = user.email;
      this.pluginIds = user.plugins ?? [];
      this.dispatchPlugins = user.dispatchPlugins ?? [];
      this.mergeDispatchPlugin = user.mergeDispatchPlugins ?? [];
      this.deploy = user.deploy ?? null;
      this.admin = user.admin ?? false;
      this.trialEndsAt = user.trialEndsAt ?? null;
    });
  }

  async loadCombinedExcelSetting(): Promise<void> {
    const response = await getCombinedExcelSetting();
    if (response.success !== true) {
      return;
    }

    runInAction(() => {
      this.setting = response.data ?? null;
    });
  }

  async update(updateDTO: Partial<User>) {
    const response = await updateUser(updateDTO);
    if (response == null || response.success !== true) {
      return;
    }

    const user = response.data;

    runInAction(() => {
      this.name = user.name;
      this.email = user.email;
      this.deploy = user.deploy ?? null;
      this.pluginIds = user.plugins ?? [];
      this.dispatchPlugins = user.dispatchPlugins ?? [];
      this.mergeDispatchPlugin = user.mergeDispatchPlugins ?? [];
    });
  }

  async createPlugin(createPluginDto: CreatePluginDTO) {
    const plugins = await this.oms.plugin.createUserPlugin(createPluginDto);

    if (plugins == null) {
      return;
    }

    runInAction(() => {
      this.pluginIds = plugins;
    });
  }

  async deletePlugin(pluginId: string) {
    await this.oms.plugin.deleteUserPlugin(pluginId);
    runInAction(() => {
      this.pluginIds = this.pluginIds.filter((id) => id !== pluginId);
    });
  }

  async createDispatchPlugin(createPluginDto: CreatePluginDTO) {
    const plugins = await this.oms.plugin.createDispatchPlugin(createPluginDto);

    if (plugins == null) {
      return;
    }

    runInAction(() => {
      this.dispatchPlugins = plugins;
    });
  }

  async deleteDispatchPlugin(pluginId: string) {
    await this.oms.plugin.deleteDispatchPlugin(pluginId);
    runInAction(() => {
      this.dispatchPlugins = this.dispatchPlugins.filter(
        (id) => id !== pluginId
      );
    });
  }

  async createMergeDispatchPlugin(createPluginDto: CreatePluginDTO) {
    const plugins =
      await this.oms.plugin.createMergeDispatchPlugin(createPluginDto);

    if (plugins == null) {
      return;
    }

    runInAction(() => {
      this.mergeDispatchPlugin = plugins;
    });
  }

  async deleteMergeDispatchPlugin(pluginId: string) {
    await this.oms.plugin.deleteMergeDispatchPlugin(pluginId);
    runInAction(() => {
      this.mergeDispatchPlugin = this.mergeDispatchPlugin.filter(
        (id) => id !== pluginId
      );
    });
  }
}
