import { overlay } from 'overlay-kit';
import { Dialog } from 'src/design-system/components/Dialog';

export function openConfirmRevertShippingOrders(count: number) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close }) => (
      <Dialog open={isOpen} onClose={close}>
        <Dialog.Title className="mb-16px">
          발주서의 주문을 이전 상태로 되돌릴까요?
        </Dialog.Title>
        <Dialog.Description className="mb-28px">
          파일에 있는 주문{' '}
          <span className="text-semibold-s text-blue-500">{count}</span>건이
          모두 '발주 대기' 상태로 이동됩니다.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
