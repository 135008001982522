import { RefObject, useEffect, useState } from 'react';

type Direction = 'x' | 'y' | 'x|y' | 'x&y';

export function useIsOverflow<T extends HTMLElement>(
  ref: RefObject<T>,
  direction: Direction
) {
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (element == null) {
      return;
    }

    const isHorizontalOverflow = element.scrollWidth > element.clientWidth;
    const isVerticalOverflow = element.scrollHeight > element.clientHeight;
    switch (direction) {
      case 'x':
        setIsOverflow(isHorizontalOverflow);
        break;
      case 'y':
        setIsOverflow(isVerticalOverflow);
        break;
      case 'x|y':
        setIsOverflow(isHorizontalOverflow || isVerticalOverflow);
        break;
      case 'x&y':
        setIsOverflow(isHorizontalOverflow && isVerticalOverflow);
        break;
    }
  }, [direction, ref]);

  return isOverflow;
}
