import { z } from 'zod';

const ZDoseosanganStandard = z.enum(['CJ', 'LOTTE']);

export type DoseosanganStandard = z.infer<typeof ZDoseosanganStandard>;

const ZDoseosanganSetting = z.object({
  standard: ZDoseosanganStandard.optional(),
});

export type DoseosanganSetting = z.infer<typeof ZDoseosanganSetting>;

export const ZUserSetting = z.object({
  _id: z.string(),
  columnOrder: z.array(z.string()),
  columnTranslation: z.record(z.string()),

  doseosangan: ZDoseosanganSetting.optional(),
  compositionMatching: z
    .object({ optionCodeMatching: z.boolean().optional() })
    .optional(),
  uploadHandWrittenFile: z.boolean().optional(),
  supplierFileFormatting: z
    .object({
      /**
       * @example "{date} {name} {supplierName} 발주서"
       */
      fileFormat: z.string(),
      dateFormat: z.string().optional(),
    })
    .optional(),
  excel: z
    .object({
      removeSpecialCharactersInName: z.boolean().optional(),
      fields: z
        .object({ target: z.string(), type: z.enum(['number']) })
        .array()
        .optional(),
    })
    .optional(),
  defaultShippingCompany: z.any(),

  formatOrdersSettings: z.any(),
  convertSpecificCompositionExpressionSetting: z.any(),

  platformNameSettings: z.any(),
  applyJSLogic: z.any(),
  sortOrders: z.any(),
  applyMergedCellValues: z.any(),
  applyFieldValueToAnother: z.any(),

  preprocessSettings: z.any(),
  disableMultipleAddressHighlighting: z.any(),

  combinationCustomSetting: z.any(),
  interpreteOrderSettings: z.any(),
  shippingExcelTemplate: z.any(),
  settlementType: z.any(),
});

export type UserSetting = z.infer<typeof ZUserSetting>;
