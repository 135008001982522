import type {
  JSONSheetData,
  SheetData as FormulaeSheetData,
} from '../../parsers-formulae/parser.type';
import {
  convertColumnAddressToIndex,
  convertColumnIndexToAddress,
} from '../cell-address';

type CellCoordinate = {
  row: number;
  col: number;
};

function parseCellReference(cellRef: string): CellCoordinate {
  const colMatch = cellRef.match(/^[A-Z]+/)?.[0] ?? '';
  const rowMatch = cellRef.match(/\d+$/)?.[0] ?? '';

  if (!colMatch || !rowMatch) {
    throw new Error(`Invalid cell reference: ${cellRef}`);
  }

  const col = convertColumnAddressToIndex(colMatch);
  const row = parseInt(rowMatch, 10) - 1;

  return { row, col };
}

function getMaxDimensions(formulae: FormulaeSheetData): {
  maxRow: number;
  maxCol: number;
} {
  return formulae.reduce(
    (acc, formulaeCellValue) => {
      const [cellRef] = formulaeCellValue.split('=');
      const { row, col } = parseCellReference(cellRef);
      return {
        maxRow: Math.max(acc.maxRow, row),
        maxCol: Math.max(acc.maxCol, col),
      };
    },
    { maxRow: -1, maxCol: -1 }
  );
}

function extractCellValue(value: string): string | number | null {
  if (!value) {
    return null;
  }

  // Handle string values (prefixed with ')
  if (value.startsWith("'")) {
    return value.slice(1);
  }

  const numValue = Number(value);
  return isNaN(numValue) ? null : numValue;
}

export function convertFormulaeToJSON(
  formulae: FormulaeSheetData
): JSONSheetData {
  const { maxRow, maxCol } = getMaxDimensions(formulae);
  if (maxRow === -1 || maxCol === -1) {
    return [];
  }

  // Initialize array with empty objects
  const jsonData: JSONSheetData = Array.from(
    { length: maxRow + 1 },
    () => ({})
  );

  // Initialize all cells with null
  for (let row = 0; row <= maxRow; row++) {
    for (let col = 0; col <= maxCol; col++) {
      const colAddress = convertColumnIndexToAddress(col);
      jsonData[row][colAddress] = null;
    }
  }

  // Fill in values from formulae
  for (const formulaeCellValue of formulae) {
    const [formulaeCellRef, ...formulaeValues] = formulaeCellValue.split('=');
    const formulaeValue = formulaeValues.join('=');
    const { row, col } = parseCellReference(formulaeCellRef);
    const colAddress = convertColumnIndexToAddress(col);

    const value = extractCellValue(formulaeValue);
    jsonData[row][colAddress] = value;
  }

  return jsonData;
}

function _convertFormulaeTo2DArray(
  formulae: FormulaeSheetData,
  options?: { enableCellTrim?: boolean }
): string[][] {
  const enableCellTrim = options?.enableCellTrim ?? false;
  return convertFormulaeToJSON(formulae).map((row) =>
    Object.values(row).map((rawValue) => {
      const value = rawValue?.toString() ?? '';
      const parsedValue = enableCellTrim ? value.trim() : value;

      return parsedValue;
    })
  );
}

export function convertFormulaeTo2DArray(
  formulae: FormulaeSheetData
): string[][] {
  return _convertFormulaeTo2DArray(formulae, { enableCellTrim: false });
}

export function convertFormulaeTo2DArrayWithTrim(
  formulae: FormulaeSheetData
): string[][] {
  return _convertFormulaeTo2DArray(formulae, { enableCellTrim: true });
}
