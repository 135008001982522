import { z } from 'zod';

const ZUnit = z.object({
  unit: z.string(),
  optionCode: z.string().nullish(),
  supplierId: z.string().nullish(),
});

export type Unit = z.infer<typeof ZUnit>;

export const ZSalesChannelSupplierId = z.union([
  z.object({
    partnerId: z.string(),
    supplierId: z.string().nullish(),
  }),
  z.object({
    shoppingMallId: z.string(),
    supplierId: z.string().nullish(),
  }),
]);

export type SalesChannelSupplierId = z.infer<typeof ZSalesChannelSupplierId>;

export const ZProduct = z.object({
  _id: z.string(),
  productName: z.string(),

  units: ZUnit.array().optional(),

  supplierId: z.string().nullish(),
  salesChannelSupplierIds: ZSalesChannelSupplierId.array().optional(),

  useSupplierByOption: z.boolean().optional(),
  useSupplierBySalesChannel: z.boolean().optional(),
});

export type Product = z.infer<typeof ZProduct>;
